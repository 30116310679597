<script setup lang="ts">
import { useRoute } from 'vue-router'
import { ROUTE_NAMES } from '@/constants/constants'
import { ref } from 'vue'
import { ApprovalType } from '@/enumerators/enumerators'

defineProps<{
  title: string
  isApproved: ApprovalType
}>()

const route = useRoute()

const isUploadDocumentSite = ref(false)

isUploadDocumentSite.value = route.path.includes(ROUTE_NAMES.DOCUMENTS)
</script>

<template>
  <v-row>
    <v-col class="d-flex align-center">
      <h4>{{ title }}</h4>
    </v-col>
    <v-col v-if="!isUploadDocumentSite" class="d-flex justify-end align-center">
      <div class="d-flex align-center" v-if="isApproved === ApprovalType.approved">
        <p class="approved">{{ $t('approvedByAdmin') }}</p>
        <v-img src="/images/check-green.svg" alt="check" width="15" height="15"></v-img>
      </div>
      <div v-else-if="isApproved === ApprovalType.missingDocuments" class="d-flex align-center">
        <p class="missing-documents">{{ $t('missingDocuments') }}</p>
        <v-btn :icon="true" color="#C12F5D" height="25" width="25">
          <v-img src="/images/warning.svg" alt="warning" width="15" height="15"></v-img>
        </v-btn>
      </div>
      <div v-else class="d-flex align-center">
        <p class="font-weight-bold text-grey-darken-1 pr-1">{{ $t('sentForValidation') }}</p>
        <v-btn icon="mdi-progress-clock" height="25" width="25" flat disabled />
      </div>
    </v-col>
  </v-row>
</template>

<style scoped>
.approved {
  color: #38b000;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 5px;
  padding-top: 3px;
}

.missing-documents {
  color: #c12f5d;
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 5px;
}

.v-btn {
  text-transform: unset !important;
}
</style>
