<script setup lang="ts">
const model = defineModel({
  required: true,
  type: Boolean
})
</script>

<template>
  <v-dialog v-model="model" class="dialog-style">
    <slot name="dialogBody" />
  </v-dialog>
</template>

<style scoped>
.dialog-style {
  border-radius: 10px !important;
  background: #3e3caa !important;
  max-width: 538px !important;
  max-height: 314px !important;
}
</style>
