<script setup lang="ts">
import LanguageSelector from '@/components/navigation/languageSelector/LanguageSelector.vue'
import Button from '@/components/Button.vue'
import { useCustomerStore } from '@/stores/customer'
import router from '@/router'
import { APP_NAV_LOGGED, ROUTE_NAMES } from '@/constants/constants'
import { computed } from 'vue'

const customerStore = useCustomerStore()
const userStore = useCustomerStore()

const getCustomerName = (): string => {
  return `${customerStore.user?.first_name || ''} ${customerStore.user?.last_name || ''}`
}
const getCustomerEmail = (): string => {
  return customerStore.user?.email || ''
}

const isUserLogged = (): boolean => {
  return !!customerStore.user
}

const isLogged = computed((): boolean => {
  return !!userStore.getUser?.id
})

const navItems = computed(() => {
  return isLogged.value ? APP_NAV_LOGGED : []
})

const handleLogin = (): void => {
  router.push({ name: ROUTE_NAMES.LOGIN })
}
</script>

<template>
  <v-app-bar
    class="app-bar-wrapper"
    color="white"
    density="prominent"
    :elevation="0"
    scroll-behavior="hide"
    :scroll-threshold="10"
  >
    <div class="navbar-container d-flex justify-center align-center">
      <a href="/">
        <v-img
          :width="237"
          :height="30"
          alt="Meškanie letu"
          src="/img/meskanieletu_light.svg"
          title="Meškanie letu"
        />
      </a>
      <v-spacer />
      <LanguageSelector chevron-color="black" />
      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon
            v-bind="props"
            class="account-icon navbar-icon"
            :ripple="false"
            icon="customIconSet:account"
            @click.stop="$emit('drawerClick')"
          />

          <div
            v-bind="props"
            class="user-info"
            v-if="isUserLogged"
            @click.stop="$emit('drawerClick')"
          >
            <p class="username">{{ getCustomerName() }}</p>
            <p class="email">{{ getCustomerEmail() }}</p>
          </div>
          <Button
            v-bind="props"
            v-else
            type="primary-outlined-blue"
            width="201px"
            variant="plain"
            :active="true"
            @click="handleLogin"
            >{{ $t('login') }}</Button
          >
        </template>
        <v-list v-if="navItems?.length" class="mt-2" color="transparent" rounded="0">
          <v-list-item
            v-for="(item, i) in navItems"
            class="item-account"
            :key="i"
            color="black"
            @click="router.push(item.sef)"
          >
            <v-list-item-title class="cursor-pointer">{{
              $t(`navbar.${item.code}`)
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<style scoped>
.navbar-container {
  width: 100%;
}

.navbar-icon {
  height: 20px;
  width: 20px;
}

.account-icon {
  background-color: #3e3caa;
  height: 32px;
  width: 32px;
}

.user-info {
  padding-left: 21px;
  cursor: pointer;
}

.username {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
}

.email {
  color: #898989;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  cursor: pointer;
  margin-top: 5px;
}

.username.email {
  margin-top: 5px;
}

:deep(.v-toolbar__content) {
  align-items: center;
  max-width: 1320px;
  margin: 0 auto;
  height: 54px !important;
}

:deep(.v-navigation-drawer) {
  height: 54px !important;
}
.app-bar-wrapper {
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1) !important;
}
</style>
