import { defineStore } from 'pinia'
import { useCustomerService } from '@/service/services/CustomerService'
import type {
  CustomerRequest,
  IResetPasswordRequest,
  Customer,
  CustomerUpdate,
  BankData,
  ContactDataUpdate
} from '@/interfaces/interfaces'
import { useSettingService } from '@/service/services/SettingsService'

export const useCustomerStore = defineStore('customer', {
  state() {
    return {
      user: undefined as Customer | undefined
    }
  },
  persist: {
    storage: localStorage
  },
  getters: {
    getUser: function (state): Customer | undefined {
      return state.user
    }
  },
  actions: {
    setUser(user: Customer): void {
      this.user = user
    },
    async getSettingCountries(searchTerm: string) {
      const { getCountries } = useSettingService()
      return await getCountries(searchTerm)
    },
    setUserContactData(data: ContactDataUpdate): void {
      this.user.first_name = data.first_name
      this.user.last_name = data.last_name
      this.user.date_of_birth = data.date_of_birth
      this.user.phone_number = data.phone_number
      this.user.city = data.city
      this.user.street = data.street
      this.user.postal_code = data.postal_code
      this.user.country = data.country
    },
    setUserBankData(data: BankData): void {
      this.user.bank_account_owner = data.bank_account_owner
      this.user.iban = data.iban
      this.user.swift = data.swift
    },
    setUserIban(iban: string): void {
      this.user.iban = iban
    },
    setUserBankAccountOwner(bankAccountOwner: string): void {
      this.user.bank_account_owner = bankAccountOwner
    },
    setUserSwift(swift: string): void {
      this.user.swift = swift
    },
    async login(loginData: Partial<CustomerRequest>) {
      const { login } = useCustomerService()
      this.user = await login(loginData)
    },
    async googleLogin(token: string) {
      const { googleLogin } = useCustomerService()
      this.user = await googleLogin(token)
    },
    async logout() {
      const { logout } = useCustomerService()
      await logout()
      this.user = null
    },
    async signup(userData: CustomerRequest) {
      const { signup } = useCustomerService()
      this.user = await signup(userData)
    },
    async changePassword(userData: { newPassword: string } & Partial<CustomerRequest>) {
      const { changePassword } = useCustomerService()
      return await changePassword(userData)
    },
    async resendVerificationEmail(userEmail: string) {
      const { resendVerificationEmail } = useCustomerService()
      return await resendVerificationEmail(userEmail)
    },
    async updateCustomerData(customer: CustomerUpdate) {
      const { updateUserData } = useCustomerService()
      return await updateUserData(customer)
    },
    async resetPassword(requestData: IResetPasswordRequest) {
      const { resetPassword } = useCustomerService()
      return await resetPassword(requestData)
    },
    async forgotPassword(email: string) {
      const { forgotPassword } = useCustomerService()
      return await forgotPassword(email)
    }
  }
})
