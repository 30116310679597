<template>
  <div style="background-color: white">
    <div class="px-0 d-flex">
      <div class="justify-start px-1">
        <div class="step">
          <accept-stage
            v-if="firstChoice.status === ClaimStatusType.completed"
            color="blue"
            size="small"
          />
          <accept-stage
            v-else-if="firstChoice.status === ClaimStatusType.completed_error"
            color="red"
            size="small"
          />
          <warning-stage v-else-if="firstChoice.status === ClaimStatusType.alert" size="small" />
          <div
            v-else
            class="circle"
            :class="{ blue: firstChoice.status === ClaimStatusType.current }"
          >
            1.
          </div>
        </div>
      </div>
      <div class="line mt-3 px-3" :class="getFirstLineClass()" />

      <template v-for="(betweenChoice, index) in choices" :key="index">
        <div class="justify-center px-1">
          <div class="step">
            <accept-stage
              v-if="betweenChoice.status === ClaimStatusType.completed"
              color="blue"
              size="small"
            />
            <accept-stage
              v-else-if="betweenChoice.status === ClaimStatusType.completed_error"
              color="red"
              size="small"
            />
            <warning-stage
              v-else-if="betweenChoice.status === ClaimStatusType.alert"
              size="small"
            />
            <div
              v-else
              class="circle"
              :class="{ blue: betweenChoice.status === ClaimStatusType.current }"
            >
              {{ index + 2 }}.
            </div>
          </div>
        </div>
        <div class="line mt-3 px-3" :class="getLineClass(index)" />
      </template>

      <div class="justify-end px-1 pr-7">
        <div class="step">
          <accept-stage
            v-if="lastChoice.status === ClaimStatusType.completed"
            color="blue"
            size="small"
          />
          <accept-stage
            v-else-if="lastChoice.status === ClaimStatusType.completed_error"
            color="red"
            size="small"
          />
          <warning-stage v-else-if="lastChoice.status === ClaimStatusType.alert" size="small" />
          <div
            v-else
            class="circle"
            :class="{ blue: lastChoice.status === ClaimStatusType.current }"
          >
            {{ props.allChoices.length }}.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import AcceptStage from '@/components/stepper/AcceptStage.vue'
import WarningStage from '@/components/stepper/WarningStage.vue'
import { computed } from 'vue'
import { ClaimStatusType } from '@/enumerators/enumerators'

const props = defineProps({
  currentStep: {
    type: Number,
    required: true,
    default: 0
  },
  allChoices: {
    type: Array<{ name: String; status: String }>,
    required: true
  }
})
const firstChoice = computed(() => {
  return props.allChoices[0]
})

const lastChoice = computed(() => {
  return props.allChoices[props.allChoices.length - 1]
})

const choices = computed(() => {
  return props.allChoices.slice(1, -1)
})

const getColoring = (status: ClaimStatusType) => {
  switch (status) {
    case ClaimStatusType.completed:
      return 'active'
    case ClaimStatusType.current:
      return 'active'
    case ClaimStatusType.completed_error:
      return 'active'
    case ClaimStatusType.alert:
      return 'active'
    default:
      return ''
  }
}

const getFirstLineClass = () => {
  const secondStep = props.allChoices[1]
  if (secondStep) {
    return getColoring(secondStep.status as ClaimStatusType)
  }
}
const getLineClass = (choiceIdx: number) => {
  const choice = props.allChoices[choiceIdx + 2]
  const actualChoice = props.allChoices[choiceIdx + 1]
  if (choice && actualChoice) {
    if (actualChoice.status === ClaimStatusType.skipped) return ''

    return getColoring(choice.status as ClaimStatusType)
  }
  return ''
}
</script>

<style scoped>
.step {
  align-items: center;
  text-align: center;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  position: relative;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &.blue {
    background-color: #3e3caa;
    color: white;
  }
}

.step.active .circle {
  background-color: #3e3caa;
  color: white;
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: #dcdcdc;
}

.line.active {
  flex-grow: 1;
  height: 2px;
  background-color: #3e3caa;
}
</style>
