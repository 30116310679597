export default {
  error_messages: {
    default_error: 'Something went wrong',
    access_token_expired: 'Your session has expired. Please log in again.',
    claim_not_found: 'Request not found',
    not_verified_email: 'Your email is not verified. Please verify your email.',
    unknown_email: 'Unknown email',
    email_occupied: 'Email is already taken',
    different_new_passwords: 'New passwords do not match',
    could_not_validate_credentials: 'Failed to verify login credentials',
    incorrect_email_or_password: 'Incorrect email or password',
    weak_password: 'Weak password',
    invalid_reset_token: 'Invalid reset token',
    incorrect_old_password: 'Incorrect old password',
    not_found: 'Record with the given identifier does not exist',
    not_authorized: 'You are not authorized to perform this action',
    entity_too_large: 'Attachment is too large',
    url_already_exists: 'URL already exists',
    recaptcha_error: 'Incorrect Recaptcha',
    login_again: 'Please log in again'
  },
  distance_verification_subtitle:
    'Information is used to check if you are eligible for compensation.',
  distance_verification_flight_from: 'Departure from',
  distance_verification_flight_to: 'Arrival at',
  no_results: 'No results found',
  enter_city_airport: 'Enter a city or airport',
  distance_verification_direct_flight: 'Did you have a direct flight?',
  distance_verification_stepovers: 'Where was your transfer?',
  distance_verification_stepovers_other: 'Where was your next transfer located?',
  next: 'Next',
  previous: 'Back',
  yes: 'Yes',
  no: 'No',
  passenger_details: 'Passenger details',
  flight_identification_airline: 'Airline name',
  flight_identification_number: 'Flight number',
  flight_identification_number_placeholder: 'e.g., FR1234',
  flight_identification_date: 'Flight date',
  flight_identification_reservation_number: 'Booking number',
  flight_identification_reservation_number_placeholder: 'e.g., NQH5UX',
  flight_identification_alert:
    'Your confirmation code is in the email you received from the airline after booking your flight. This code is also visible on your boarding pass. It looks like this: AB12CD (a six-letter word made up of letters and digits). In the case of a charter flight, fill in 000000 (six zeros).',
  reasons_application_title: 'Reasons for requesting compensation',
  reasons_application_subtitle:
    'Additional information that can help us process your compensation faster.',
  reasons_application_problem: 'What was the issue with the flight you encountered?',
  reasons_application_problem_label: 'Choose a problem',
  reasons_application_interval: 'How many hours was your flight delayed?',
  reasons_application_interval_label: 'Choose an option',
  reasons_application_problem_type:
    'Did the company inform you of the reason for the delay of the flight?',
  reasons_application_airport_problem: 'What was the issue with the flight you encountered?',
  reasons_application_problem_description: 'Briefly describe your experience',
  reasons_application_problem_description_label: 'What inconveniences did you encounter?',
  reasons_application_agreement_1: 'I agree to',
  reasons_application_agreement_2: 'the processing of my personal data.',
  reasons_application_agreement_2_general: 'general terms and conditions',
  upload_documents: 'Documents',
  upload_documents_title: 'Documents',
  upload_documents_load_docs: 'Upload document',
  upload_documents_load_docs_label: 'Select file',
  upload_documents_btn_later: "I'll add later",
  files: 'files',
  documents_1: 'Tickets or boarding passes / passenger',
  documents_1_help: 'Tickets or boarding passes / passenger',
  documents_2: 'Airport confirmation of flight delay/cancellation',
  documents_2_help: 'Airport confirmation of flight delay/cancellation',
  documents_3: 'Your communication with the airline',
  documents_3_help:
    'Complete email communication and contractual documentation with the airline regarding your flight and also the ticket sale, if you purchased it directly from the airline, including terms and conditions.',
  documents_4: 'Your communication with the travel agency',
  documents_4_help:
    'Complete email communication and contractual documentation with the ticket / trip seller (e.g., travel agency, portal, etc.), if you did not purchase it directly from the airline, including terms and conditions.',
  documents_5: 'Your communication with another company involved in obtaining compensation',
  documents_5_help:
    'Complete email communication, if you have already addressed the case with another company involved in obtaining compensation for your canceled flight.',
  documents_6: 'Other documents',
  documents_6_help: 'Other documents related to your case',
  download: 'Download',
  card_flight_airline: 'Airline',
  card_flight_number: 'Flight number',
  card_flight_date: 'Date of flight',
  card_personal_name: 'Name',
  card_personal_last_name: 'Surname',
  card_personal_email: 'Email',
  card_personal_date: 'Date of birth',
  card_personal_country: 'Country',
  card_personal_street: 'Street',
  card_personal_city: 'City',
  card_personal_postal: 'Postal code',
  card_personal_contact: 'Phone number',
  login: 'Login',
  my_account: 'Client zone',
  application_progress: 'Application process:',
  add: 'Add',
  remove: 'Remove',
  password_change: 'Change password',
  password: 'Password',
  new_password: 'New password',
  new_password_confirm: 'Confirm new password',
  data_was_updated: 'Data was updated!',
  password_confirm: 'Confirm password',
  forgot_password: 'Forgot password',
  forgot_password_description:
    'To reset your password, enter the email address to which a verification email will be sent.',
  forgot_password_email: 'Send verification email',
  forgot_password_email2: 'Email has been sent to:',
  confirm_email_alert: 'You have successfully verified your email. Please proceed with logging in.',
  login2: 'Log in',
  or_use: 'or use',
  forgot_password_question: 'Forgot your password?',
  restore: 'Restore',
  no_account_question: "Don't have an account yet?",
  register: 'Register',
  confirm_email_fail: 'The confirmation link has expired. Request a new one.',
  just_a_step: 'Just one more step!',
  not_verified_description:
    'To complete the registration process, you need to confirm your email. If you have not received an email, you can request to resend it.',
  already_have_account: 'Already have an account?',
  create_password: 'Create a password',
  restore_password: 'Password reset',
  set_password: 'Set password',
  reset_password_change: 'Change password',
  reset_password_success_alert_1: 'You have successfully set your password.',
  reset_password_success_alert_2: 'You have successfully changed your password.',
  adult_rule:
    'You must be at least 18 years old to apply. For persons under the age of 18, the application is submitted by their legal representative.',
  same_email_rule: 'Email has already been used.',
  are_not_same_rule: 'Emails are not the same.',
  email_confirm: 'Email confirmation',
  repeat_email: 'Repeat email',
  contact_info: 'Contact details',
  other_passengers: 'Other passengers',
  application_state: 'CLAIM STATUS:',
  edit: 'Edit',
  save: 'Save',
  shorter_than_3_hours: 'Less than 3 hours',
  longer_than_3_hours: 'More than 3 hours',
  missed_connecting_flight: 'Missed connecting flight',
  duration_less_than_2_hours: '0 - 2 hours',
  duration_more_than_2_hours: 'More than 2 hours',
  duration_never_flied: 'Never departed',
  technical_problem: 'Technical problem',
  bad_weather: 'Bad weather',
  strike: 'Strike',
  airport_problem: 'Problem at the airport',
  other_flight: 'Affected by other flights',
  higher_power: 'Force majeure',
  other: 'Other',
  cant_remember: ' I do not remember',
  logout: 'Log out',
  upload: 'Upload',
  faq: 'Faq',
  blog: 'Blog',
  new_traveler: '+ Add another passenger',
  flight_delay: 'Delayed flight',
  flight_cancel: 'Canceled flight',
  flight_connecting_delay: 'Missed connecting flight',
  claim_submission: 'Submit claim',
  my_claims: 'My Claims',
  claim_stepper: {
    mobile: {
      title: 'Submit claim',
      step1: 'Flight check',
      step2: 'Passenger details',
      step3: 'Finish'
    }
  },
  validations: {
    required: 'This field is required',
    max_char: 'Maximum number of characters is ',
    different_password: 'Passwords do not match',
    password_format:
      'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number',
    postal_code: 'Enter a valid postal code',
    email: 'Enter a valid email',
    age: 'Minimum required age is ',
    dateFormat: 'Date must be in the format DD. MM. YYYY',
    dateValidity: 'Invalid date',
    dateFuture: 'The date cannot be in the future'
  },
  alert_messages: {
    halted_missing_docs: ['Halted progress', 'Missing documents'],
    halted_missing_data: ['Halted progress', 'Missing data'],
    claim_validation: ['Claim in Progress', 'Claim Validation'],
    missing_claim_attachments: ['Claim in Progress', 'Missing Attachments'],
    missing_legal_documents: ['Claim in Progress', 'Missing Legal Documents'],
    legal_documents: ['Claim in Progress', 'Verifying Legal Documents'],
    communications_airline: ['Claim in Progress', 'Communication with Airline'],
    communications_court: ['Claim in Progress', 'Communication with Court'],
    successfully_completed: ['Claim Completed', 'Successfully Completed'],
    closed_undelivered_documents: ['Claim Completed', 'Undelivered Documents'],
    closed_duplicate_request: ['Claim Completed', 'Duplicate Request'],
    extraordinary_situation: ['Claim Completed', 'Extraordinary Situation'],
    delay_less_then_3_hours: ['Claim Completed', 'Delay Less Than 3 Hours'],
    claim_successfully_completed: ['Claim Completed', 'Successfully Completed'],
    flight_outside_eu: ['Claim Completed', 'Flight Outside EU'],
    court_start_process: ['Court Process', 'Filing a Lawsuit'],
    court_received_docs: ['Court Process', 'Documents Received'],
    court_lawyer_delegation: ['Court Process', 'Case Handed Over to Law Firm'],
    closed_duplicated_request: ['Claim Completed', 'Duplicated claim'],
    closed_undelivered_docs: ['Claim Completed', 'Undelivered documents']
  },
  claim_status: {
    created: 'Created',
    validation: 'In validation',
    legal_documents: 'Contract documentation',
    airline_communication: 'Communication with the airline',
    court_communication: 'Communication with the court',
    rejected: 'Rejected request - Extraordinary event',
    completed: 'Completed successfully',
    finished: 'Finished claim',
    stopped_documentation_needed: 'Stopped progress - Missing documentation',
    stopped_data_needed: 'Stopped progress - Missing data'
  },
  confirm: 'Confirm',
  claim_success_message:
    'Your request has been successfully submitted. Please follow the status of your request in the My requests tab',
  contact_message_success: 'You have successfully submitted the contact form. We thank you.',
  footer: {
    submit_claim_slogan: 'In our case, you only pay if you successfully receive compensation.',
    our_services: 'Our services',
    delay_longer_than_3_hours: 'Flight delay of more than 3 hours',
    canceled_flight: 'Flight cancellation without prior notice',
    delayed_connecting_flight: 'Connecting flight delay',
    delay_longer_than_3_hours_url: '/en/get-compensation-for-flight-delay-up-to-600-e',
    canceled_flight_url:
      '/en/unexpected-cancellation-of-the-flight-you-may-be-entitled-to-compensation-up-to-600-e',
    delayed_connecting_flight_url:
      '/en/claim-compensation-for-missed-connecting-flight-up-to-600-e',
    customer_service_message:
      'Stay in touch with us. Follow us on social media for news, unique articles, and more information about your claims!',
    customer_service_title: 'Get in touch with us',
    company_slogan:
      'We help clients obtain compensation for flight cancellations and delays with a professional team. Fill out an online application and pay only if successful.',
    company_email: "info{'@'}meskanieletu.sk",
    company_name: 'Studio JŠ, s.r.o. - Meskanieletu.sk',
    company_address: 'Ul. 1. Mája, Krásno nad Kysucou 02302, Slovakia',
    lawyer_documents: {
      title: 'Legal documents',
      general_terms: 'General terms',
      privacy_policy: 'Privacy policy',
      cookies_files: 'Regulation EPaR (EC) no. 261/2004'
    },
    menu: {
      title: 'Menu',
      about_us: 'About us',
      price_list: 'Price list',
      faq: 'FAQ',
      blog: 'Blog',
      urls: {
        about_us: '/en/about-us',
        price_list: '/en/price-list',
        faq: '/en/frequently-asked-questions',
        terms_and_conditions: '/en/terms-and-conditions',
        privacy_policy: '/en/ochrana-a-spracovanie-osobnych-udajov',
        blog: '/en/blog'
      }
    }
  },
  buttons: {
    submit_claim: 'Submit claim',
    logout: 'Logout',
    login: 'Log in',
    check_claim: 'Verify entitlement',
    skip: 'Skip'
  },
  navbar: {
    my_claims: 'My claims',
    contact: 'Contact',
    bank: 'Bank account',
    settings: 'Settings',
    show_profile: 'Show profile',
    change_password: 'Change password',
    logout: 'Log out'
  },
  personal_data: 'Personal data',
  personal_data_view: {
    title: 'Your data',
    description:
      'Please check your personal contact information. With this information, your request will be processed faster and easier. Your data will allow us to automatically pre-fill contracts or contact you if necessary. Thank you for your cooperation!',
    bank_account_title: 'Bank account',
    bank_account_description:
      'The bank account information is used for the preparation of contractual documentation as well as for sending the financial amount in the scope of the mandate contract in the case of successful compensation.',
    iban_title: 'Account number in IBAN format'
  },
  address: 'Home address',
  address_line_2: 'Address line 2',
  city: 'City',
  postal_code: 'Postal code',
  country: 'Country',
  phone_number: 'Phone number',
  bank_account_owner: "Account owner's name",
  iban: 'IBAN',
  swift: 'SWIFT',
  airplaneBackgroundText:
    'For more details about your application, log in to your account after submitting the application.',
  cancel: 'Cancel',
  application_done_title: 'We have everything we need!',
  application_done_subtitle:
    'We will review your compensation claim and send you information about the next steps to your email. You can track the current status of your claim in your account. More information is already in your email.',
  add_document: 'Add document',
  reprimand_contract: 'Reprimand contract',
  delegation_document: 'Delegation document',
  my_claims_view: {
    departure_place: 'Departure Place',
    arrival_place: 'Arrival Place',
    claim: 'Claim',
    claim_number: 'Claim Number',
    requestor: 'Requestor',
    submission_date: 'Submission Date',
    all_documents_requirement: 'It is necessary to submit all documents.',
    upload_documents: 'Upload Documents',
    here: 'HERE',
    claim_type: 'Claim Type',
    title: 'Submit a claim quickly and easily!',
    fast_and_easy: 'Fast and Easy',
    fast_and_easy_neutral: 'quickly and easily!',
    all_airlines: 'All Airlines',
    no_risks: 'No Risk of Fees',
    fast_handling: 'It only takes 3 minutes.',
    show_more: 'Show Claim Details',
    show_less: 'Show Less',
    residence: 'Residence',
    email_address: 'Email Address',
    claim_status: 'Claim Status',
    other_passengers: 'Other Passengers',
    description:
      'Our team of travel law professionals will review your claim and keep you informed of every milestone achieved throughout the process.'
  },
  IdOrPass: 'Identity card (front page) or passport',
  claimDocuments: 'Documents for the claim',
  representationDocuments: 'Documents for representation',
  deleteConfirmationText:
    'You will no longer have access to the document. It will be permanently deleted.',
  deleteConfirmationTitle: 'Are you sure you want to delete this document?',
  coPassengerNumber: 'Passenger No.',
  unknownFile: 'Unknown file',
  approvedByAdmin: 'Approved by admin',
  missingDocuments: 'Missing documents',
  sentForValidation: 'Sent for validation',
  IdontHave: "I don't have",
  other_airline_communication:
    'Have you communicated with the airline or another company for compensation?',
  yes_communicated: 'Yes, I have communicated',
  no_communication: 'No, I have not communicated',
  communication: 'Communication',
  explain_communication: 'Describe your communication with the airline or the compensation company',
  send_without_documentation: 'Send without documents',
  send: 'Send',
  continue_to_detail: 'Go to request detail',
  rest_upload_later: 'I will add the rest later',
  delete: 'Delete',
  reprimand_contract_description:
    'The reprimand contract is a document that allows us to represent you in the claim.',
  delegation_document_description:
    'The delegation document is a document that allows us to represent you in the claim.',
  idOrPass_description:
    'The identity card or passport is a document that allows us to verify your identity.',
  step_number: 'Step n.',
  my_claims_state: 'Claim status:'
}
