<script setup lang="ts">

</script>

<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="white"/>
    <path d="M7.06341 8L17.1027 21.4642L7 32.4082H9.275L18.1174 22.8238L25.2634 32.4082H33L22.3979 18.1886L31.7991 8H29.528L21.3832 16.8251L14.804 8H7.06341ZM10.4085 9.67756H13.9637L29.6588 30.7266H26.1037L10.4085 9.67756Z" fill="#3E3CAA"/>
  </svg>
</template>

<style scoped>

</style>
