<script async setup lang="ts">
import { useClaimStore } from '@/stores/claim'
import { ref } from 'vue'
import type { IProcessedClaim } from '@/interfaces/interfaces'
import type { Ref } from 'vue'
import { useCustomerStore } from '@/stores/customer'
import MyClaimBanner from '@/components/claim/myClaim/banner/MyClaimBanner.vue'
import MyClaimCard from '@/components/claim/myClaim/MyClaimCard.vue'
import { useDisplay } from 'vuetify'

const claimStore = useClaimStore()
const customerStore = useCustomerStore()
const { mobile } = useDisplay()

let claims: Ref<IProcessedClaim[]> = ref([])
const getClaims = async (): Promise<void> => {
  claims.value = await claimStore.getMyClaims()
}

if (customerStore.getUser) {
  getClaims()
}
</script>

<template>
  <v-container
    :fluid="true"
    class="d-flex flex-column align-lg-center pt-0"
    :class="{ 'mobile-banner': mobile }"
  >
    <div>
      <h1>{{ $t('claim_submission') }}</h1>
      <MyClaimBanner />
    </div>
    <div v-if="claims.length > 0">
      <h1>{{ $t('my_claims') }}</h1>
      <div>
        <MyClaimCard v-for="(claim, idx) in claims" :key="idx" :claim="claim" />
      </div>
    </div>
  </v-container>
</template>

<style scoped>
.mobile-banner {
  background: rgba(0, 0, 0, 0.12);
}
a {
  text-decoration: none;
  color: #fc9424;
}

div:first-of-type {
  padding-bottom: 32px;
}

div:nth-of-type(2) {
  padding-bottom: 34px;
}
</style>
