export default {
  error_messages: {
    default_error: 'Valami elromlott',
    access_token_expired: 'Az Ön munkamenete lejárt. Kérjük, jelentkezzen be újra.',
    claim_not_found: 'Kérés nem található',
    not_verified_email:
      'Az Ön e-mail címe nincs megerősítve. Kérjük, erősítse meg az e-mail címét.',
    unknown_email: 'Ismeretlen e-mail',
    email_occupied: 'Az e-mail cím már foglalt',
    different_new_passwords: 'Az új jelszavak nem egyeznek',
    could_not_validate_credentials: 'A bejelentkezési adatok ellenőrzése nem sikerült',
    incorrect_email_or_password: 'Helytelen e-mail vagy jelszó',
    weak_password: 'Gyenge jelszó',
    invalid_reset_token: 'Érvénytelen visszaállító token',
    incorrect_old_password: 'Helytelen régi jelszó',
    not_found: 'A megadott azonosítóval nem létezik bejegyzés',
    not_authorized: 'Ön nem jogosult végrehajtani ezt a műveletet',
    entity_too_large: 'A melléklet túl nagy',
    url_already_exists: 'Az URL már létezik',
    recaptcha_error: 'Hibás Recaptcha',
    login_again: 'Kérjük, jelentkezzen be újra'
  },
  distance_verification_subtitle:
    'Az információk ellenőrzés céljából szolgálnak, hogy jogosult-e kártérítésre.',
  distance_verification_flight_from: 'Indulás',
  distance_verification_flight_to: 'Érkezés',
  no_results: 'Nem található találat',
  enter_city_airport: 'Adjon meg várost vagy repülőteret',
  distance_verification_direct_flight: 'Közvetlen járata volt?',
  distance_verification_stepovers: 'Hol volt az átszállás?',
  distance_verification_stepovers_other: 'Hol volt a következő átszállás?',
  next: 'Tovább',
  previous: 'Vissza',
  yes: 'Igen',
  no: 'Nem',
  passenger_details: 'Utasok adatai',
  flight_identification_airline: 'Légitársaság neve',
  flight_identification_number: 'Az Ön járata',
  flight_identification_number_placeholder: 'pl. NQH5UX',
  flight_identification_date: 'A járat dátuma',
  flight_identification_reservation_number: 'Foglalási szám',
  flight_identification_reservation_number_placeholder: 'pl. FR1234',
  flight_identification_alert:
    'Az Ön visszaigazoló kódja az e-mailben található, amelyet a légitársaságtól kapott a járat foglalásakor. Ez a kód a beszállókártyán is látható. Így néz ki: AB12CD (hatjegyű betűkből és számokból álló kód). Charter járat esetén töltse ki a következő kódot: 000000 (hat nulla).',
  reasons_application_title: 'A kártérítés igénylésének okai',
  reasons_application_subtitle:
    'Kiegészítő információk, amelyek segíthetnek a kártérítés gyorsabb elintézésében.',
  reasons_application_problem: 'Milyen problémával találkozott a járatával kapcsolatban?',
  reasons_application_problem_label: 'Válasszon problémát',
  reasons_application_interval: 'Hány órás késése volt a járatának?',
  reasons_application_interval_label: 'Válasszon lehetőséget',
  reasons_application_problem_type: 'Tájékoztatta-e a társaság a késés/törlés okáról?',
  reasons_application_airport_problem: 'Milyen problémával találkozott a járatával kapcsolatban?',
  reasons_application_problem_description: 'Röviden írja le élményét',
  reasons_application_problem_description_label: 'Milyen kellemetlenségekkel találkozott?',
  reasons_application_agreement_1: 'Egyetértek az',
  reasons_application_agreement_2: 'Egyetértek azzal, hogy a személyes adataimat feldolgozzák.',
  reasons_application_agreement_2_general: 'általános szerződési feltételek',
  upload_documents: 'Dokumentumok',
  upload_documents_title: 'A jogosultság igazolásához szükséges dokumentumok',
  upload_documents_load_docs: 'Töltsön fel dokumentumot',
  upload_documents_load_docs_label: 'Válasszon fájlt',
  upload_documents_btn_later: 'Később pótolom',
  files: 'fájlok',
  documents_1: 'Repülőjegy vagy beszállókártya (boarding pass) / minden utas számára',
  documents_1_help:
    'Amennyiben több utast adott meg a kérvényben, szükségünk van minden érintett utas repülőjegyére.',
  documents_2: 'A repülőtér igazolása a késésről/törlésről',
  documents_2_help:
    'Ez egy, a repülőtér által kiállított dokumentum kérésre. Nem egy fényképezett információs tábla stb. Erre szolgál a "További dokumentumok" sor.',
  documents_3: 'Légi társasággal folytatott kommunikáció',
  documents_3_help:
    'A légitársasággal folytatott teljes e-mail kommunikáció és a járatára vonatkozó szerződéses dokumentáció, valamint a repülőjegy vásárlására vonatkozó dokumentáció, ha a jegyet közvetlenül a légitársaságtól vásárolta, beleértve az általános szerződési feltételeket is.',
  documents_4: 'Utazási irodával folytatott kommunikáció',
  documents_4_help:
    'A repülőjegy / utazási csomag vásárlásával kapcsolatos teljes e-mail kommunikáció és szerződéses dokumentáció (pl. utazási iroda, portál stb.), ha nem közvetlenül a légitársaságtól vásárolta a jegyet, beleértve az általános szerződési feltételeket is.',
  documents_5: 'Kártérítési ügyekkel foglalkozó céggel folytatott kommunikáció',
  documents_5_help:
    'A teljes e-mail kommunikáció, amennyiben már egy másik társasággal kezelte az ügyet, amely a törölt járatával kapcsolatos kártérítést intézte.',
  documents_6: 'Egyéb dokumentumok',
  documents_6_help: 'Egyéb, az ügyével kapcsolatos dokumentumok',
  documents_other: 'További dokumentumok',
  documents_other_help:
    'Töltsön fel bármilyen további dokumentumot/fotót, amely a fentiekben nem lett megemlítve, de Ön szerint fontos.',
  download: 'Letöltés',
  card_flight_airline: 'Légi társaság',
  card_flight_number: 'Járatszám',
  card_flight_date: 'A járat dátuma',
  card_personal_name: 'Keresztnév',
  card_personal_last_name: 'Vezetéknév',
  card_personal_email: 'Email',
  card_personal_date: 'Születési dátum',
  card_personal_country: 'Ország',
  card_personal_street: 'Utca',
  card_personal_city: 'Város',
  card_personal_postal: 'Irányítószám',
  card_personal_contact: 'Telefonszám',
  login: 'Bejelentkezés',
  my_account: 'Ügyfélzóna',
  application_progress: 'A kérvény benyújtásának folyamata',
  add: 'Hozzáadás',
  remove: 'Eltávolítás',
  password_change: 'Jelszó módosítása',
  password: 'Jelszó',
  new_password: 'Új jelszó',
  new_password_confirm: 'Új jelszó megerősítése',
  data_was_updated: 'Az adatok sikeresen mentve lettek',
  password_confirm: 'Jelszó megerősítése',
  forgot_password: 'Elfelejtettem a jelszavam',
  forgot_password_description:
    'A jelszó visszaállításához adja meg az e-mail címét, amelyre a megerősítő e-mailt küldjük.',
  forgot_password_email: 'Ellenőrző e-mail küldése',
  forgot_password_email2: 'Az e-mail elküldve a következő címre:',
  confirm_email_alert:
    'Sikeresen megerősítette az e-mail címét. Kérjük, folytassa a bejelentkezéssel.',
  login2: 'Bejelentkezés',
  or_use: 'vagy használja',
  forgot_password_question: 'Elfelejtette a jelszavát?',
  restore: 'Visszaállítás',
  no_account_question: 'Még nincs fiókja?',
  register: 'Regisztráció',
  confirm_email_fail: 'A megerősítő link lejárt. Kérjen újat.',
  just_a_step: 'Már csak egy lépés!',
  not_verified_description:
    'A regisztráció befejezéséhez meg kell erősítenie az e-mail címét. Ha nem kapta meg az e-mailt, kérhet újraküldést.',
  already_have_account: 'Már van fiókja?',
  create_password: 'Jelszó létrehozása',
  restore_password: 'Jelszó visszaállítása',
  set_password: 'Jelszó beállítása',
  reset_password_change: 'Jelszó módosítása',
  reset_password_success_alert_1: 'Sikeresen beállította a jelszavát.',
  reset_password_success_alert_2: 'Sikeresen megváltoztatta a jelszavát.',
  adult_rule: '18 év alatti személyek esetében a törvényes képviselő nyújtja be a kérvényt.',
  same_email_rule: 'Ezt az e-mail címet már használták.',
  are_not_same_rule: 'Az e-mail címek nem egyeznek',
  email_confirm: 'E-mail megerősítése',
  repeat_email: 'Ismételje meg az e-mailt',
  contact_info: 'Kapcsolattartási adatok',
  other_passengers: 'További utas',
  application_state: 'KÉRVÉNY ÁLLAPOTA:',
  my_claims_state: 'A kérvény állapota:',
  edit: 'Szerkesztés',
  save: 'Mentés',
  shorter_than_3_hours: 'Kevesebb mint 3 óra',
  longer_than_3_hours: 'Több mint 3 óra',
  missed_connecting_flight: 'Átszálló járat lekésett',
  duration_less_than_2_hours: '0 - 2 óra',
  duration_more_than_2_hours: 'Több mint 2 óra',
  duration_never_flied: 'Soha nem indult el',
  technical_problem: 'Műszaki probléma',
  bad_weather: 'Rossz időjárás',
  strike: 'Sztrájk',
  airport_problem: 'Probléma a repülőtéren',
  other_flight: 'Más járatok hatása',
  higher_power: 'Vis maior',
  other: 'Egyéb',
  cant_remember: 'Nem emlékszem',
  logout: 'Kijelentkezés',
  upload: 'Feltöltés',
  faq: 'Faq',
  blog: 'Blog',
  new_traveler: '+ További utas hozzáadása',
  flight_delay: 'Járat késése',
  flight_cancel: 'Járat törlése',
  flight_connecting_delay: 'Csatlakozó járat késése',
  claim_submission: 'Kérelem benyújtása',
  my_claims: 'Kérelmeim',
  claim_stepper: {
    mobile: {
      title: '$t(claim_submission)',
      step1: 'Járat ellenőrzése',
      step2: 'Utas adatai',
      step3: 'Befejezés'
    }
  },
  validations: {
    required: 'Kötelező mező',
    max_char: 'Maximális karakterek száma',
    different_password: 'A jelszavak nem egyeznek',
    password_format:
      'A jelszónak legalább 8 karakter hosszúnak kell lennie, tartalmaznia kell nagybetűt, kisbetűt és számot',
    postal_code: 'Adjon meg érvényes irányítószámot',
    email: 'Adjon meg érvényes e-mail címet',
    age: 'Minimális életkor',
    dateFormat: 'A dátumnak a következő formátumban kell lennie: NN. HH. ÉÉÉÉ',
    dateValidity: 'Érvénytelen dátum',
    dateFuture: 'A dátum nem lehet a jövőben.'
  },
  alert_messages: {
    halted_missing_docs: ['Előrehaladás felfüggesztve', 'Hiányzó dokumentumok'],
    halted_missing_data: ['Előrehaladás felfüggesztve', 'Hiányzó adatok'],
    claim_validation: ['Kérelem feldolgozás alatt', 'Jogosultság ellenőrzése'],
    missing_claim_attachments: ['Kérelem feldolgozás alatt', 'Hiányzó mellékletek'],
    missing_legal_documents: ['Kérelem feldolgozás alatt', 'Hiányzó jogi dokumentumok'],
    legal_documents: ['Kérelem feldolgozás alatt', 'Jogi dokumentumok ellenőrzése'],
    communications_airline: ['Kérelem feldolgozás alatt', 'Kommunikáció a légitársasággal'],
    communications_court: ['Kérelem feldolgozás alatt', 'Bírósági kommunikáció'],
    successfully_completed: ['Kérelem lezárva', 'Sikeresen lezárva'],
    closed_undelivered_documents: ['Kérelem lezárva', 'Dokumentumok nem lettek benyújtva'],
    closed_duplicate_request: ['Kérelem lezárva', 'Duplikált kérelem'],
    extraordinary_situation: ['Kérelem lezárva', 'Rendkívüli esemény'],
    delay_less_then_3_hours: ['Kérelem lezárva', 'A késés kevesebb, mint 3 óra'],
    flight_outside_eu: ['Kérelem lezárva', 'Az EU-n kívüli járat'],
    claim_successfully_completed: ['Kérelem lezárva', 'Sikeresen lezárva'],
    closed_duplicated_request: ['Kérelem lezárva', 'Duplikált kérelem'],
    closed_undelivered_docs: ['Kérelem lezárva', 'Dokumentumok nem érkeztek meg'],
    court_start_process: ['Bírósági eljárás', 'Kérelem benyújtása'],
    court_received_docs: ['Bírósági eljárás', 'Dokumentumok átvétele'],
    court_lawyer_delegation: ['Bírósági eljárás', 'Az ügy átadása az ügyvédi irodának']
  },
  claim_status: {
    created: 'Létrehozva',
    validation: 'Jogosultság ellenőrzése',
    legal_documents: 'Szerződéskötés',
    arline_communications: 'Kommunikáció a légitársasággal',
    court_communication: 'Bírósági kommunikáció',
    rejected: 'Elutasított kérelem - Rendkívüli esemény',
    completed: 'Sikeresen lezárva',
    finished: 'Kérelem lezárása',
    stopped_documentation_needed: 'Előrehaladás felfüggesztve - Hiányzó dokumentumok',
    stopped_data_needed: 'Előrehaladás felfüggesztve - Hiányzó adatok',
    court_communications: 'Bírósági kommunikáció'
  },
  confirm: 'Megerősítés',
  claim_success_message:
    'A kérelmet sikeresen benyújtották. Kérelmének állapotát kérjük, kövesse a Kérelmeim fül alatt.',
  contact_message_success: 'Sikeresen elküldte a kapcsolati űrlapot. Köszönjük.',
  footer: {
    submit_claim_slogan: 'Nálunk csak akkor fizet, ha sikeresen megszerzi a kártérítést.',
    our_services: 'Szolgáltatásaink',
    delay_longer_than_3_hours: 'Több mint 3 órás járatkésés',
    canceled_flight: 'Járattörlés előzetes tájékoztatás nélkül',
    delayed_connecting_flight: 'Csatlakozó járat lekésett',
    delay_longer_than_3_hours_url: '/a-repülés-elmaradásért-600-ig-kereshetsz',
    canceled_flight_url: '/váratlan-járattörlés-lehet-jogosult-hoz-600-e-ig terjedő-kedvezményre',
    delayed_connecting_flight_url: '/elmulasztott-csatlakozó-járatokra-600-ig-előre-pályázhat',
    customer_service_message:
      'Legyen velünk kapcsolatban. Kövessen minket a közösségi médiában, ahol híreket, egyedi cikkeket és további információkat talál az Ön jogosultságairól!',
    customer_service_title: 'Lépjen kapcsolatba velünk',
    company_slogan:
      'Segítünk ügyfeleinknek kártérítést szerezni a légi közlekedésben történő járattörlésért és késésekért, szakértő csapattal. Töltse ki az online kérelmet, és csak siker esetén fizet.',
    company_email: "info{'@'}meskanieletu.sk",
    company_name: 'Studio JŠ, s.r.o. - Meskanieletu.sk',
    company_address: 'Május utca, Krásno nad Kysucou 02302, Szlovákia',
    lawyer_documents: {
      title: 'Jogi dokumentumok',
      general_terms: 'Általános Szerződési Feltételek',
      privacy_policy: 'Adatvédelem',
      cookies_files: 'Európai Parlament és Tanács (EK) 261/2004 rendelet'
    },
    menu: {
      title: 'Menü',
      about_us: 'Rólunk',
      price_list: 'Árak',
      faq: 'FAQ',
      blog: 'Blog',
      urls: {
        about_us: '/rolunk',
        price_list: '/árlista',
        faq: '/gyakran-ismetelt-kerdesek',
        terms_and_conditions: '/altalanos-kereskedelmi feltetelek',
        privacy_policy: '/szemelyes-adatok-vedelme-es-feldolgozása',
        blog: '/blog'
      }
    }
  },
  buttons: {
    submit_claim: 'Kérelem benyújtása',
    logout: 'Kijelentkezés',
    login: 'Bejelentkezés',
    check_claim: 'Jogosultság ellenőrzése',
    skip: 'Átugrás'
  },
  navbar: {
    my_claims: 'Kérelmeim',
    contact: 'Kapcsolattartási adatok',
    bank: 'Banki adatok',
    settings: 'Beállítások',
    show_profile: 'Profil megtekintése',
    change_password: 'Jelszó megváltoztatása',
    logout: 'Kijelentkezés'
  },
  personal_data: 'Személyes adatok',
  personal_data_view: {
    title: 'Az Ön adatai',
    description:
      'Kérjük, ellenőrizze személyes kapcsolattartási adatait. Ezeknek az információknak köszönhetően kérelme gyorsabban és egyszerűbben feldolgozható lesz. Ezek az adatok lehetővé teszik számunkra, hogy automatikusan kitöltsük a szerződéseket, vagy szükség esetén felvehessük Önnel a kapcsolatot. Köszönjük az együttműködését!',
    bank_account_title: 'Bankszámla',
    bank_account_description:
      'A bankszámla-információk a szerződéses dokumentáció előkészítéséhez és a kártérítés sikeres teljesítése esetén a pénzösszeg átutalásához szükségesek.',
    iban_title: 'IBAN formátumú bankszámlaszám'
  },
  address: 'Lakcím',
  address_line_2: 'Cím 2. sor',
  city: 'Város',
  postal_code: 'Irányítószám',
  country: 'Ország',
  phone_number: 'Telefonszám',
  bank_account_owner: 'Számlatulajdonos neve',
  iban: 'IBAN',
  swift: 'SWIFT',
  airplaneBackgroundText:
    'A kérelme további részleteiért jelentkezzen be fiókjába a kérelem benyújtása után.',
  cancel: 'Mégse',
  application_done_title: 'Minden szükséges információnk megvan!',
  application_done_subtitle:
    'Fel fogjuk dolgozni a kártérítési kérelmét, és e-mailben tájékoztatjuk a további lépésekről. Kérelme aktuális állapotát a fiókjában nyomon követheti. További információkat e-mailben már megkapott.',
  add_document: 'Dokumentum hozzáadása',
  reprimand_contract: 'Meghatalmazás',
  delegation_document: 'Kérelmező személyes adatai',
  my_claims_view: {
    personal_information: 'Indulási hely',
    departure_place: 'Érkezési hely',
    arrival_place: 'Kérelem',
    claim: 'KÉRELEM SZÁMA',
    claim_number: 'Kérelmező neve',
    requestor: 'Benyújtás dátuma',
    submission_date: 'Minden dokumentumot el kell küldeni.',
    all_documents_requirement: 'Minden dokumentumot el kell küldeni.',
    upload_documents: 'Dokumentumok feltöltése',
    here: 'Kérelem típusa',
    claim_type: 'Az alkalmazás típusa',
    title: 'Kérelem benyújtása gyorsan és egyszerűen!',
    fast_and_easy: 'Gyors és egyszerű',
    fast_and_easy_neutral: 'gyorsan és egyszerűen!',
    all_airlines: 'Minden légitársaság',
    no_risks: 'Kockázat nélküli díjak',
    fast_handling: 'Csak 3 percet vesz igénybe.',
    show_more: 'Kérelem részleteinek megtekintése',
    show_less: 'Kevesebb megjelenítése',
    residence: 'Lakcím',
    email_address: 'E-mail cím',
    claim_status: 'Kérelem aktuális állapota',
    other_passengers: 'További utasok',
    description:
      'Jogász csapatunk ellenőrzi a kérelmét, és folyamatosan tájékoztatni fogja a folyamat minden egyes mérföldkövéről.',
    day_of_flight: 'Járat napja'
  },
  IdOrPass: 'Személyi igazolvány (előlap) vagy útlevél',
  claimDocuments: 'Kérelmi dokumentumok',
  representationDocuments: 'Meghatalmazási dokumentumok',
  deleteConfirmationText:
    'A dokumentumhoz már nem fog hozzáférni. A dokumentum véglegesen törlésre kerül.',
  deleteConfirmationTitle: 'Valóban törölni szeretné ezt a dokumentumot?',
  coPassengerNumber: 'Utas száma',
  unknownFile: 'Ismeretlen fájl',
  approvedByAdmin: 'Adminisztrátor által jóváhagyva',
  missingDocuments: 'Hiányzó dokumentumok',
  sentForValidation: 'Ellenőrzésre elküldve',
  IdontHave: 'Nincs',
  other_airline_communication:
    'Kapcsolatba lépett a légitársasággal vagy más céggel a kártérítés megszerzése érdekében?',
  yes_communicated: 'Igen, kapcsolatba léptünk',
  no_communication: 'Nem, nem léptünk kapcsolatba',
  communication: 'A kommunikáció rövid leírása',
  explain_communication:
    'Itt röviden írja le kommunikációját a légitársasággal vagy a kártérítéssel foglalkozó céggel',
  send_without_documentation: 'Dokumentumok nélküli elküldés',
  send: 'Elküldés',
  continue_to_detail: 'Tovább a kérelem részleteihez',
  rest_upload_later: 'A maradékot később pótolom',
  delete: 'Törlés',
  reprimand_contract_description: 'A meghatalmazás egy szerződés, amelyet a cégünkkel ír alá.',
  delegation_document_description:
    'A meghatalmazás egy dokumentum, amellyel engedélyezi számunkra, hogy Önt képviseljük a kérelem során.',
  idOrPass_description:
    'A személyi igazolvány vagy útlevél szükséges a személyazonosság ellenőrzéséhez.',
  step_number: 'Lépés sz.'
}
