<script setup lang="ts">
import Button from '@/components/Button.vue'

defineProps<{
  confirmationText: string
  confirmationTitle: string
}>()

const emits = defineEmits(['update:confirmation'])

const handleConfirmation = (value: boolean) => {
  emits('update:confirmation', value)
}
</script>

<template>
  <v-card color="transparent" elevation="0">
    <v-card-text class="text">
      <h2 class="title">{{ confirmationTitle }}</h2>
      {{ confirmationText }}
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <Button
        type="primary-blue"
        :title="$t('yes')"
        width="84px"
        @click="handleConfirmation(true)"
      />
      <Button
        type="primary-outlined-blue"
        :title="$t('no')"
        width="84px"
        @click="handleConfirmation(false)"
      />
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.text {
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.title {
  color: #fff;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-wrap: break-word;
  padding-bottom: 10px;
}
</style>
