<script setup lang="ts">
import { computed } from 'vue'
import Button from '@/components/Button.vue'

const PRESENTATION_PAGE = import.meta.env.VITE_PRESENTATION_APP_URL

const composePath = (path: string): string => {
  return `${PRESENTATION_PAGE}${path}`
}

const submitClaimPath = '/distance-verification'
const RIGHTS_LINK = 'https://eur-lex.europa.eu/legal-content/SK/TXT/?uri=celex%3A32004R0261'
const currentYear = computed(() => {
  return new Date().getFullYear()
})
</script>

<template>
  <v-footer>
    <v-row class="hidden-lg-and-up">
      <v-col cols="12" class="footer-title">{{ $t('footer.menu.title') }} </v-col>
      <v-col cols="12" class="footer-body"
        ><a :href="composePath($t('footer.menu.urls.about_us'))">{{
          $t('footer.menu.about_us')
        }}</a></v-col
      >
      <v-col cols="12" class="footer-body"
        ><a :href="composePath($t('footer.menu.urls.faq'))">{{ $t('footer.menu.faq') }}</a></v-col
      >
      <v-col cols="12" class="footer-body"
        ><a :href="composePath($t('footer.menu.urls.blog'))">{{ $t('footer.menu.blog') }}</a></v-col
      >
      <v-col cols="12" class="footer-body"
        ><a :href="composePath($t('footer.menu.urls.price_list'))">{{
          $t('footer.menu.price_list')
        }}</a></v-col
      >

      <v-col cols="12" class="footer-title">{{ $t('footer.our_services') }} </v-col>
      <v-col cols="12" class="footer-body">
        <a :href="composePath($t('footer.delay_longer_than_3_hours_url'))">{{
          $t('footer.delay_longer_than_3_hours')
        }}</a>
      </v-col>

      <v-col cols="12" class="footer-body">
        <a :href="composePath($t('footer.canceled_flight_url'))">{{
          $t('footer.canceled_flight')
        }}</a>
      </v-col>

      <v-col cols="12" class="footer-body">
        <a :href="composePath($t('footer.delayed_connecting_flight_url'))">{{
          $t('footer.delayed_connecting_flight')
        }}</a>
      </v-col>

      <v-col cols="12" class="footer-title">{{ $t('footer.lawyer_documents.title') }} </v-col>
      <v-col cols="12" class="footer-body">
        <a :href="composePath($t('footer.menu.urls.terms_and_conditions'))">
          {{ $t('footer.lawyer_documents.general_terms') }}</a
        >
      </v-col>
      <v-col cols="12" class="footer-body"
        ><a :href="composePath($t('footer.menu.urls.privacy_policy'))">{{
          $t('footer.lawyer_documents.privacy_policy')
        }}</a></v-col
      >
      <v-col cols="12" class="footer-body"
        ><a :href="RIGHTS_LINK" target="blank">{{ $t('footer.lawyer_documents.cookies_files') }}</a>
      </v-col>

      <v-col cols="12" class="footer-title">{{ $t('footer.customer_service_title') }} </v-col>
      <v-col cols="12" class="footer-body" style="padding-bottom: 0">
        {{ $t('footer.customer_service_message') }}
      </v-col>

      <v-col cols="12" class="footer-title" style="padding-top: 0; padding-bottom: 0">
        <v-btn
          class="contact-icon"
          icon="customIconSet:facebook"
          href="https://www.facebook.com/meskanieletu.claimwin/"
        ></v-btn>
        <v-btn
          class="contact-icon"
          icon="customIconSet:instagram"
          href="https://www.instagram.com/meskanieletu.sk/"
        ></v-btn>
        <v-btn
          class="contact-icon"
          icon="customIconSet:linkedin"
          href="https://www.linkedin.com/company/meskanieletu-kompenzacia/"
        ></v-btn>
        <v-btn
          class="contact-icon"
          icon="customIconSet:x_twitter"
          href="https://x.com/claimwin"
        ></v-btn>
      </v-col>

      <v-col cols="12" class="mobile-logo">
        <a href="https://meskanieletu.sk" target="_blank">
          <v-img src="/img/meskanieletu_dark.svg" alt="Meškanie letu" :width="138" />
        </a>
      </v-col>

      <v-col cols="12" class="footer-body-small" style="padding-top: 0">
        {{ $t('footer.company_slogan') }}
      </v-col>
      <v-col cols="12" class="footer-body-small" style="padding-top: 0">
        {{ $t('footer.submit_claim_slogan') }}
      </v-col>

      <v-col
        cols="12"
        class="pa-0"
        style="display: flex; align-items: center; justify-content: center"
      >
        <Button
          class="submit_claim"
          type="primary-red"
          :title="$t('buttons.submit_claim')"
          :href="submitClaimPath"
        />
      </v-col>

      <v-col cols="12" class="footer-body-small" style="padding-top: 0">
        <b style="color: white">{{ $t('footer.company_name') }}</b>
        <div style="padding-top: 3px">
          {{ $t('footer.company_address') }}
        </div>
      </v-col>

      <v-col cols="12" class="footer-body-small pt-12 pb-4 text-center"
        >Copyright © {{ currentYear }} meskanieletu.sk
      </v-col>
    </v-row>
    <v-container class="content-footer">
      <v-row class="hidden-md-and-down">
        <v-col cols="3">
          <a href="https://meskanieletu.sk" target="_blank">
            <v-img src="/img/meskanieletu_dark.svg" alt="Meškanie letu" :width="237" :height="30" />
          </a>

          <div class="footer-body-small" style="margin-top: 20px">
            {{ $t('footer.company_slogan') }}
          </div>

          <div class="footer-body-small" style="margin-top: 22px">
            {{ $t('footer.submit_claim_slogan') }}
          </div>

          <Button
            class="submit_claim"
            type="primary-red"
            :title="$t('buttons.submit_claim')"
            :href="submitClaimPath"
          />
        </v-col>

        <v-col cols="1"></v-col>
        <v-col cols="2">
          <!--          menu-->
          <div class="footer-title" style="padding-top: 0">
            {{ $t('footer.menu.title') }}
          </div>
          <div class="footer-body">
            <a :href="composePath($t('footer.menu.urls.about_us'))">{{
              $t('footer.menu.about_us')
            }}</a>
          </div>
          <div class="footer-body">
            <a :href="composePath($t('footer.menu.urls.faq'))">{{ $t('footer.menu.faq') }}</a>
          </div>
          <div class="footer-body">
            <a :href="composePath($t('footer.menu.urls.blog'))">{{ $t('footer.menu.blog') }}</a>
          </div>
          <div class="footer-body">
            <a :href="composePath($t('footer.menu.urls.price_list'))">{{
              $t('footer.menu.price_list')
            }}</a>
          </div>
        </v-col>

        <v-col cols="3">
          <div class="footer-title" style="padding-top: 0">
            {{ $t('footer.lawyer_documents.title') }}
          </div>
          <div class="footer-body">
            <a :href="composePath($t('footer.menu.urls.terms_and_conditions'))">{{
              $t('footer.lawyer_documents.general_terms')
            }}</a>
          </div>
          <div class="footer-body">
            <a :href="composePath($t('footer.menu.urls.privacy_policy'))">{{
              $t('footer.lawyer_documents.privacy_policy')
            }}</a>
          </div>
          <div class="footer-body">
            <a :href="RIGHTS_LINK" target="blank">{{
              $t('footer.lawyer_documents.cookies_files')
            }}</a>
          </div>
          <div class="footer-title">
            {{ $t('footer.our_services') }}
          </div>

          <div class="footer-body">
            <a :href="composePath($t('footer.delay_longer_than_3_hours_url'))">{{
              $t('footer.delay_longer_than_3_hours')
            }}</a>
          </div>
          <div class="footer-body">
            <a :href="composePath($t('footer.canceled_flight_url'))">{{
              $t('footer.canceled_flight')
            }}</a>
          </div>
          <div class="footer-body">
            <a :href="composePath($t('footer.delayed_connecting_flight_url'))">{{
              $t('footer.delayed_connecting_flight')
            }}</a>
          </div>
        </v-col>

        <v-col cols="3">
          <div class="footer-title" style="padding-top: 0">
            {{ $t('footer.customer_service_title') }}
          </div>
          <div class="footer-body" style="padding-bottom: 0">
            {{ $t('footer.customer_service_message') }}
          </div>
          <div>
            <v-btn
              class="contact-icon"
              icon="customIconSet:facebook"
              href="https://www.facebook.com/meskanieletu.claimwin/"
            ></v-btn>
            <v-btn
              class="contact-icon"
              icon="customIconSet:instagram"
              href="https://www.instagram.com/meskanieletu.sk/"
            ></v-btn>
            <v-btn
              class="contact-icon"
              icon="customIconSet:linkedin"
              href="https://www.linkedin.com/company/meskanieletu-kompenzacia/"
            ></v-btn>
            <v-btn
              class="contact-icon"
              icon="customIconSet:x_twitter"
              href="https://x.com/claimwin"
            ></v-btn>
          </div>

          <div class="footer-body-small" style="padding-top: 22px">
            <b style="color: white">{{ $t('footer.company_name') }}</b>
          </div>
          <div class="footer-body-small">
            {{ $t('footer.company_address') }}
          </div>
        </v-col>
      </v-row>

      <v-row class="hidden-md-and-down justify-center footer-body-small pt-2 pb-5">
        Copyright © {{ currentYear }} meskanieletu.sk
      </v-row>
    </v-container>
  </v-footer>
</template>

<style scoped>
.footer-title {
  color: #fff;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 18px;
  padding-top: 32px;
}

.footer-body {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  padding-top: 0;
  padding-bottom: 12px;
}

.footer-body-small {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  align-content: start;
}

a {
  text-decoration: none !important;
  color: rgba(255, 255, 255, 0.6);
}

a:hover {
  text-decoration: underline !important;
}

.mobile-logo {
  margin-top: 50px;
  margin-bottom: 32px;
}

.v-footer {
  background-color: #3e3caa;
  color: white;
}

.submit_claim {
  margin-bottom: 57px;
  margin-top: 58px;
  width: 220px !important;
  text-transform: none !important;

  @media (min-width: 1280px) {
    margin-top: 37px;
    text-transform: none !important;
  }
}

.contact-icon {
  margin-right: 19px;
  margin-top: 19px;
  width: 40px;
  height: 40px;
}

a {
  text-decoration: none !important;
  text-transform: none !important;
}
.content-footer {
  padding-left: 0;
  padding-right: 0;
  width: 1320px !important;
  max-width: 1320px !important;
  padding-top: 62px;
}
</style>
