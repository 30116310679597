<script setup lang="ts">
import Button from '@/components/Button.vue'
const model = defineModel({
  required: true,
  type: Array<File>
})

const emits = defineEmits(['update:uploadFiles'])

const exitDialog = () => {
  emits('update:uploadFiles')
}

const cancelUpload = () => {
  model.value = []
  exitDialog()
}
</script>

<template>
  <v-card color="transparent" elevation="0">
    <v-card-title class="title">
      {{ $t('upload_documents_load_docs') }}
    </v-card-title>
    <v-card-text>
      <v-file-input
        color="white"
        v-model="model"
        accept="image/*,application/pdf"
        :label="$t('upload_documents_load_docs_label')"
        prepend-icon="mdi-paperclip"
        :clearable="true"
        :multiple="true"
        outlined
      ></v-file-input>
    </v-card-text>
    <v-card-actions class="d-flex justify-center">
      <Button
        type="primary-blue"
        :title="$t('upload')"
        width="84px"
        @click="exitDialog"
        :disabled="model?.length === 0"
      />
      <Button
        type="primary-outlined-blue"
        :title="$t('cancel')"
        width="84px"
        @click="cancelUpload"
      />
    </v-card-actions>
  </v-card>
</template>

<style scoped>
.v-input {
  color: white;
}
.title {
  color: #fff;
  text-align: center;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  word-wrap: break-word;
}
</style>
