import type {
  AlertType,
  ClaimResponse,
  Customer,
  IClaimStepper,
  IProcessedClaim,
  IProcessedCustomer
} from '@/interfaces/interfaces'
import { formatDate } from 'date-fns'
import { EXTERNAL_STATUS_MAP } from '@/constants/constants'
import i18n from '../../locales/i18n.config'

function parseDate(date: string | Date | null) {
  return date ? formatDate(date, 'dd.MM.yyyy') : '-'
}

function personalData(personalData: Customer): IProcessedCustomer {
  const birthDate = parseDate(personalData.date_of_birth)
  return {
    birthDate,
    customerId: personalData.id,
    firstName: personalData.first_name || '-',
    lastName: personalData.last_name || '-',
    fullName: `${personalData.first_name} ${personalData.last_name}`,
    email: personalData.email || '-',
    phone: personalData.phone_number || '-',
    city: personalData.city || '-',
    postalCode: personalData.postal_code || '-',
    country: personalData?.setting_country?.name || personalData?.country || '-',
    street: personalData.street || '-'
  }
}

function parseFlightProblemInterval(claim: ClaimResponse): string {
  if (claim?.flight_problem_interval?.length > 0) {
    return `- ${i18n.global.t(claim.flight_problem_interval)}`
  } else {
    return ''
  }
}

function checkWarningEnabled(alertData: AlertType | null) {
  const isEmptyAlert =
    !alertData ||
    (!alertData.message && !alertData.alert_type) ||
    Object.keys(alertData).length === 0

  return !isEmptyAlert
}

function handleStepperProcessing(
  claimStepper: IClaimStepper[],
  alert: AlertType | null,
  step_number: number | null
) {
  const steps = claimStepper || []
  const isWarningEnabled = checkWarningEnabled(alert)
  const statusStep = step_number !== null ? step_number : 1
  const statusName = step_number !== null ? claimStepper[step_number - 1].step_name : 'created'
  const mappedSteps = steps.map((step) => {
    return {
      name: i18n.global.t(`claim_status.${step.step_name}`),
      status: step.status
    }
  })
  let stepAlert = null
  if (isWarningEnabled) {
    stepAlert = alert
  }
  return {
    mappedSteps,
    stepAlert,
    isWarningEnabled,
    statusStep,
    statusName
  }
}

export function handleClaimPreprocessing(claim: ClaimResponse): IProcessedClaim {
  const submissionDate = parseDate(claim.created_at)
  const flightDate = parseDate(claim.flight_ticket?.flight_date)
  const processedCustomer = personalData(claim.customer)
  const processedStepper = handleStepperProcessing(
    claim.status_data.stepper,
    claim.status_data.alert,
    claim.status_data.step_number
  )
  return {
    ...processedCustomer,
    ...processedStepper,
    flightDate,
    submissionDate,
    coPassengers: claim.passengers.map(personalData) || [],
    departurePlace: `${claim.flight_ticket?.catalog_flight?.airport_from?.municipality || '-'} (${claim.flight_ticket?.catalog_flight?.airport_from?.iata_code || '-'})`,
    arrivalPlace: `${claim.flight_ticket?.catalog_flight?.airport_to?.municipality || '-'} (${claim.flight_ticket.catalog_flight?.airport_to?.iata_code || '-'})`,
    flightProblem: claim.flight_problem,
    flightProblemInterval: parseFlightProblemInterval(claim),
    claimId: claim.id,
    claimStatus: EXTERNAL_STATUS_MAP[claim.external_status.id] || claim.external_status.name,
    flightNumber: claim.flight_ticket?.catalog_flight?.flight_number || '-',
    reservationNumber: claim.flight_ticket?.reservation_number || '-',
    claimNumber: claim.internal_claim_number || '-',
    airlineName: claim.flight_ticket?.catalog_flight?.airline?.name || '-',
    missingClaimAttachments: claim.missing_claim_attachments || false,
    missingLegalDocuments: claim.missing_legal_documents || false
  }
}
