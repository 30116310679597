import { defineStore } from 'pinia'
import { useMyClaimService } from '@/service/services/MyClaimService'
import type { ClaimPost, FileResponse, IProcessedClaim } from '@/interfaces/interfaces'
import { handleClaimPreprocessing } from '@/utils/helpers'
import { DocumentCategory, type DocumentGroup, DocumentState } from '@/enumerators/enumerators'
import { useCustomerStore } from '@/stores/customer'

export const useClaimStore = defineStore('claim', {
  state() {
    return {
      currentId: undefined as number,
      currentClaim: null as IProcessedClaim | null
    }
  },
  getters: {
    getCurrentClaim: function (state): IProcessedClaim | null {
      return state.currentClaim
    },
    getCurrentId: function (state): number {
      return state.currentId
    }
  },
  actions: {
    currentId: undefined,
    async postClaim(claim: ClaimPost): Promise<number> {
      const { postClaim } = useMyClaimService()

      const data = await postClaim(claim)
      this.currentId = data?.id
      const customerStore = useCustomerStore()
      if (data?.customer && customerStore.user !== null) {
        customerStore.setUser(data.customer)
      }
      this.currentClaim = handleClaimPreprocessing(data)
      return data?.id
    },

    async getMyClaims(): Promise<IProcessedClaim[]> {
      const { getMyClaims } = useMyClaimService()

      const myClaims = await getMyClaims()

      return (myClaims || []).map(handleClaimPreprocessing)
    },
    async getMyClaimDetail(id: number) {
      const { getMyClaim } = useMyClaimService()

      return await getMyClaim(id)
    },
    async uploadDocument(
      claimId: string,
      requestData: Blob | null,
      customerId: number,
      category: DocumentCategory,
      groupFile: DocumentGroup,
      documentState: DocumentState
    ): Promise<FileResponse | null> {
      const { uploadDocument } = useMyClaimService()

      return await uploadDocument(
        claimId,
        requestData,
        customerId,
        category,
        groupFile,
        documentState
      )
    },

    async removeDocument(documentId: number): Promise<boolean> {
      const { removeDocument } = useMyClaimService()

      return await removeDocument(documentId)
    },

    async getMyClaimFiles(claimId: number): Promise<FileResponse[]> {
      const { getClaimFiles } = useMyClaimService()

      return await getClaimFiles(claimId)
    }
  }
})
