<script setup lang="ts">
import { useDisplay } from 'vuetify'
import { useSiteStore } from '@/stores/site'
import { onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

withDefaults(
  defineProps<{
    chevronColor?: string
  }>(),
  {
    chevronColor: 'black'
  }
)

const { mobile } = useDisplay()
const siteStore = useSiteStore()

onMounted(() => {
  setLanguage(siteStore.selectedLanguage)
})

const { locale } = useI18n({ useScope: 'global' })

const setLanguage = (lang: string) => {
  siteStore.setSelectedLanguage(lang)
  locale.value = lang
}
</script>

<template>
  <v-menu offset-y transition="slide-y-transition">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" variant="text" class="language-selector">
        {{ $i18n.locale }}
        <template v-slot:append>
          <v-icon
            :color="chevronColor"
            icon="mdi-chevron-down"
            style="margin-left: -10px; margin-right: 5px"
          />
        </template>
      </v-btn>
    </template>
    <v-list color="transparent" rounded="0">
      <v-list-item
        v-for="(lang, i) in $i18n.availableLocales"
        class="item-account"
        :key="i"
        color="black"
        @click="
          () => {
            setLanguage(lang)
            $i18n.locale = lang
          }
        "
      >
        <v-list-item-title class="text-uppercase cursor-pointer">{{ lang }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>
.language-selector {
  width: 10px !important;
  margin: 0 !important;
  height: 31px !important;
  padding: 0 !important;
  font-size: 14px;
}

:deep(.v-btn__overlay) {
  width: 0px !important;
}
:deep(.v-btn__underlay) {
  width: 0px !important;
}
</style>
