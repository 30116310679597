export default {
  error_messages: {
    default_error: 'Niečo sa pokazilo',
    access_token_expired: 'Vaša relácia vypršala. Prosím, prihláste sa znova.',
    claim_not_found: 'Požiadavka nenájdená',
    not_verified_email: 'Váš email nie je overený. Prosím, overte váš email.',
    unknown_email: 'Neznámy email',
    email_occupied: 'Email je už obsadený',
    different_new_passwords: 'Nové heslá sa líšia',
    could_not_validate_credentials: 'Nepodarilo sa overiť prihlasovacie údaje',
    incorrect_email_or_password: 'Nesprávny email alebo heslo',
    weak_password: 'Slabé heslo',
    invalid_reset_token: 'Neplatný token na resetovanie',
    incorrect_old_password: 'Nesprávne staré heslo',
    not_found: 'Záznam s daným identifikátorom neexistuje',
    not_authorized: 'Nie ste oprávnený vykonať túto akciu',
    entity_too_large: 'Príloha je príliš veľká',
    url_already_exists: 'URL už existuje',
    recaptcha_error: 'Chybná Recaptcha',
    login_again: 'Please log in again'
  },
  distance_verification_subtitle: 'Informácie slúžia pre kontrolu, či máte nárok na kompenzáciu.',
  distance_verification_flight_from: 'Odlet z',
  distance_verification_flight_to: 'Prílet do',
  no_results: 'Nenašli sa žiadne výsledky',
  enter_city_airport: 'Zadaj mesto alebo letisko',
  distance_verification_direct_flight: 'Mali ste priamy let?',
  distance_verification_stepovers: 'Kde sa nachádzal váš prestup?',
  distance_verification_stepovers_other: 'Kde sa nachádzal váš ďalší prestup?',
  next: 'Ďalej',
  previous: 'Späť',
  yes: 'Áno',
  no: 'Nie',
  passenger_details: 'Údaje o pasažieroch',
  flight_identification_airline: 'Názov leteckej spoločnost',
  flight_identification_number: 'Váš let',
  flight_identification_number_placeholder: 'napr. NQH5UX',
  flight_identification_date: 'Dátum letu',
  flight_identification_reservation_number: 'Rezervačné číslo',
  flight_identification_reservation_number_placeholder: 'napr. FR1234',
  flight_identification_alert:
    'Váš potvrdzovací kód je v e-maile, ktorý ste dostali od leteckej spoločnosti po rezervácii letu. Tento kód je tiež viditeľný na vašej palubnej karte. Vyzerá takto: AB12CD (šesťmiestne slovo zložené z písmen a číslic). V prípade charterového letu vyplňte 000000 (šesť nul).',
  reasons_application_title: 'Dôvody žiadania kompenzáci',
  reasons_application_subtitle:
    'Doplnkové informácie, ktoré nám môžu pomôcť k rýchlejšiemu vybaveniu kompenzácie.',
  reasons_application_problem: 'Aký bol problém letu, s ktorým ste sa stretli?',
  reasons_application_problem_label: 'Zvoľ problém',
  reasons_application_interval: 'Koľko hodín trvalo Vaše meškanie letu?',
  reasons_application_interval_label: 'Vyber možnosť',
  reasons_application_problem_type: 'Oznámila vám spoločnosť dôvod meškania/zrušenia letu?',
  reasons_application_airport_problem: 'Aký bol problém letu, s ktorým ste sa stretli?',
  reasons_application_problem_description: 'V skratke opíšte svoj zážitok',
  reasons_application_problem_description_label: 'S skými nepríjemnosťami ste sa stretli?',
  reasons_application_agreement_1: 'Súhlasím so',
  reasons_application_agreement_2: 'spracovaním mojich osobných údajov.',
  reasons_application_agreement_2_general: 'všeobecnými obchodnými podmienkami',
  upload_documents: 'Dokumenty',
  upload_documents_title: 'Dokumenty ku overeniu nároku',
  upload_documents_load_docs: 'Nahrajte dokument',
  upload_documents_load_docs_label: 'Vyberte súbor',
  upload_documents_btn_later: 'Doplním neskôr',
  files: 'súbory',
  documents_1: 'Letenka resp. palubný lístok (boarding pass) / všetci cestujúci',
  documents_1_help:
    'Pokiaľ ste zadali do žiadosti viacerých cestujúcich, potrebujeme letenky od všetkých cestujúcich pre dotknutý let.',
  documents_2: 'Potvrdenie z letiska o meškani/zrušení letu',
  documents_2_help:
    'Jedná sa o dokument vystavený letiskom na požiadanie. Nejedná sa o odfotenú informačnú tabuľu a pod. Na to slúži riadok "Ďalšie dokumenty"',
  documents_3: 'Komunikácia s leteckou spoločnosťou',
  documents_3_help:
    'Kompletnú e-mailovú komunikáciu a zmluvnú dokumentáciu s leteckou spoločnosťou vo veci Vášho letu a taktiež aj predaja letenky, pokiaľ ste si ju zakúpili priamo od leteckej spoločnosti, vrátane obchodných podmienok.',
  documents_4: 'Komunikácia s cestovnou kanceláriou',
  documents_4_help:
    'Kompletnú e-mailovú komunikáciu a zmluvnu dokumentáciu s predajcom letenky / zájazdu (napr. cestovná kancelária, portál a pod.), pokiaľ ste si ju priamo nezakúpili od leteckej spoločnosti, vrátane obchodných podmienok.',
  documents_5: 'Komunikácia so spoločnosťou, zaoberajúcou sa odškodnením',
  documents_5_help:
    'Kompletnú e-mailovú komunikáciu, pokiaľ ste už prípad riešili s inou spoločnosťou, zaoberajúcou sa získaním kompenzácie za Váš zrušený let.',
  documents_6: 'Ostatné dokumenty',
  documents_6_help: 'Ostatné dokumenty týkajúce sa Vášho prípadu',
  documents_other: 'Ďalšie dokumenty',
  documents_other_help:
    'Tu nahrajte akýkoľvek ďalší dokument/fotku, ktorý nebol vyššie spomenutý a je podľa Vás dôležitý.',
  download: 'Stiahnuť',
  card_flight_airline: 'Letecká spoločnosť',
  card_flight_number: 'Číslo letu',
  card_flight_date: 'Dátum letu',
  card_personal_name: 'Meno',
  card_personal_last_name: 'Priezvisko',
  card_personal_email: 'Email',
  card_personal_date: 'Dátum narodenia',
  card_personal_country: 'Krajina',
  card_personal_street: 'Ulica',
  card_personal_city: 'Mesto',
  card_personal_postal: 'PSČ',
  card_personal_contact: 'Telefónne číslo',
  login: 'Prihlásenie',
  my_account: 'Klientská zóna',
  application_progress: 'Proces podania žiadosti',
  add: 'Pridať',
  remove: 'Odobrať',
  password_change: 'Zmena hesla',
  password: 'Heslo',
  new_password: 'Nové heslo',
  new_password_confirm: 'Potvrdenie nového hesla',
  data_was_updated: 'Údaje boli úspešne uložené',
  password_confirm: 'Potvrdenie hesla',
  forgot_password: 'Zabudol som heslo',
  forgot_password_description:
    'Pre resetovanie hesla zadajte emailovú adresu, na ktorú vám bude zaslaný overovací email.',
  forgot_password_email: 'Odoslať overovací e-mail',
  forgot_password_email2: 'E-mail bol odoslaný na adresu:',
  confirm_email_alert: 'Úspešne ste potvrdili email. Pokračujte prosím prihlásením.',
  login2: 'Prihlásiť sa',
  or_use: 'alebo použi',
  forgot_password_question: 'Zabudol si heslo?',
  restore: 'Obnoviť',
  no_account_question: 'Ešte nemáš účet?',
  register: 'Registrovať sa',
  confirm_email_fail: 'Link na potvrdenie vypršal. Požiadajte o nový.',
  just_a_step: 'Už len jeden krok!',
  not_verified_description:
    'Pre dokončenie procesu registrácie je potrebné potvrdiť email. Ak vám neprišiel e-mail, môžete požiadať o znovuposlanie.',
  already_have_account: 'Už máš účet?',
  create_password: 'Vytvorenie hesla',
  restore_password: 'Obnovenie hesla',
  set_password: 'Nastaviť heslo',
  reset_password_change: 'Zmeniť heslo',
  reset_password_success_alert_1: 'Podarilo sa Vám úspešne nastaviť heslo.',
  reset_password_success_alert_2: 'Podarilo sa Vám úspešne zmeniť heslo.',
  adult_rule: 'Za osoby mladšie ako 18 rokov, podáva žiadosť ich zákonný zástupca.',
  same_email_rule: 'Email bol už použitý.',
  are_not_same_rule: 'Emaily sa nezhodujú',
  email_confirm: 'Potvrdenie emailu',
  repeat_email: 'Zopakuj email',
  contact_info: 'Kontaktné údaje',
  other_passengers: 'Ďalší cestujúci',
  application_state: 'STAV žiadosti:',
  my_claims_state: 'Stav žiadosti:',
  edit: 'Editovať',
  save: 'Uložiť',
  shorter_than_3_hours: 'Kratšie ako 3 hodiny',
  longer_than_3_hours: 'Dlhšie ako 3 hodiny',
  missed_connecting_flight: 'Zmeškanie nádväzného letu',
  duration_less_than_2_hours: '0 - 2 hodiny',
  duration_more_than_2_hours: 'Viac ako 2 hodiny',
  duration_never_flied: 'Nikdy neodletel',
  technical_problem: 'Technický problém',
  bad_weather: 'Zlé počasie',
  strike: 'Štrajk',
  airport_problem: 'Problém na letisku',
  other_flight: 'Ovplyvnenie inými letmi',
  higher_power: 'Vyššia moc',
  other: 'Iné',
  cant_remember: 'Nepamätám si',
  logout: 'Odhlásiť sa',
  upload: 'Nahrať',
  faq: 'Faq',
  blog: 'Blog',
  new_traveler: '+ Pridať ďalšieho cestujúceho',
  flight_delay: 'Meškanie letu',
  flight_cancel: 'Zrušený let',
  flight_connecting_delay: 'Meškanie nadväzujúceho letu',
  claim_submission: 'Podanie žiadosti',
  my_claims: 'Moje žiadosti',
  claim_stepper: {
    mobile: {
      title: '$t(claim_submission)',
      step1: 'Overenie letu',
      step2: 'Údaje o cestujúcom',
      step3: 'Dokončenie'
    }
  },
  validations: {
    required: 'Povinné pole',
    max_char: 'Maximálny počet znakov je',
    different_password: 'Hesla sa nezhodujú',
    password_format: 'Heslo musí obsahovať aspoň 8 znakov, veľké písmeno, malé písmeno a číslicu',
    postal_code: 'Zadajte platné PSČ',
    email: 'Zadajte platný email',
    age: 'Minimálny vek je',
    dateFormat: 'Dátum musí byť vo formáte DD. MM. RRRR',
    dateValidity: 'Neplatný dátum',
    dateFuture: 'Dátum nemôže byť v budúcnosti.'
  },
  alert_messages: {
    // Pozastavené napredovanie
    halted_missing_docs: ['Pozastavené napredovanie', 'Chýbajúce dokumenty'],
    halted_missing_data: ['Pozastavené napredovanie', 'Chýbajúce údaje'],

    // Žiadosť v procese
    claim_validation: ['Žiadosť v procese', 'Overenie nároku'],
    missing_claim_attachments: ['Žiadosť v procese', 'Chýbajúce prílohy'],
    missing_legal_documents: ['Žiadosť v procese', 'Chýbajúce právne dokumenty'],
    legal_documents: ['Žiadosť v procese', 'Overujeme právne dokumenty'],
    communications_airline: ['Žiadosť v procese', 'Komunikácia s leteckou spoločnosťou'],
    communications_court: ['Žiadosť v procese', 'Komunikácia so súdom'],

    // Ziadost ukoncena
    successfully_completed: ['Žiadosť ukončená', 'Úspešne ukončená'],
    closed_undelivered_documents: ['Žiadosť ukončená', 'Nedodané dokumenty'],
    closed_duplicate_request: ['Žiadosť ukončená', 'Duplicitná žiadosť'],
    extraordinary_situation: ['Žiadosť ukončená', 'Mimoriadna udalosť'],
    delay_less_then_3_hours: ['Žiadosť ukončená', 'Meškanie kratšie ako 3 hodiny'],
    flight_outside_eu: ['Žiadosť ukončená', 'Let mimo EÚ'],
    claim_successfully_completed: ['Žiadosť ukončená', 'Úspešne ukončená'],
    closed_duplicated_request: ['Žiadosť ukončená', 'Duplicitná žiadosť'],
    closed_undelivered_docs: ['Žiadosť ukončená', 'Nedoručené dokumenty'],

    // Sudny proces
    court_start_process: ['Súdny proces', 'Návrh na začatie'],
    court_received_docs: ['Súdny proces', 'Prijaté dokumenty'],
    court_lawyer_delegation: ['Súdny proces', 'Odovzdanie prípadu advokátskej kancelárií']
  },
  claim_status: {
    created: 'Vytvorená',
    validation: 'Overenie nároku',
    legal_documents: 'Zazmluvnenie strán',
    arline_communications: 'Komunikácia s leteckou spoločnosťou',
    court_communication: 'Komunikácia so súdom',
    rejected: 'Zamietnutá žiadosť - Mimoriadná udalosť',
    completed: 'Úspešne ukončená',
    finished: 'Zatvorenie žiadosti',
    stopped_documentation_needed: 'Pozastavené napredovanie - Chýbajúce dokumenty',
    stopped_data_needed: 'Pozastavené napredovanie - Chýbajúce údaje',
    court_communications: 'Komunikácia so súdom'
  },
  confirm: 'Potvrdiť',
  claim_success_message:
    'Žiadosť bola úspešne odoslaná. Stav Vašej žiadosti si prosím sledujte v záložke Moje žiadosti',
  contact_message_success: 'Úspešne ste odoslali kontaktný formulár. Ďakujeme.',
  footer: {
    submit_claim_slogan: 'U nás platíte len v prípade úspešného získania odškodného.',
    our_services: 'Naše služby',
    delay_longer_than_3_hours: 'Meškanie letu viac ako 3 hodiny',
    canceled_flight: 'Zrušenie letu bez informovania vopred',
    delayed_connecting_flight: 'Meškanie nádväzného letu',
    delay_longer_than_3_hours_url: '/za-meskanie-letu-mozete-ziskat-odskodne-az-do-vysky-600-e',
    canceled_flight_url:
      '/neocakavane-zrusenie-letu-mozno-mate-narok-na-odskodne-az-do-vysky-600-e',
    delayed_connecting_flight_url:
      '/za-zmeskany-nadvazny-let-si-mozno-uplatnit-odskodne-az-do-vysky-600-e',
    customer_service_message:
      'Buďte s nami v kontakte. Sledujte nás na sociálnych sieťach, kde nájdete novinky, jedinečné články a viac informácií o vašich nárokoch!',
    customer_service_title: 'Spojte sa snami',
    company_slogan:
      'Pomáhame klientom získavať odškodnenie v leteckej preprave za zrušenie a meškanie letu s odborným tímom. Vyplňte online žiadosť a zaplatíte len v prípade úspechu.',
    company_email: "info{'@'}meskanieletu.sk",
    company_name: 'Studio JŠ, s.r.o. - Meskanieletu.sk',
    company_address: 'Ul. 1. Mája, Krásno nad Kysucou 02302, Slovensko',
    lawyer_documents: {
      title: 'Právne dokumenty',
      general_terms: 'Obchodné podmienky',
      privacy_policy: 'Ochrana osobných údajov',
      cookies_files: 'Nariadenie EPaR (ES) č. 261/2004'
    },
    menu: {
      title: 'Menu',
      about_us: 'O nás',
      price_list: 'Cenník',
      faq: 'FAQ',
      blog: 'Blog',
      urls: {
        about_us: '/o-nas',
        price_list: '/cennik',
        faq: '/casto-kladene-otazky',
        terms_and_conditions: '/vseobecne-obchodne-podmienky',
        privacy_policy: '/ochrana-a-spracovanie-osobnych-udajov',
        blog: '/blog'
      }
    }
  },
  buttons: {
    submit_claim: 'Podať žiadosť',
    logout: 'Odhlásiť sa',
    login: 'Prihlásiť sa',
    check_claim: 'Overiť nárok',
    skip: 'Preskočiť'
  },
  navbar: {
    my_claims: 'Moje žiadosti',
    contact: 'Kontaktné údaje',
    bank: 'Bankové údaje',
    settings: 'Nastavenie',
    show_profile: 'Zobraziť profil',
    change_password: 'Zmeniť heslo',
    logout: 'Odhlásiť sa'
  },
  personal_data: 'Osobné údaje',
  personal_data_view: {
    title: 'Vaše údaje',
    description:
      'Prosím skontrolujte si svoje osobné kontaktné údaje. Vďaka týmto informáciám bude vybavovanie vašej žiadosti rýchlejšie a jednoduchšie. Vaše údaje nám umožnia automaticky predvyplniť zmluvy, alebo v prípade potreby Vás kontaktovať. Ďakujeme za vašu spoluprácu!',
    bank_account_title: 'Bankový účet',
    bank_account_description:
      'Informácie o bankovom účte slúžia pre potreby prípravy zmluvnej dokumentácie a rovnako aj na zaslanie finančnej čiastky v rozsahu príkaznej zmluvy, v prípade uspešnej kompenzácie.',
    iban_title: 'Číslo účtu v tvare IBAN'
  },
  address: 'Adresa bydliska',
  address_line_2: 'Adresa riadok 2',
  city: 'Mesto',
  postal_code: 'PSČ',
  country: 'Krajina',
  phone_number: 'Tel. číslo',
  bank_account_owner: 'Meno vlastníka účtu',
  iban: 'IBAN',
  swift: 'SWIFT',
  airplaneBackgroundText:
    'Pre viac detailov o svojej žiadosti sa prihláste do svojho účtu po podaní žiadosti.',
  cancel: 'Zrušiť',
  application_done_title: 'Máme všetko čo potrebujeme!',
  application_done_subtitle:
    'Posúdime vašu žiadosť na odškodnenie a pošleme vám informácie o ďalšom postupe na váš e-mail. Aktuálny stav vašej žiadosti môžete sledovať vo svojom účte. Viac informácií už máte v e-maili. ',
  add_document: 'Pridať dokument',
  reprimand_contract: 'Príkazná zmluva',
  delegation_document: 'Splnomocnenie',
  my_claims_view: {
    personal_information: 'Osobné údaje žiadateľa',
    departure_place: 'Miesto odletu',
    arrival_place: 'Miesto príletu',
    claim: 'Žiadosť',
    claim_number: 'ČÍSLO ŽIADOSTI',
    requestor: 'Meno žiadateľa',
    submission_date: 'Dátum podania',
    all_documents_requirement: 'Je potrebné odoslať všetky dokumenty.',
    upload_documents: 'Nahrať dokumenty',
    here: 'TU',
    claim_type: 'Typ žiadosti',
    title: 'Podanie žiadosti rýchlo a jednoducho!',
    fast_and_easy: 'Rýchle a jednoduché',
    fast_and_easy_neutral: 'rýchlo a jednoducho!',
    all_airlines: 'Všetky aerolinky',
    no_risks: 'Bez rizika poplatkov',
    fast_handling: 'Zaberie to len 3 min.',
    show_more: 'Zobraziť detail žiadosti',
    show_less: 'Zobraziť menej',
    residence: 'Bydlisko',
    email_address: 'Emailová adresa',
    claim_status: 'Aktuálny stav žiadosti',
    other_passengers: 'Další pasažieri',
    description:
      'Náš tím profesionálov z oblasti cestovného práva preverí vašu žiadosť a priebežne Vás bude informovať o každom dosiahnutom míľniku celého procesu.',
    day_of_flight: 'Deň letu'
  },
  IdOrPass: 'Občiansky preukaz (predná strana) alebo cestovný pas',
  claimDocuments: 'Dokumenty k nároku',
  representationDocuments: 'Dokumenty k zastupovaniu',
  deleteConfirmationText: 'K dokumentu sa už nebudete vedieť dostať. Dokument sa natrvalo vymaže.',
  deleteConfirmationTitle: 'Naozaj si prajete tento dokument vymazať?',
  coPassengerNumber: 'Cestujúci č.',
  unknownFile: 'Neznámy súbor',
  approvedByAdmin: 'Odobrene spravcom',
  missingDocuments: 'Chýbajúce dokumenty',
  sentForValidation: 'Odoslané ku overeniu',
  IdontHave: 'Nemám',
  other_airline_communication:
    'Komunikovali ste s leteckou spoločnosťou alebo inou spoločnosťou pre získanie kompenzácie?',
  yes_communicated: 'Áno, komunikovali sme',
  no_communication: 'Nie, nekomunikovali sme',
  communication: 'Stručný opis komunikácie',
  explain_communication:
    'Tu stručne popíšte svoju komunikáciu s leteckou spoločnosťou alebo spoločnosťou zaoberajúcou sa získaním odškodného',
  send_without_documentation: 'Odoslať bez dokumentov',
  send: 'Odoslať',
  continue_to_detail: 'Prejdi na detail žiadosti',
  rest_upload_later: 'Zvyšok doplním neskôr',
  delete: 'Vymazať',
  reprimand_contract_description:
    'Príkazná zmluva je zmluva, ktorú podpisujete s našou spoločnosťou.',
  delegation_document_description:
    'Splnomocnenie je dokument, ktorým nám dovolujete zastupovať vás v rámci žiadosti.',
  idOrPass_description: 'Občiansky preukaz alebo cestovný pas je potrebný pre overenie totožnosti.',
  step_number: 'Krok č.'
}
