<script setup lang="ts">
import { useSiteStore } from '@/stores/site'
import { useDisplay } from 'vuetify'
import { computed } from 'vue'
const siteStore = useSiteStore()
const { mobile } = useDisplay()

const location = computed(() => {
  return mobile.value ? 'top center' : 'left bottom'
})
</script>

<template>
  <v-snackbar
    v-model="siteStore.snackbar.show"
    :color="siteStore.snackbar.color"
    :location="location"
  >
    {{ siteStore.snackbar.message }}
  </v-snackbar>
</template>
x
