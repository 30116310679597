class Cookie {
  static getAll(): object {
    const result = {}
    try {
      const rawCookie = decodeURIComponent(document.cookie).split('; ')

      for (let i = 0; i < rawCookie.length; i++) {
        const cookie = rawCookie[i].split('=')
        result[cookie[0]] = cookie[1]
      }
    } catch (error) {
      console.log('error')
    }
    return result
  }

  static remove(name: string): void {
    document.cookie = name + '=; Max-Age=-99999999;'
  }

  static get(name: string): string | null {
    const cookies = this.getAll()
    let result = null

    if (Object.keys(cookies).length && name in cookies) {
      result = cookies[name]
    }

    return result
  }
}

export default Cookie
