<script setup lang="ts">
import { ClaimAlertStatusType } from '@/enumerators/enumerators'
import { computed } from 'vue'

const props = defineProps<{
  alertType: ClaimAlertStatusType
  alertMessage: string
}>()

const getColor = computed(() => {
  switch (props.alertType) {
    case ClaimAlertStatusType.ALERT:
      return '#C12F5D'
    case ClaimAlertStatusType.SUCCESS:
      return '#3E3CAA'
    default:
      return '#3E3CAA'
  }
})
</script>

<template>
  <div class="alert-container">
    <v-alert :color="getColor" class="custom-alert">
      <p class="text-white text-alignment">
        {{ $t(`alert_messages.${alertMessage}.0`, { returnObjects: true }) }}
      </p>
      <p class="text-style text-alignment">
        {{ $t(`alert_messages.${alertMessage}.1`, { returnObjects: true }) }}
      </p>
    </v-alert>
  </div>
</template>

<style scoped>
.text-style {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.text-alignment {
  text-align: right;

  @media (max-width: 1280px) {
    text-align: center;
  }
}
.alert-container {
  min-width: 269px;
}
.custom-alert {
  border-radius: 10px;
  height: 66px;
}
</style>
