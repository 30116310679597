import { createHttpClient } from '@/service/client'
import { handleApiError } from '@/utils/commsWrapper'
import type { SettingsCountryResponse } from '@/interfaces/interfaces'

const prefix = 'setting/'
export function useSettingService() {
  const client = createHttpClient(prefix)

  const getCountries = async (
    searchTerm: string = ''
  ): Promise<Array<SettingsCountryResponse> | undefined> => {
    try {
      const { data } = await client.get(`countries?search_term=${searchTerm}`)
      return data
    } catch (error) {
      handleApiError(error)
    }
  }

  return { getCountries }
}
