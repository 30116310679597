<script setup lang="ts">
import { useDisplay } from 'vuetify'
import Button from '@/components/Button.vue'
import { useI18n } from 'vue-i18n'

const { lgAndUp } = useDisplay()
const { t } = useI18n()
const icons = [
  { icon: 'mdi-checkbox-marked-circle-outline', text: t('my_claims_view.fast_and_easy') },
  { icon: 'mdi-checkbox-marked-circle-outline', text: t('my_claims_view.all_airlines') },
  { icon: 'mdi-checkbox-marked-circle-outline', text: t('my_claims_view.no_risks') },
  { icon: 'mdi-checkbox-marked-circle-outline', text: t('my_claims_view.fast_handling') }
]
</script>

<template>
  <v-card v-if="lgAndUp" class="card-large card-shadow-elevation">
    <v-card-text class="fill-height pa-0">
      <v-row class="fill-height">
        <v-col class="content-left">
          <h3>{{ $t('claim_submission') }}</h3>
          <h3>{{ $t('my_claims_view.fast_and_easy_neutral') }}</h3>
          <p class="description">
            {{ $t('my_claims_view.description') }}
          </p>
          <v-row no-gutters class="chip-row">
            <v-col cols="4">
              <v-chip color="success" class="chip">
                <v-icon icon="mdi-checkbox-marked-circle-outline" start></v-icon>
                {{ $t('my_claims_view.fast_and_easy') }}
              </v-chip>
              <v-chip color="success" class="chip">
                <v-icon icon="mdi-checkbox-marked-circle-outline" start></v-icon>
                {{ $t('my_claims_view.all_airlines') }}
              </v-chip>
            </v-col>
            <v-col cols="4">
              <v-chip color="success" class="chip">
                <v-icon icon="mdi-checkbox-marked-circle-outline" start></v-icon>
                {{ $t('my_claims_view.no_risks') }}
              </v-chip>
              <v-chip color="success" class="chip negative-margin">
                <v-icon icon="mdi-checkbox-marked-circle-outline" start></v-icon>
                {{ $t('my_claims_view.fast_handling') }}
              </v-chip>
            </v-col>
          </v-row>
          <div class="button-container">
            <Button
              type="primary-red"
              :title="$t('buttons.submit_claim')"
              href="/distance-verification"
            />
          </div>
        </v-col>
        <v-col class="content-right">
          <div class="fill-height">
            <v-img src="/images/silhouette.png" class="silhouette" alt="silluette"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <div v-else class="mobile-banner">
    <h2>{{ $t('my_claims_view.title') }}</h2>
    <p class="description-sm">
      {{ $t('my_claims_view.description') }}
    </p>
    <v-row no-gutters class="chip-row">
      <v-col>
        <v-chip color="success" v-for="(item, idx) in icons" :key="idx" class="chip mt-3 mr-2">
          <v-icon :icon="item.icon" start></v-icon>
          {{ item.text }}
        </v-chip>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <Button
          type="primary-red"
          :title="$t('buttons.submit_claim')"
          href="/distance-verification"
        />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.mobile-banner {
  background-color: white !important;
  padding: 16px;
  border-radius: 10px;
}
.card-large {
  width: 1320px;
  height: 510px;
  border-radius: 10px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.12);
}

.content-left {
  padding-left: 114px;
  padding-top: 60px;
}

.content-right {
  padding: 0;
  margin-top: 3px;
  display: flex;
  justify-content: flex-end;
}

h3 {
  color: #000;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

h2 {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.description {
  padding-top: 40px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.description-sm {
  max-width: 310px;
  min-width: 252px;
  padding-top: 21px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.chip-row {
  padding-top: 22px;
}

.chip {
  margin-bottom: 16px;
}

.negative-margin {
  margin-left: -30px;
}

.button-container {
  padding-top: 40px;
  width: 220px;
}

.silhouette {
  height: 510px;
  width: 502px;
}

.btn-style {
  border-radius: 8px;
  background: #c12f5d;
  color: #fff;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 1px;
}

.v-btn {
  text-transform: none !important;
}
</style>
