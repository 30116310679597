<script setup lang="ts"></script>

<template>
  <svg width="26" height="17" viewBox="0 0 26 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="26" height="3" rx="1.5" fill="#3E3CAA" />
    <rect x="2" y="7" width="22" height="3" rx="1.5" fill="#3E3CAA" />
    <rect y="14" width="26" height="3" rx="1.5" fill="#3E3CAA" />
  </svg>
</template>

<style scoped></style>
