<script setup lang="ts">
import { useSiteStore } from '@/stores/site'

const siteStore = useSiteStore()
</script>

<template>
  <v-dialog v-model="siteStore.isLoading" max-width="300" persistent class="pb-15">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <v-progress-circular
            indeterminate
            color="#3E3CAA"
            bg-color="#FAFBFD"
            :size="60"
            :width="8"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>

<style scoped></style>
