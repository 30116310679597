<script setup lang="ts">
import type { IProcessedClaim } from '@/interfaces/interfaces'
import MyClaimCardFlightInformation from '@/components/claim/myClaim/MyClaimCardFlightInformation.vue'
import MyClaimCardPersonInformation from '@/components/claim/myClaim/MyClaimCardPersonInformation.vue'
import MyClaimCardDocumentInformation from '@/components/claim/myClaim/MyClaimCardDocumentInformation.vue'
import { useDisplay } from 'vuetify'
import MyClaimCardMobile from '@/components/claim/myClaim/mobile/MyClaimCardMobile.vue'
import ExpansionButton from '@/components/common/expansionButton/ExpansionButton.vue'
import { useI18n } from 'vue-i18n'
import { nextTick, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import ClaimStatus from '@/components/claimStatus/ClaimStatus.vue'
import MyClaimStepper from '@/components/claimStepper/MyClaimStepper.vue'

const props = defineProps<{
  claim: IProcessedClaim
}>()

const { lgAndUp, lgAndDown } = useDisplay()
const { locale } = useI18n()
const route = useRoute()

const isExpansionBtnExpanded = ref(false)
const expendableSection = ref(null)
const claimStepSection = ref(null)

const getStepperChoices = () => {
  return props.claim.mappedSteps
}

const handleExpansionBtnClick = () => {
  isExpansionBtnExpanded.value = true
}

const scrollToDocumentSection = async () => {
  await nextTick()
  if (route.query.cidentity) {
    const targetIndex = route.query.cidentity as string
    if (parseInt(targetIndex) === props.claim.claimId) {
      scrollToDocumentView()
    }
  } else if (isExpansionBtnExpanded.value) {
    scrollToDocumentView()
  }
}

const openDocumentSection = async () => {
  if (route.query.cidentity) {
    const targetIndex = route.query.cidentity as string
    if (parseInt(targetIndex) === props.claim.claimId) {
      isExpansionBtnExpanded.value = true
    }
  } else if (route.query.cdetail) {
    const targetIndex = route.query.cdetail as string
    if (parseInt(targetIndex) === props.claim.claimId) {
      scrollToClaimView()
    }
  }
}

const stepperChoices = ref(getStepperChoices())

watch(locale, () => {
  stepperChoices.value = getStepperChoices()
})

onMounted(async () => {
  await openDocumentSection()
})

const scrollToClaimView = () => {
  if (!claimStepSection.value) return
  claimStepSection.value.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

const scrollToDocumentView = () => {
  if (!expendableSection.value) return
  expendableSection.value.scrollIntoView({ behavior: 'smooth' })
}
</script>

<template>
  <v-card v-if="lgAndUp" width="1320" class="mb-6 card-shadow-elevation">
    <v-card-text class="aligned">
      <v-row>
        <v-col cols="6">
          <h3 class="card-title pb-5">{{ $t('my_claims_view.claim') }}: {{ claim.claimNumber }}</h3>
          <p class="user-credentials">
            {{ $t('my_claims_view.submission_date') }}:
            <span class="bold">{{ claim.submissionDate }}</span>
          </p>
          <p class="user-credentials">
            {{ $t('my_claims_view.claim_type') }}:
            <span class="bold"
              >{{ $t(`${claim.flightProblem}`) }} {{ claim.flightProblemInterval }}</span
            >
          </p>
          <p class="user-credentials">
            {{ $t('card_flight_airline') }}:
            <span class="bold">{{ claim.airlineName }}</span>
          </p>
        </v-col>
        <v-col>
          <div class="d-flex justify-end">
            <h3 class="state pb-3 pt-2">{{ $t('my_claims_state') }}</h3>
          </div>
          <div class="d-flex justify-end">
            <ClaimStatus
              v-if="claim.isWarningEnabled"
              :alert-message="claim.stepAlert?.message"
              :alert-type="claim.stepAlert?.alert_type"
              @expansion-btn-click="handleExpansionBtnClick"
            />
          </div>
        </v-col>
      </v-row>
      <div style="padding-top: 36px">
        <MyClaimCardFlightInformation :claim="claim" />
      </div>
      <div class="stepper-container" ref="claimStepSection">
        <MyClaimStepper :all-choices="stepperChoices" :current-step="claim.statusStep" />
      </div>
      <section ref="expendableSection">
        <ExpansionButton
          @update:expand-on-change="isExpansionBtnExpanded = $event"
          :expand-on-change="isExpansionBtnExpanded"
          :visible-text="$t('my_claims_view.show_less')"
          :hidden-text="$t('my_claims_view.show_more')"
        >
          <template #expand>
            <div>
              <MyClaimCardPersonInformation :claim="claim" />
              <v-divider
                v-if="claim.coPassengers?.length > 0"
                thickness="2px"
                class="divider-spacing top-margin"
              />
              <MyClaimCardDocumentInformation
                :claim="claim"
                @data-loaded="scrollToDocumentSection"
              />
            </div>
          </template>
        </ExpansionButton>
      </section>
    </v-card-text>
  </v-card>
  <MyClaimCardMobile v-else-if="lgAndDown" :claim="claim" />
</template>

<style scoped>
.card-shadow-elevation {
  border-radius: 10px;
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.12);
}
.user-credentials {
  color: #000;

  &.white {
    color: #fff;
    font-size: 12px;
  }

  span.bold {
    font-weight: 600;
  }

  &.padded {
    padding-top: 7px;
  }
}

.aligned {
  padding: 107px 110px 25px 112px;
}

.divider-spacing {
  padding-top: 13px;
  padding-bottom: 13px;
}

.top-margin {
  margin-top: 17px;
}

.stepper-container {
  margin-left: -20px;
  padding-top: 53px;
}
.state {
  color: #3e3caa;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
</style>
