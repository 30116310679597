import { createI18n } from 'vue-i18n'
import EN from '../locales/en.js'
import SK from '../locales/sk.js'
import CZ from '../locales/cz.js'
import DE from '../locales/de.js'
import PL from '../locales/pl.js'
import HU from '../locales/hu.js'

const i18n = createI18n({
  locale: 'en',
  legacy: false,
  globalInjection: true,
  messages: {
    en: EN,
    sk: SK,
    pl: PL,
    cz: CZ,
    de: DE,
    hu: HU
  }
})
export default i18n
