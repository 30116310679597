import { createHttpClient } from '@/service/client'
import type { Customer, CustomerRequest, IResetPasswordRequest } from '@/interfaces/interfaces'
import { ROUTE_NAMES } from '@/constants/constants'
import router from '@/router'
import { handleApiError, withLoading } from '@/utils/commsWrapper'

const prefix = 'customers/'
export function useCustomerService() {
  const client = createHttpClient(prefix)

  const login = withLoading(
    async (requestData: Partial<CustomerRequest>): Promise<Customer | undefined> => {
      try {
        const { data } = await client.post('login', requestData)
        return data
      } catch (error) {
        if (error.response.data.detail.error === 'not_verified_email') {
          await router.push({ name: ROUTE_NAMES.NOT_VERIFIED })
        } else {
          handleApiError(error)
        }
      }
    }
  )

  const googleLogin = withLoading(async (token: string): Promise<Customer | undefined> => {
    try {
      const { data } = await client.post('auth/google', { google_token: token })
      return data
    } catch (error) {
      handleApiError(error)
    }
  })

  const logout = withLoading(async (): Promise<boolean> => {
    try {
      const { status } = await client.post('logout')
      return status === 200
    } catch (error) {
      handleApiError(error)
    }
  })

  const signup = withLoading(async (userData: CustomerRequest): Promise<Customer | undefined> => {
    try {
      const { data } = await client.post('signup', userData)
      return data
    } catch (error: any) {
      handleApiError(error)
    }
  })

  const changePassword = withLoading(
    async (userData: { newPassword: string } & Partial<CustomerRequest>): Promise<boolean> => {
      try {
        const { status } = await client.post('change-password', {
          old_password: userData.password,
          new_password: userData.newPassword
        })
        return status === 200
      } catch (error) {
        handleApiError(error)
      }
    }
  )
  const resendVerificationEmail = withLoading(async (email: string): Promise<boolean> => {
    try {
      const { status } = await client.post('resend-verification-email', { email })
      return status === 200
    } catch (error) {
      handleApiError(error)
    }
  })

  const updateUserData = withLoading(async (data): Promise<boolean> => {
    try {
      const { status } = await client.patch('update-profile', data)
      return status === 200
    } catch (error) {
      handleApiError(error)
    }
  })

  const forgotPassword = withLoading(async (email: string): Promise<boolean> => {
    try {
      const { status } = await client.post('forgot-password', { email })
      return status === 200
    } catch (error) {
      handleApiError(error)
    }
  })

  const resetPassword = withLoading(async (data: IResetPasswordRequest): Promise<boolean> => {
    try {
      const { status } = await client.post('reset-password', data)
      return status === 200
    } catch (error) {
      handleApiError(error)
    }
  })

  return {
    login,
    googleLogin,
    logout,
    signup,
    changePassword,
    resendVerificationEmail,
    updateUserData,
    forgotPassword,
    resetPassword
  }
}
