<script setup lang="ts">
import { ref } from 'vue'
import { useDisplay } from 'vuetify'

const emits = defineEmits(['expansionBtnClick'])
const { lgAndUp, lgAndDown } = useDisplay()

const warningHidden = ref(false)

const hideWarning = () => {
  warningHidden.value = true
}

const showWarning = () => {
  warningHidden.value = false
}

const handleExpansionBtnClick = () => {
  emits('expansionBtnClick')
}
</script>

<template>
  <div v-if="lgAndUp" class="d-flex">
    <div class="alert-container">
      <v-slide-x-reverse-transition>
        <v-alert v-if="!warningHidden" color="#c12f5d" class="custom-alert" closable>
          <p class="user-credentials white text-end">
            {{ $t('my_claims_view.all_documents_requirement') }}
          </p>
          <p class="user-credentials white text-end">
            {{ $t('my_claims_view.upload_documents') }}
            <span
              @click="handleExpansionBtnClick"
              class="font-weight-bold text-white cursor-pointer"
              >{{ $t('my_claims_view.here') }}</span
            >
          </p>
          <template #close>
            <v-img
              v-if="false"
              src="/images/close.svg"
              alt="close"
              width="20"
              height="20"
              class="close-img cursor-pointer"
              @click="hideWarning"
            ></v-img>
          </template>
        </v-alert>
      </v-slide-x-reverse-transition>
    </div>
    <div v-if="false" class="warning-btn-container cursor-pointer" @click="showWarning">
      <v-btn :icon="true" color="#c12f5d" class="warning-btn">
        <v-img src="/images/warning.svg" alt="warning" width="20" height="20"></v-img>
      </v-btn>
    </div>
  </div>
  <v-alert v-else-if="lgAndDown" color="#C12F5D" class="custom-alert--mobile">
    <template #prepend>
      <v-img
        src="/images/warning.svg"
        alt="warning"
        width="21"
        height="21"
        class="alert-icon"
      ></v-img>
    </template>
    <div class="alert-content">
      <p class="text-white">{{ $t('my_claims_view.all_documents_requirement') }}</p>
      <v-btn flat color="#FFF" class="upload-button" @click="handleExpansionBtnClick">
        <span style="font-size: 13px; color: #c12f5d">{{
          $t('my_claims_view.upload_documents')
        }}</span>
      </v-btn>
    </div>
  </v-alert>
</template>

<style scoped>
.alert-container {
  width: 336px;
}

.custom-alert {
  border-radius: 10px;
  height: 66px;
}

.close-img {
  margin-top: -5px;
}

.warning-btn-container {
  padding-left: 11px;
}

.warning-btn {
  height: 30px;
  width: 30px;
  pointer-events: none;
}

.custom-alert--mobile {
  border-radius: 10px;
  height: 111px;
}

.alert-icon {
  margin-top: 32px;
}

.alert-content {
  margin-left: 10px;
}

.text-white {
  padding-bottom: 9px;
}

.upload-button {
  text-transform: unset !important;
  width: 95%;
}
</style>
