import { useSiteStore } from '@/stores/site'
import type { ExtendedAxiosError } from '@/interfaces/interfaces'
import i18n from '../../locales/i18n.config'
export function withLoading<T extends (...args: any[]) => Promise<any>>(fn: T): T {
  return async function (...args: Parameters<T>): Promise<ReturnType<T>> {
    const siteStore = useSiteStore()
    siteStore.setLoading(true)
    try {
      return await fn(...args)
    } finally {
      siteStore.setLoading(false)
    }
  } as T
}

export function handleApiError(error: ExtendedAxiosError | undefined = undefined) {
  const siteStore = useSiteStore()
  const errorKey = error?.response?.data?.detail?.error || 'default_error'
  siteStore.showSnackbar(i18n.global.t('error_messages.' + errorKey), 'error')
}
