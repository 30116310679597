import { createHttpClient } from '@/service/client'
import type { ClaimResponse, ClaimPost, FileResponse } from '@/interfaces/interfaces'
import { handleApiError, withLoading } from '@/utils/commsWrapper'
import { DocumentCategory, type DocumentGroup, DocumentState } from '@/enumerators/enumerators'

const filesPrefix = 'claim-files'
const prefix = 'my-claims'
export function useMyClaimService() {
  const client = createHttpClient(prefix)
  const filesClient = createHttpClient(filesPrefix)

  const getMyClaims = withLoading(async (): Promise<ClaimResponse[]> => {
    try {
      const { data } = await client.get('')
      return data
    } catch (error) {
      handleApiError(error)
      return []
    }
  })

  const getMyClaim = withLoading(async (id: number): Promise<ClaimResponse | undefined> => {
    try {
      const { data } = await client.get(`/${id}`)
      return data
    } catch (error) {
      handleApiError(error)
    }
  })

  const removeDocument = withLoading(async (documentId: number) => {
    try {
      const { status } = await filesClient.delete(`/${documentId}`)
      return status === 200
    } catch (error) {
      handleApiError(error)
    }
  })

  const uploadDocument = withLoading(
    async (
      claimId: string,
      requestData: Blob | null,
      customerId: number,
      category: DocumentCategory,
      groupFile: DocumentGroup,
      documentState: DocumentState
    ): Promise<FileResponse | null> => {
      try {
        const formData = new FormData()
        if (requestData) formData.append('file', requestData)
        formData.append('category', category)
        formData.append('group_claim_file', groupFile)
        formData.append('claim_document_state', documentState)
        formData.append('customer_id', customerId.toString())
        formData.append('claim_id', claimId)

        const { data } = await filesClient.postForm(`/upload-file`, formData)
        return data
      } catch (error) {
        handleApiError(error)
        return null
      }
    }
  )

  const postClaim = withLoading(async (requestData: ClaimPost): Promise<ClaimResponse> => {
    try {
      const { data } = await client.post('/new', requestData)
      return data
    } catch (error) {
      handleApiError(error)
    }
  })

  const getClaimFiles = withLoading(async (claimId: number): Promise<FileResponse[]> => {
    try {
      const { data } = await filesClient.get(`/${claimId}/files`)
      return data
    } catch (error) {
      handleApiError(error)
      return []
    }
  })

  return { getMyClaims, getMyClaim, uploadDocument, postClaim, getClaimFiles, removeDocument }
}
