<script setup lang="ts">
import { ref, watch } from 'vue'

const props = defineProps<{
  hiddenText: string
  visibleText: string
  expandOnChange: boolean
}>()

const isExpanded = ref(props.expandOnChange)
const expansionText = ref(props.hiddenText)
const emits = defineEmits(['update:expandOnChange'])

const setExpansion = (): void => {
  isExpanded.value = !isExpanded.value
  expansionText.value = isExpanded.value ? props.visibleText : props.hiddenText
  emits('update:expandOnChange', isExpanded.value)
}

watch(
  () => props.expandOnChange,
  (newVal) => {
    isExpanded.value = newVal
    expansionText.value = isExpanded.value ? props.visibleText : props.hiddenText
  }
)
</script>

<template>
  <v-expand-transition>
    <slot v-if="isExpanded" name="expand"></slot>
  </v-expand-transition>
  <div :class="{ 'padding-top-27': isExpanded }" class="pt-5">
    <v-btn
      prepend-icon="mdi-chevron-down"
      flat
      class="expansion-btn"
      color="transparent"
      @click="setExpansion"
    >
      <template #prepend>
        <v-img
          v-if="!isExpanded"
          src="/images/arrow-down.svg"
          alt="arrow-down"
          width="16"
          height="16"
        ></v-img>
        <v-img v-else src="/images/arrow-up.svg" alt="arrow-up" width="16" height="16"></v-img>
      </template>
      <span class="expansion-text">{{ expansionText }}</span></v-btn
    >
  </div>
</template>

<style scoped>
.expansion-btn {
  padding-left: 8px;
  height: 21px;
}

.expansion-text {
  font-size: 11px;
}

.padding-top-27 {
  padding-top: 27px;
}
</style>
