export default {
  error_messages: {
    default_error: 'Etwas ist schiefgelaufen',
    access_token_expired: 'Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.',
    claim_not_found: 'Anfrage nicht gefunden',
    not_verified_email: 'Ihre E-Mail ist nicht verifiziert. Bitte verifizieren Sie Ihre E-Mail.',
    unknown_email: 'Unbekannte E-Mail',
    email_occupied: 'Die E-Mail ist bereits vergeben',
    different_new_passwords: 'Die neuen Passwörter stimmen nicht überein',
    could_not_validate_credentials: 'Anmeldedaten konnten nicht verifiziert werden',
    incorrect_email_or_password: 'Falsche E-Mail oder Passwort',
    weak_password: 'Schwaches Passwort',
    invalid_reset_token: 'Ungültiges Zurücksetzungstoken',
    incorrect_old_password: 'Falsches altes Passwort',
    not_found: 'Eintrag mit dieser ID existiert nicht',
    not_authorized: 'Sie sind nicht berechtigt, diese Aktion auszuführen',
    entity_too_large: 'Die Datei ist zu groß',
    url_already_exists: 'Die URL existiert bereits',
    recaptcha_error: 'Fehlerhafte Recaptcha',
    login_again: 'Bitte melden Sie sich erneut an'
  },
  distance_verification_subtitle:
    'Die Informationen dienen zur Überprüfung, ob Sie Anspruch auf eine Entschädigung haben.',
  distance_verification_flight_from: 'Abflug von',
  distance_verification_flight_to: 'Ankunft in',
  no_results: 'Keine Ergebnisse gefunden',
  enter_city_airport: 'Geben Sie eine Stadt oder einen Flughafen ein',
  distance_verification_direct_flight: 'Hatten Sie einen Direktflug?',
  distance_verification_stepovers: 'Wo war Ihr Zwischenstopp?',
  distance_verification_stepovers_other: 'Wo war Ihr nächster Zwischenstopp?',
  next: 'Weiter',
  previous: 'Zurück',
  yes: 'Ja',
  no: 'Nein',
  passenger_details: 'Passagierdaten',
  flight_identification_airline: 'Name der Fluggesellschaft',
  flight_identification_number: 'Ihr Flug',
  flight_identification_number_placeholder: 'z. B. NQH5UX',
  flight_identification_date: 'Flugdatum',
  flight_identification_reservation_number: 'Buchungsnummer',
  flight_identification_reservation_number_placeholder: 'z. B. FR1234',
  flight_identification_alert:
    'Ihr Bestätigungscode befindet sich in der E-Mail, die Sie nach der Flugbuchung von der Fluggesellschaft erhalten haben. Dieser Code ist auch auf Ihrer Bordkarte sichtbar. Er sieht so aus: AB12CD (ein sechsstelliger Code aus Buchstaben und Ziffern). Für Charterflüge geben Sie bitte 000000 (sechs Nullen) ein.',
  reasons_application_title: 'Gründe für die Beantragung einer Entschädigung',
  reasons_application_subtitle:
    'Zusätzliche Informationen, die uns helfen können, die Entschädigung schneller zu bearbeiten.',
  reasons_application_problem: 'Welches Problem hatten Sie mit dem Flug?',
  reasons_application_problem_label: 'Wählen Sie ein Problem aus',
  reasons_application_interval: 'Wie viele Stunden hat sich Ihr Flug verspätet?',
  reasons_application_interval_label: 'Option auswählen',
  reasons_application_problem_type: 'Welches Problem hatten Sie mit dem Flug?',
  reasons_application_airport_problem: 'Welches war das Problem, das Sie bei Ihrem Flug hatten?',
  reasons_application_problem_description: 'Beschreiben Sie kurz Ihre Erfahrung',
  reasons_application_problem_description_label: 'Welche Unannehmlichkeiten haben Sie erlebt?',
  reasons_application_agreement_1: 'Ich stimme zu mit',
  reasons_application_agreement_2: 'die Verarbeitung meiner personenbezogenen Daten.',
  reasons_application_agreement_2_general: 'Allgemeine Geschäftsbedingungen',
  upload_documents: 'Dokumente',
  upload_documents_title: 'Dokumente zur Überprüfung der Forderung',
  upload_documents_load_docs: 'Dokument hochladen',
  upload_documents_load_docs_label: 'Wählen Sie eine Datei',
  upload_documents_btn_later: 'Ich werde es später ergänzen',
  files: 'Dateien',
  documents_1: 'Ticket oder Bordkarte / alle Passagiere',
  documents_1_help:
    'Wenn Sie mehr als einen Passagier in den Antrag eingetragen haben, benötigen wir die Tickets aller Passagiere für den betreffenden Flug.',
  documents_2: 'Bestätigung des Flughafens über Flugverspätung/-annullierung',
  documents_2_help:
    'Dies ist ein Dokument, das vom Flughafen auf Antrag ausgestellt wird. Es handelt sich nicht um eine fotografierte Informationstafel oder ähnliches. Dafür ist die Zeile „Andere Dokumente“ gedacht',
  documents_3: 'Kommunikation mit der Fluggesellschaft',
  documents_3_help:
    'Vollständiger E-Mail-Verkehr und Vertragsunterlagen mit der Fluggesellschaft in Bezug auf Ihren Flug und auch den Verkauf des Tickets, wenn Sie es direkt bei der Fluggesellschaft gekauft haben, einschließlich der Geschäftsbedingungen.',
  documents_4: 'Kommunikation mit dem Reisebüro',
  documents_4_help:
    'Vollständige E-Mail-Kommunikation und Vertragsunterlagen mit dem Ticket-/Reiseverkäufer (z. B. Reisebüro, Portal usw.), sofern Sie nicht direkt bei der Fluggesellschaft gekauft haben, einschließlich der Allgemeinen Geschäftsbedingungen.',
  documents_5: 'Kommunikation mit der Entschädigungseinrichtung',
  documents_5_help:
    'Umfassende E-Mail-Kommunikation, wenn Sie sich bereits mit einem anderen Unternehmen in Verbindung gesetzt haben, um eine Entschädigung für Ihren annullierten Flug zu erhalten.',
  documents_6: 'Andere Dokumente',
  documents_6_help: 'Andere Dokumente, die sich auf Ihren Fall beziehen',
  documents_other: 'Andere Dokumente',
  documents_other_help:
    'Laden Sie hier alle anderen Dokumente/Fotos hoch, die oben nicht erwähnt wurden und für Sie wichtig sind.',
  download: 'Herunterladen',
  card_flight_airline: 'Fluggesellschaft',
  card_flight_number: 'Flugnummer',
  card_flight_date: 'Datum des Fluges',
  card_personal_name: 'Name',
  card_personal_last_name: 'Nachname',
  card_personal_email: 'Email',
  card_personal_date: 'Datum der Geburt',
  card_personal_country: 'Landschaft',
  card_personal_street: 'Straße',
  card_personal_city: 'Stadt',
  card_personal_postal: 'POSTCODE',
  card_personal_contact: 'Rufnummer',
  login: 'Anmeldung',
  my_account: 'Kundenbereich',
  application_progress: 'Bewerbungsprozess',
  add: 'hinzufügen',
  remove: 'entfernen',
  password_change: 'Passwort ändern',
  password: 'Passwort',
  new_password: 'Neues Passwort',
  new_password_confirm: 'Bestätigen eines neuen Passworts',
  data_was_updated: 'Die Daten wurden erfolgreich gespeichert',
  password_confirm: 'Bestätigung des Passworts',
  forgot_password: 'Ich habe mein Passwort vergessen',
  forgot_password_description:
    'Um Ihr Passwort zurückzusetzen, geben Sie die E-Mail-Adresse ein, an die eine Bestätigungs-E-Mail gesendet wird.',
  forgot_password_email: 'Bestätigungs-E-Mail senden',
  forgot_password_email2: 'Eine E-Mail wurde gesendet an:',
  confirm_email_alert:
    'Sie haben Ihre E-Mail erfolgreich bestätigt. Bitte fahren Sie fort, indem Sie sich anmelden.',
  login2: 'Einloggen',
  or_use: 'oder verwenden',
  forgot_password_question: 'Haben Sie Ihr Passwort vergessen?',
  restore: 'Aktualisieren',
  no_account_question: 'Sie haben noch kein Konto?',
  register: 'Jetzt anmelden',
  confirm_email_fail: 'Der Bestätigungslink ist abgelaufen. Bitte fordern Sie einen neuen an.',
  just_a_step: 'Nur noch ein Schritt!',
  not_verified_description:
    'Um den Anmeldevorgang abzuschließen, müssen Sie die E-Mail bestätigen. Wenn Sie keine E-Mail erhalten haben, können Sie eine erneute Zusendung anfordern.',
  already_have_account: 'Haben Sie schon ein Konto?',
  create_password: 'Erstellen eines Passworts',
  restore_password: 'Passwort zurücksetzen',
  set_password: 'Passwort festlegen',
  reset_password_change: 'Passwort ändern',
  reset_password_success_alert_1: 'Sie haben erfolgreich ein Passwort festgelegt.',
  reset_password_success_alert_2: 'Sie haben Ihr Passwort erfolgreich geändert.',
  adult_rule:
    'Bei Personen unter 18 Jahren muss der Antrag von ihrem gesetzlichen Vertreter gestellt werden.',
  same_email_rule: 'Die E-Mail wurde bereits verwendet.',
  are_not_same_rule: 'Emails stimmen nicht überein',
  email_confirm: 'E-Mail-Bestätigung',
  repeat_email: 'E-Mail wiederholen',
  contact_info: 'Kontaktangaben',
  other_passengers: 'Andere Passagiere',
  application_state: 'STATUS DER ANFRAGE:',
  my_claims_state: 'Status der Bewerbung:',
  edit: 'Bearbeiten durch',
  save: 'Speichern Sie',
  shorter_than_3_hours: 'Weniger als 3 Stunden',
  longer_than_3_hours: 'Länger als 3 Stunden',
  missed_connecting_flight: 'Verpassen eines Anschlussfluges',
  duration_less_than_2_hours: '0 - 2 Stunden',
  duration_more_than_2_hours: 'Mehr als 2 Stunden',
  duration_never_flied: 'Er hat nie abgehoben',
  technical_problem: 'Technisches Problem',
  bad_weather: 'Schlechtes Wetter',
  strike: 'Streik',
  airport_problem: 'Problem auf dem Flughafen',
  other_flight: 'Beeinflussung durch andere Flüge',
  higher_power: 'Höhere Macht',
  other: 'Andere',
  cant_remember: 'Ich erinnere mich nicht.',
  logout: 'Abmelden',
  upload: 'Hochladen auf',
  faq: 'Faq',
  blog: 'Blog',
  new_traveler: '+ Einen weiteren Passagier hinzufügen',
  flight_delay: 'Flugverspätung',
  flight_cancel: 'Annullierter Flug',
  flight_connecting_delay: 'Verspätung eines Anschlussfluges',
  claim_submission: 'Einreichung eines Antrags',
  my_claims: 'Meine Wünsche',
  claim_stepper: {
    mobile: {
      title: '$t(claim_submission)',
      step1: 'Flugverifizierung',
      step2: 'Passagierdaten',
      step3: 'Oberfläche'
    }
  },
  validations: {
    required: 'Erforderliches Feld',
    max_char: 'Die maximale Anzahl von Zeichen beträgt',
    different_password: 'Passwörter stimmen nicht überein',
    password_format:
      'Das Passwort muss mindestens 8 Zeichen enthalten, einen Großbuchstaben, einen Kleinbuchstaben und eine Zahl',
    postal_code: 'Geben Sie eine gültige Postleitzahl ein',
    email: 'Geben Sie eine gültige E-Mail ein',
    age: 'Das Mindestalter beträgt',
    dateFormat: 'Das Datum muss im Format DD. MM. JJJJ',
    dateValidity: 'Ungültiges Datum',
    dateFuture: 'Das Datum darf nicht in der Zukunft liegen.'
  },
  alert_messages: {
    halted_missing_docs: ['Ausgesetzter Fortschritt', 'Fehlende Dokumente'],
    halted_missing_data: ['Ausgesetzter Fortschritt ', 'Fehlende Daten'],
    claim_validation: ['Antrag in Bearbeitung ', 'Anerkennung der Forderung'],
    missing_claim_attachments: ['Bewerbung in Bearbeitung', 'Fehlende Anhänge'],
    missing_legal_documents: ['Antrag in Bearbeitung', 'Fehlende rechtliche Dokumente'],
    legal_documents: ['Antrag in Bearbeitung', 'Wir prüfen die rechtlichen Dokumente'],
    communications_airline: ['Antrag in Bearbeitung ', 'Kommunikation mit der Fluggesellschaft'],
    communications_court: ['Antrag in Bearbeitung ', 'Kommunikation mit dem Gericht'],
    successfully_completed: ['Anfrage abgeschlossen ', 'Erfolgreich abgeschlossen'],
    closed_undelivered_documents: ['Antrag geschlossen ', 'Dokumente nicht zugestellt'],
    closed_duplicate_request: ['Anfrage geschlossen ', 'Duplizierte Anfrage'],
    extraordinary_situation: ['Antrag geschlossen ', 'Emergency'],
    delay_less_then_3_hours: ['Antrag geschlossen ', 'Verspätung weniger als 3 Stunden'],
    flight_outside_eu: ['Antrag abgeschlossen ', 'Flug außerhalb der EU'],
    claim_successfully_completed: ['Anfrage abgeschlossen ', 'Erfolgreich abgeschlossen'],
    closed_duplicated_request: ['Anfrage geschlossen ', 'Duplizierte Anfrage'],
    closed_undelivered_docs: ['Antrag abgeschlossen ', 'Dokumente nicht erhalten'],
    court_start_process: ['Klage ', 'Antrag auf Einleitung des Verfahrens'],
    court_received_docs: ['Gerichtsverfahren', 'Eingehende Dokumente'],
    court_lawyer_delegation: ['Klage ', 'Übertragung des Falles an eine Anwaltskanzlei']
  },
  claim_status: {
    created: 'Erstellt von',
    validation: 'Überprüfung der Berechtigung',
    legal_documents: 'Vertragsparteien',
    arline_communications: 'Kommunikation mit der Fluggesellschaft',
    court_communication: 'Kommunikation mit dem Gericht',
    rejected: 'Abgelehnter Antrag - Außerordentliches Ereignis',
    completed: 'Erfolgreich abgeschlossen',
    finished: 'Schließen der Anwendung',
    stopped_documentation_needed: 'Ausgesetzter Fortschritt - Fehlende Dokumente',
    stopped_data_needed: 'Ausgesetzter Fortschritt - Fehlende Daten',
    court_communications: 'Kommunikation mit dem Gericht'
  },
  confirm: 'Bestätigen Sie',
  claim_success_message:
    'Der Antrag wurde erfolgreich abgeschickt. Bitte überprüfen Sie den Status Ihrer Bewerbung auf der Registerkarte Meine Bewerbungen',
  contact_message_success:
    'Sie haben das Kontaktformular erfolgreich abgeschickt. Wir danken Ihnen.',
  footer: {
    submit_claim_slogan:
      'Bei uns zahlen Sie nur, wenn Sie erfolgreich eine Entschädigung erhalten.',
    our_services: 'Unsere Dienstleistungen',
    delay_longer_than_3_hours: 'Flugverspätung von mehr als 3 Stunden',
    canceled_flight: 'Annullierung eines Fluges ohne Vorankündigung',
    delayed_connecting_flight: 'Verspätung eines hoffnungsvollen Fluges',
    delay_longer_than_3_hours_url:
      '/du-kannst-einen-frühen-abbruch-für-die-überspringung-des-fliegers-zum-himmel-600-e',
    canceled_flight_url:
      '/unerwartete-flugannullierung-sie-können-einen-anspruch-auf-rückerstattung-haben-bis-sie-600-e',
    delayed_connecting_flight_url:
      '/Sie-können-für-einen-verpassten-weiteren-Flug-von-der-U-Bahn-zum-Himmel-zahlen-600-e',
    customer_service_message:
      'Bleiben Sie mit uns in Kontakt. Folgen Sie uns auf den sozialen Medien, um Neuigkeiten, einzigartige Artikel und weitere Informationen über Ihre Ansprüche zu erhalten!',
    customer_service_title: 'Mit Träumen verbinden',
    company_slogan:
      'Wir helfen unseren Kunden mit einem Expertenteam, Entschädigungen für Flugausfälle und Verspätungen zu erhalten. Füllen Sie einen Online-Antrag aus und zahlen Sie nur im Erfolgsfall.',
    company_email: "info{'@'}meskanieletu.sk",
    company_name: 'Studio JŠ, s.r.o. - Meskanieletu.sk',
    company_address: 'Ul. 1. Mája, Krásno nad Kysucou 02302, Slovensko',
    lawyer_documents: {
      title: 'Juristische Dokumente',
      general_terms: 'Bedingungen und Konditionen',
      privacy_policy: 'Datenschutzbestimmungen',
      cookies_files: 'Verordnung (EG) Nr. 261/2004'
    },
    menu: {
      title: 'Menü',
      about_us: 'Über uns',
      price_list: 'Die Preisliste',
      faq: 'FAQ',
      blog: 'Blog',
      urls: {
        about_us: '/uber-uns',
        price_list: '/preisliste',
        faq: '/gepostete-fragen-fragen',
        terms_and_conditions: '/allgemeine-trade-bedingungen',
        privacy_policy: '/schutz-und-verarbeitung-personenbezogener-Daten',
        blog: '/blog'
      }
    }
  },
  buttons: {
    submit_claim: 'Bewerbung einreichen',
    logout: 'Abmelden',
    login: 'Einloggen',
    check_claim: 'Prüfen Sie den Anspruch',
    skip: 'Sprung zu'
  },
  navbar: {
    my_claims: 'Meine Anfragen',
    contact: 'Kontaktangaben',
    bank: 'Bankdaten',
    settings: 'Einstellung',
    show_profile: 'Profil anzeigen',
    change_password: 'Passwort ändern',
    logout: 'Abmelden'
  },
  personal_data: 'Persönliche Daten',
  personal_data_view: {
    title: 'Ihre Daten',
    description:
      'Bitte überprüfen Sie Ihre persönlichen Kontaktdaten. Diese Angaben erleichtern und beschleunigen die Bearbeitung Ihrer Bewerbung. Ihre Angaben ermöglichen es uns, Verträge automatisch vorzubefüllen oder Sie gegebenenfalls zu kontaktieren. Vielen Dank für Ihre Mitarbeit!',
    bank_account_title: 'Bankkonto',
    bank_account_description:
      'Die Bankverbindung wird für die Erstellung der Vertragsunterlagen sowie für die Überweisung des Geldbetrags im Rahmen des Vertrags im Falle einer erfolgreichen Entschädigung verwendet.',
    iban_title: 'Kontonummer im IBAN-Format'
  },
  address: 'Anschrift des Wohnsitzes',
  address_line_2: 'Anschrift Zeile 2',
  city: 'Stadt',
  postal_code: 'POSTCODE',
  country: 'Landschaft',
  phone_number: 'Tel. Nummer',
  bank_account_owner: 'Name des Kontoinhabers',
  iban: 'IBAN',
  swift: 'SWIFT',
  airplaneBackgroundText:
    'Weitere Einzelheiten zu Ihrer Bewerbung finden Sie in Ihrem Konto, nachdem Sie sich beworben haben.',
  cancel: 'Abbrechen',
  application_done_title: 'Wir haben alles, was wir brauchen!',
  application_done_subtitle:
    'Wir werden Ihren Entschädigungsanspruch prüfen und Ihnen per E-Mail Informationen über das weitere Vorgehen zusenden. Sie können den aktuellen Stand Ihrer Forderung in Ihrem Konto verfolgen. Weitere Informationen finden Sie bereits in Ihrer E-Mail.',
  add_document: 'Dokument hinzufügen',
  reprimand_contract: 'Abtretungsvertrag',
  delegation_document: 'Bevollmächtigung',
  my_claims_view: {
    personal_information: 'Persönliche Daten des Antragstellers',
    departure_place: 'Ort der Abreise',
    arrival_place: 'Ort der Ankunft',
    claim: 'Anmeldung',
    claim_number: 'ANTRAGSNUMMER',
    requestor: 'Name des Antragstellers',
    submission_date: 'Datum der Einreichung',
    all_documents_requirement: 'Alle Dokumente müssen übermittelt werden.',
    upload_documents: 'Dokumente hochladen',
    here: 'Hier',
    claim_type: 'Art der Anwendung',
    title: 'Die Bewerbung ist schnell und einfach!',
    fast_and_easy: 'Schnell und einfach',
    fast_and_easy_neutral: 'schnell und einfach!',
    all_airlines: 'Alle Fluggesellschaften',
    no_risks: 'Kein Risiko von Gebühren',
    fast_handling: 'Es dauert nur 3 Minuten.',
    show_more: 'Bewerbungsdetails anzeigen',
    show_less: 'Weniger anzeigen',
    residence: 'Wohnsitz',
    email_address: 'E-Mail Adresse',
    claim_status: 'Aktueller Stand der Bewerbung',
    other_passengers: 'Andere Passagiere',
    description:
      'Unser Team von Reiserechtsexperten prüft Ihren Antrag und hält Sie über jeden Meilenstein während des Verfahrens auf dem Laufenden.',
    day_of_flight: 'Tag des Fluges'
  },
  IdOrPass: 'Personalausweis (Vorderseite) oder Reisepass',
  claimDocuments: 'Dokumente anfordern',
  representationDocuments: 'Dokumente zur Repräsentation',
  deleteConfirmationText:
    'Sie werden nicht mehr auf das Dokument zugreifen können. Das Dokument wird endgültig gelöscht.',
  deleteConfirmationTitle: 'Möchten Sie dieses Dokument wirklich löschen?',
  coPassengerNumber: 'Passagier Nr.',
  unknownFile: 'Unbekannte Datei',
  approvedByAdmin: 'Genehmigt durch den Verwalter',
  missingDocuments: 'Fehlende Dokumente',
  sentForValidation: 'Zur Überprüfung gesendet',
  IdontHave: 'Ich habe keine',
  other_airline_communication:
    'Haben Sie sich mit der Fluggesellschaft oder einem anderen Unternehmen in Verbindung gesetzt, um eine Entschädigung zu erhalten?',
  yes_communicated: 'Ja, wir haben kommuniziert',
  no_communication: 'Nein, wir haben nicht kommuniziert',
  communication: 'Kurzbeschreibung der Kommunikation',
  explain_communication:
    'Beschreiben Sie hier kurz Ihre Kommunikation mit der Fluggesellschaft oder dem Entschädigungsunternehmen',
  send_without_documentation: 'Ohne Dokumente versenden',
  send: 'Senden an',
  continue_to_detail: 'Zu den Bewerbungsdetails',
  rest_upload_later: 'Ich werde den Rest später hinzufügen',
  delete: 'Löschen',
  reprimand_contract_description:
    'Ein Auftragsvertrag ist ein Vertrag, den Sie mit unserem Unternehmen schließen.',
  delegation_document_description:
    'Eine Vollmacht ist ein Dokument, das uns erlaubt, Sie bei der Bewerbung zu vertreten.',
  idOrPass_description:
    'Zur Überprüfung der Identität ist ein Personalausweis oder Reisepass erforderlich.',
  step_number: 'Schritt Nr.'
}
