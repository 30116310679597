export default {
  error_messages: {
    default_error: 'Coś poszło nie tak',
    access_token_expired: 'Twoja sesja wygasła. Proszę, zaloguj się ponownie.',
    claim_not_found: 'Żądanie nie znalezione',
    not_verified_email: 'Twój email nie został zweryfikowany. Proszę, zweryfikuj swój email.',
    unknown_email: 'Nieznany email',
    email_occupied: 'Email jest już zajęty',
    different_new_passwords: 'Nowe hasła są różne',
    could_not_validate_credentials: 'Nie udało się zweryfikować danych logowania',
    incorrect_email_or_password: 'Niepoprawny email lub hasło',
    weak_password: 'Słabe hasło',
    invalid_reset_token: 'Nieprawidłowy token resetu',
    incorrect_old_password: 'Nieprawidłowe stare hasło',
    not_found: 'Rekord z danym identyfikatorem nie istnieje',
    not_authorized: 'Nie masz uprawnień do wykonania tej akcji',
    entity_too_large: 'Załącznik jest zbyt duży',
    url_already_exists: 'URL już istnieje',
    recaptcha_error: 'Błędna Recaptcha',
    login_again: 'Proszę, zaloguj się ponownie'
  },
  distance_verification_subtitle:
    'Informacje służą do sprawdzenia, czy przysługuje Ci odszkodowanie.',
  distance_verification_flight_from: 'Wylot z',
  distance_verification_flight_to: 'Przylot do',
  no_results: 'Nie znaleziono żadnych wyników',
  enter_city_airport: 'Wprowadź miasto lub lotnisko',
  distance_verification_direct_flight: 'Czy miałeś bezpośredni lot?',
  distance_verification_stepovers: 'Gdzie miałeś przesiadkę?',
  distance_verification_stepovers_other: 'Gdzie miałeś kolejną przesiadkę?',
  next: 'Dalej',
  previous: 'Wstecz',
  yes: 'Tak',
  no: 'Nie',
  passenger_details: 'Dane pasażerów',
  flight_identification_airline: 'Nazwa linii lotniczej',
  flight_identification_number: 'Twój lot',
  flight_identification_number_placeholder: 'np. NQH5UX',
  flight_identification_date: 'Data lotu',
  flight_identification_reservation_number: 'Numer rezerwacji',
  flight_identification_reservation_number_placeholder: 'np. FR1234',
  flight_identification_alert:
    'Twój kod potwierdzający znajduje się w e-mailu, który otrzymałeś od linii lotniczej po rezerwacji lotu. Ten kod jest również widoczny na Twojej karcie pokładowej. Wygląda tak: AB12CD (sześciocyfrowe słowo składające się z liter i cyfr). W przypadku lotu czarterowego wpisz 000000 (sześć zer).',
  reasons_application_title: 'Powody ubiegania się o odszkodowanie',
  reasons_application_subtitle:
    'Dodatkowe informacje, które mogą pomóc nam w szybszym rozpatrzeniu odszkodowania.',
  reasons_application_problem: 'Jaki był problem z lotem, z którym się zetknąłeś?',
  reasons_application_problem_label: 'Wybierz problem',
  reasons_application_interval: 'Ile godzin trwało opóźnienie Twojego lotu?',
  reasons_application_interval_label: 'Wybierz opcję',
  reasons_application_problem_type:
    'Czy firma poinformowała Cię o przyczynie opóźnienia/anulowania lotu?',
  reasons_application_airport_problem: 'Jaki był problem z lotem, z którym się zetknąłeś?',
  reasons_application_problem_description: 'Opisz w skrócie swoje doświadczenie',
  reasons_application_problem_description_label: 'Z jakimi niedogodnościami się zetknąłeś?',
  reasons_application_agreement_1: 'Zgadzam się na',
  reasons_application_agreement_2: 'przetwarzanie moich danych osobowych.',
  reasons_application_agreement_2_general: 'ogólnymi warunkami handlowymi',
  upload_documents: 'Dokumenty',
  upload_documents_title: 'Dokumenty do weryfikacji roszczenia',
  upload_documents_load_docs: 'Prześlij dokument',
  upload_documents_load_docs_label: 'Wybierz plik',
  upload_documents_btn_later: 'Dodam później',
  files: 'pliki',
  documents_1: 'Bilet lotniczy lub karta pokładowa (boarding pass) / wszyscy pasażerowie',
  documents_1_help:
    'Jeśli w zgłoszeniu podałeś kilku pasażerów, potrzebujemy bilety lotnicze od wszystkich pasażerów na dany lot.',
  documents_2: 'Potwierdzenie z lotniska o opóźnieniu/anulowaniu lotu',
  documents_2_help:
    'Jest to dokument wystawiony przez lotnisko na żądanie. Nie jest to sfotografowana tablica informacyjna itp. Do tego służy wiersz „Inne dokumenty”.',
  documents_3: 'Komunikacja z linią lotniczą',
  documents_3_help:
    'Kompletna komunikacja e-mailowa i dokumentacja umowy z linią lotniczą dotycząca Twojego lotu, a także zakupu biletu, jeśli kupiłeś go bezpośrednio od linii lotniczej, w tym warunki handlowe.',
  documents_4: 'Komunikacja z biurem podróży',
  documents_4_help:
    'Kompletna komunikacja e-mailowa i dokumentacja umowy z sprzedawcą biletu / wycieczki (np. biuro podróży, portal itp.), jeśli nie kupiłeś biletu bezpośrednio od linii lotniczej, w tym warunki handlowe.',
  documents_5: 'Komunikacja z firmą zajmującą się odszkodowaniami',
  documents_5_help:
    'Kompletna komunikacja e-mailowa, jeśli już sprawę rozpatrywałeś z inną firmą zajmującą się uzyskaniem odszkodowania za Twój odwołany lot.',
  documents_6: 'Inne dokumenty',
  documents_6_help: 'Inne dokumenty dotyczące Twojej sprawy',
  documents_other: 'Dalsze dokumenty',
  documents_other_help:
    'Tu prześlij dowolny inny dokument/zdjęcie, który nie został wymieniony powyżej, a według Ciebie jest ważny.',
  download: 'Pobierz',
  card_flight_airline: 'Linia lotnicza',
  card_flight_number: 'Numer lotu',
  card_flight_date: 'Data lotu',
  card_personal_name: 'Imię',
  card_personal_last_name: 'Nazwisko',
  card_personal_email: 'Email',
  card_personal_date: 'Data urodzenia',
  card_personal_country: 'Kraj',
  card_personal_street: 'Ulica',
  card_personal_city: 'Miasto',
  card_personal_postal: 'Kod pocztowy',
  card_personal_contact: 'Numer telefonu',
  login: 'Logowanie',
  my_account: 'Strefa klienta',
  application_progress: 'Proces składania wniosku',
  add: 'Dodaj',
  remove: 'Usuń',
  password_change: 'Zmiana hasła',
  password: 'Hasło',
  new_password: 'Nowe hasło',
  new_password_confirm: 'Potwierdzenie nowego hasła',
  data_was_updated: 'Dane zostały pomyślnie zapisane',
  password_confirm: 'Potwierdzenie hasła',
  forgot_password: 'Zapomniałem hasła',
  forgot_password_description:
    'Aby zresetować hasło, podaj adres e-mail, na który zostanie wysłany e-mail weryfikacyjny.',
  forgot_password_email: 'Wyślij e-mail weryfikacyjny',
  forgot_password_email2: 'E-mail został wysłany na adres:',
  confirm_email_alert: 'Pomyślnie potwierdziłeś email. Proszę, kontynuuj logowaniem.',
  login2: 'Zaloguj się',
  or_use: 'lub użyj',
  forgot_password_question: 'Zapomniałeś hasła?',
  restore: 'Odzyskaj',
  no_account_question: 'Jeszcze nie masz konta?',
  register: 'Zarejestruj się',
  confirm_email_fail: 'Link do potwierdzenia wygasł. Poproś o nowy.',
  just_a_step: 'Już tylko jeden krok!',
  not_verified_description:
    'Aby zakończyć proces rejestracji, konieczne jest potwierdzenie emaila. Jeśli nie otrzymałeś emaila, możesz poprosić o ponowne wysłanie.',
  already_have_account: 'Masz już konto?',
  create_password: 'Utworzenie hasła',
  restore_password: 'Odzyskanie hasła',
  set_password: 'Ustaw hasło',
  reset_password_change: 'Zmień hasło',
  reset_password_success_alert_1: 'Pomyślnie ustawiłeś hasło.',
  reset_password_success_alert_2: 'Pomyślnie zmieniłeś hasło.',
  adult_rule: 'W imieniu osób poniżej 18. roku życia wniosek składa ich opiekun prawny.',
  same_email_rule: 'Email został już użyty.',
  are_not_same_rule: 'Emaile nie pasują do siebie',
  email_confirm: 'Potwierdzenie emaila',
  repeat_email: 'Powtórz email',
  contact_info: 'Dane kontaktowe',
  other_passengers: 'Kolejny pasażer',
  application_state: 'STATUS wniosku:',
  my_claims_state: 'Status wniosku:',
  edit: 'Edytuj',
  save: 'Zapisz',
  shorter_than_3_hours: 'Krótsze niż 3 godziny',
  longer_than_3_hours: 'Dłuższe niż 3 godziny',
  missed_connecting_flight: 'Opóźnienie lotu łączącego',
  duration_less_than_2_hours: '0 - 2 godziny',
  duration_more_than_2_hours: 'Więcej niż 2 godziny',
  duration_never_flied: 'Nigdy nie odleciał',
  technical_problem: 'Problem techniczny',
  bad_weather: 'Zła pogoda',
  strike: 'Strajk',
  airport_problem: 'Problem na lotnisku',
  other_flight: 'Wpływ innych lotów',
  higher_power: 'Siła wyższa',
  other: 'Inne',
  cant_remember: 'Nie pamiętam',
  logout: 'Wyloguj się',
  upload: 'Prześlij',
  faq: 'Faq',
  blog: 'Blog',
  new_traveler: '+ Dodaj kolejnego pasażera',
  flight_delay: 'Opóźnienie lotu',
  flight_cancel: 'Anulowany lot',
  flight_connecting_delay: 'Opóźnienie lotu łączącego',
  claim_submission: 'Złożenie wniosku',
  my_claims: 'Moje wnioski',
  claim_stepper: {
    mobile: {
      title: '$t(claim_submission)',
      step1: 'Weryfikacja lotu',
      step2: 'Dane pasażera',
      step3: 'Zakończenie'
    }
  },
  validations: {
    required: 'Pole obowiązkowe',
    max_char: 'Maksymalna liczba znaków to',
    different_password: 'Hasła nie pasują do siebie',
    password_format: 'Hasło musi zawierać co najmniej 8 znaków, wielką literę, małą literę i cyfrę',
    postal_code: 'Podaj prawidłowy kod pocztowy',
    email: 'Podaj prawidłowy email',
    age: 'Minimalny wiek to',
    dateFormat: 'Data musi być w formacie DD. MM. RRRR',
    dateValidity: 'Nieprawidłowa data',
    dateFuture: 'Data nie może być w przyszłości.'
  },
  alert_messages: {
    halted_missing_docs: ['Zawieszone postępy', 'Brakujące dokumenty'],
    halted_missing_data: ['Zawieszone postępy', 'Brakujące dane'],
    claim_validation: ['Wniosek w trakcie', 'Weryfikacja roszczenia'],
    missing_claim_attachments: ['Wniosek w trakcie', 'Brakujące załączniki'],
    missing_legal_documents: ['Wniosek w trakcie', 'Brakujące dokumenty prawne'],
    legal_documents: ['Wniosek w trakcie', 'Weryfikujemy dokumenty prawne'],
    communications_airline: ['Wniosek w trakcie', 'Komunikacja z linią lotniczą'],
    communications_court: ['Wniosek w trakcie', 'Komunikacja z sądem'],
    successfully_completed: ['Wniosek zakończony', 'Zakończony sukcesem'],
    closed_undelivered_documents: ['Wniosek zakończony', 'Nieprzekazane dokumenty'],
    closed_duplicate_request: ['Wniosek zakończony', 'Zduplikowany wniosek'],
    extraordinary_situation: ['Wniosek zakończony', 'Nadzwyczajna okoliczność'],
    delay_less_then_3_hours: ['Wniosek zakończony', 'Opóźnienie krótsze niż 3 godziny'],
    flight_outside_eu: ['Wniosek zakończony', 'Lot poza UE'],
    claim_successfully_completed: ['Wniosek zakończony', 'Zakończony sukcesem'],
    closed_duplicated_request: ['Wniosek zakończony', 'Zduplikowany wniosek'],
    closed_undelivered_docs: ['Wniosek zakończony', 'Nieprzekazane dokumenty'],
    court_start_process: ['Postępowanie sądowe', 'Wniosek o wszczęcie'],
    court_received_docs: ['Postępowanie sądowe', 'Odebrane dokumenty'],
    court_lawyer_delegation: ['Postępowanie sądowe', 'Przekazanie sprawy do kancelarii adwokackiej']
  },
  claim_status: {
    created: 'Utworzony',
    validation: 'Weryfikacja roszczenia',
    legal_documents: 'Zawarcie umów',
    arline_communications: 'Komunikacja z linią lotniczą',
    court_communication: 'Komunikacja z sądem',
    rejected: 'Odrzucony wniosek - Nadzwyczajna okoliczność',
    completed: 'Zakończony sukcesem',
    finished: 'Zamknięcie wniosku',
    stopped_documentation_needed: 'Zawieszone postępy - Brakujące dokumenty',
    stopped_data_needed: 'Zawieszone postępy - Brakujące dane',
    court_communications: 'Komunikacja z sądem'
  },
  confirm: 'Potwierdź',
  claim_success_message:
    'Wniosek został pomyślnie wysłany. Proszę, śledź status swojego wniosku w zakładce Moje wnioski',
  contact_message_success: 'Pomyślnie wysłałeś formularz kontaktowy. Dziękujemy.',
  footer: {
    submit_claim_slogan: 'U nas płacisz tylko w przypadku pomyślnego uzyskania odszkodowania.',
    our_services: 'Nasze usługi',
    delay_longer_than_3_hours: 'Opóźnienie lotu dłuższe niż 3 godziny',
    canceled_flight: 'Odwołanie lotu bez wcześniejszej informacji',
    delayed_connecting_flight: 'Opóźnienie lotu łączącego',
    delay_longer_than_3_hours_url: '/za-opoznienie-lotu-mozesz-uzyskac-odszkodowanie-do-600-euro',
    canceled_flight_url:
      '/niespodziewane-odwolanie-lotu-mozesz-miec-prawo-do-odszkodowania-do-600-euro',
    delayed_connecting_flight_url:
      '/za-opozniony-lot-laczacy-mozesz-uzyskac-odszkodowanie-do-600-euro',
    customer_service_message:
      'Bądź z nami w kontakcie. Śledź nas w mediach społecznościowych, aby znaleźć nowości, unikalne artykuły i więcej informacji o Twoich prawach!',
    customer_service_title: 'Skontaktuj się z nami',
    company_slogan:
      'Pomagamy klientom uzyskać odszkodowanie w transporcie lotniczym za odwołanie i opóźnienie lotów z pomocą zespołu ekspertów. Wypełnij wniosek online i zapłacisz tylko w przypadku sukcesu.',
    company_email: "info{'@'}meskanieletu.sk",
    company_name: 'Studio JŠ, s.r.o. - Meskanieletu.sk',
    company_address: 'Ul. 1. Mája, Krásno nad Kysucou 02302, Słowacja',
    lawyer_documents: {
      title: 'Dokumenty prawne',
      general_terms: 'Warunki handlowe',
      privacy_policy: 'Ochrona danych osobowych',
      cookies_files: 'Rozporządzenie EPaR (WE) nr 261/2004'
    },
    menu: {
      title: 'Menu',
      about_us: 'Informacje o nas',
      price_list: 'Wykaz cen',
      faq: 'FAQ',
      blog: 'Blog',
      urls: {
        about_us: '/my',
        price_list: '/wykaz-cen',
        faq: '/najczesciej-zadawane-pytania',
        terms_and_conditions: '/ogolne-warunki-handlowe',
        privacy_policy: '/ochrona-i-przetwarzanie-danych-osobowych',
        blog: '/blog'
      }
    }
  },
  buttons: {
    submit_claim: 'Złóż wniosek',
    logout: 'Wyloguj się',
    login: 'Zaloguj się',
    check_claim: 'Zweryfikuj roszczenie',
    skip: 'Pomiń'
  },
  navbar: {
    my_claims: 'Moje wnioski',
    contact: 'Dane kontaktowe',
    bank: 'Dane bankowe',
    settings: 'Ustawienia',
    show_profile: 'Wyświetl profil',
    change_password: 'Zmień hasło',
    logout: 'Wyloguj się'
  },
  personal_data: 'Dane osobowe',
  personal_data_view: {
    title: 'Twoje dane',
    description:
      'Prosimy o sprawdzenie swoich osobistych danych kontaktowych. Dzięki tym informacjom proces rozpatrywania Twojego wniosku będzie szybszy i łatwiejszy. Twoje dane umożliwią nam automatyczne wypełnianie umów lub skontaktowanie się z Tobą w razie potrzeby. Dziękujemy za współpracę!',
    bank_account_title: 'Konto bankowe',
    bank_account_description:
      'Informacje o koncie bankowym służą do przygotowania dokumentacji umownej, a także do przesłania kwoty pieniężnej zgodnie z umową zlecenia w przypadku pomyślnego uzyskania odszkodowania.',
    iban_title: 'Numer konta w formacie IBAN'
  },
  address: 'Adres zamieszkania',
  address_line_2: 'Adres, wiersz 2',
  city: 'Miasto',
  postal_code: 'Kod pocztowy',
  country: 'Kraj',
  phone_number: 'Nr telefonu',
  bank_account_owner: 'Imię właściciela konta',
  iban: 'IBAN',
  swift: 'SWIFT',
  airplaneBackgroundText:
    'Aby uzyskać więcej szczegółów dotyczących swojego wniosku, zaloguj się na swoje konto po złożeniu wniosku.',
  cancel: 'Anuluj',
  application_done_title: 'Mamy wszystko, czego potrzebujemy!',
  application_done_subtitle:
    'Rozpatrzymy Twój wniosek o odszkodowanie i wyślemy Ci informacje o dalszych krokach na Twój adres e-mail. Aktualny status swojego wniosku możesz śledzić na swoim koncie. Więcej informacji znajduje się już w Twojej skrzynce e-mailowej.',
  add_document: 'Dodaj dokument',
  reprimand_contract: 'Umowa zlecenia',
  delegation_document: 'Pełnomocnictwo',
  my_claims_view: {
    personal_information: 'Dane osobowe wnioskodawcy',
    departure_place: 'Miejsce wylotu',
    arrival_place: 'Miejsce przylotu',
    claim: 'Wniosek',
    claim_number: 'NUMER WNIOSKU',
    requestor: 'Imię wnioskodawcy',
    submission_date: 'Data złożenia',
    all_documents_requirement: 'Należy wysłać wszystkie dokumenty.',
    upload_documents: 'Prześlij dokumenty',
    here: 'TUTAJ',
    claim_type: 'Typ wniosku',
    title: 'Złożenie wniosku szybko i łatwo!',
    fast_and_easy: 'Szybko i łatwo',
    fast_and_easy_neutral: 'szybko i łatwo!',
    all_airlines: 'Wszystkie linie lotnicze',
    no_risks: 'Bez ryzyka opłat',
    fast_handling: 'Zajmie to tylko 3 minuty.',
    show_more: 'Zobacz szczegóły wniosku',
    show_less: 'Zobacz mniej',
    residence: 'Miejsce zamieszkania',
    email_address: 'Adres e-mail',
    claim_status: 'Aktualny status wniosku',
    other_passengers: 'Inni pasażerowie',
    description:
      'Nasz zespół profesjonalistów z zakresu prawa lotniczego sprawdzi Twój wniosek i będzie Cię na bieżąco informować o każdym osiągniętym etapie procesu.',
    day_of_flight: 'Dzień lotu'
  },
  IdOrPass: 'Dowód osobisty (przednia strona) lub paszport',
  claimDocuments: 'Dokumenty do roszczenia',
  representationDocuments: 'Dokumenty do pełnomocnictwa',
  deleteConfirmationText:
    'Nie będziesz mieć dostępu do dokumentu. Dokument zostanie trwale usunięty.',
  deleteConfirmationTitle: 'Czy na pewno chcesz usunąć ten dokument?',
  coPassengerNumber: 'Pasażer nr',
  unknownFile: 'Nieznany plik',
  approvedByAdmin: 'Zatwierdzone przez administratora',
  missingDocuments: 'Brakujące dokumenty',
  sentForValidation: 'Wysłane do weryfikacji',
  IdontHave: 'Nie mam',
  other_airline_communication:
    'Czy kontaktowałeś się z linią lotniczą lub inną firmą w celu uzyskania odszkodowania?',
  yes_communicated: 'Tak, skontaktowaliśmy się',
  no_communication: 'Nie, nie kontaktowaliśmy się',
  communication: 'Krótki opis komunikacji',
  explain_communication:
    'Tutaj krótko opisz swoją komunikację z linią lotniczą lub firmą zajmującą się uzyskaniem odszkodowania',
  send_without_documentation: 'Wyślij bez dokumentów',
  send: 'Wyślij',
  continue_to_detail: 'Przejdź do szczegółów wniosku',
  rest_upload_later: 'Resztę uzupełnię później',
  delete: 'Usuń',
  reprimand_contract_description: 'Umowa zlecenia to umowa, którą podpisujesz z naszą firmą.',
  delegation_document_description:
    'Pełnomocnictwo to dokument, który pozwala nam reprezentować Cię w sprawie wniosku.',
  idOrPass_description: 'Dowód osobisty lub paszport jest wymagany do weryfikacji tożsamości.',
  step_number: 'Krok nr'
}
