<script setup lang="ts">
import type { FileResponse, IProcessedCustomer } from '@/interfaces/interfaces'
import { DocumentCategory, DocumentGroup, DocumentState } from '@/enumerators/enumerators'
import { computed, type Ref, ref, toRef, watch } from 'vue'
import Dialog from '@/components/common/dialog/Dialog.vue'
import ConfirmationModal from '@/components/common/dialog/dialogTypes/ConfirmationModal.vue'
import UploadModal from '@/components/common/dialog/dialogTypes/UploadModal.vue'
import Button from '@/components/Button.vue'
import { useRoute } from 'vue-router'
import { ROUTE_NAMES } from '@/constants/constants'

const props = defineProps<{
  sectionTitle: string
  sectionId: number
  sectionType: DocumentCategory
  documentState: DocumentState
  documentGroup: DocumentGroup
  sectionHelpText: string
  files: FileResponse[]
  userName?: string
  userId?: number
  coPassengers?: IProcessedCustomer[]
  isLegalDocumentsSectionAllowed?: boolean
}>()

const emits = defineEmits(['update:removeFiles', 'update:noSelected', 'update:uploadFiles'])
const reactiveDocument = toRef(props, 'documentState')
const isNoSelected = ref(false)
const showConfirmationDialog = ref(false)
const showUploadDialog = ref(false)
const uploadedFiles: Ref<File[]> = ref([])
const route = useRoute()

const checkIsNoSelected = (): boolean => {
  return (
    reactiveDocument.value === DocumentState.NO &&
    props.files.some((file) => {
      return file.claim_file === null
    })
  )
}
isNoSelected.value = checkIsNoSelected()

watch(reactiveDocument, () => {
  isNoSelected.value = checkIsNoSelected()
})

watch(props.files, () => {
  isNoSelected.value = checkIsNoSelected()
})

const clearFiles = (value: boolean) => {
  showConfirmationDialog.value = false
  if (value)
    emits('update:removeFiles', {
      files: props.files,
      sectionId: props.sectionId
    })
}

const handleNoSelected = () => {
  emits('update:uploadFiles', {
    files: null,
    sectionId: props.sectionId,
    sectionType: props.sectionType,
    userId: props.userId,
    documentState: props.documentState
  })
}

const isAllApproved = computed((): boolean => {
  return props.files.every((file) => file?.approved)
})

const handleDownload = () => {
  props.files.forEach((file, index) => {
    setTimeout(() => {
      window.open(file.claim_file?.url, '_blank')
    }, index * 500)
  })
}

const handleUpload = () => {
  showUploadDialog.value = false
  if (uploadedFiles.value.length > 0)
    emits('update:uploadFiles', {
      files: uploadedFiles.value,
      sectionId: props.sectionId,
      sectionType: props.sectionType,
      userId: props.userId,
      documentState: props.documentState
    })
}

const handlePassengerNumber = () => {
  if (props.coPassengers) {
    let passengerIdx = props.coPassengers.findIndex(
      (passenger) => passenger.customerId === props.userId
    )
    // +2 because the first one is always requestor
    return passengerIdx > -1 ? passengerIdx + 2 : 1
  }
}

const isDownloadAllowed = computed(() => {
  return route.name !== ROUTE_NAMES.DOCUMENTS
})
</script>

<template>
  <Dialog v-model="showConfirmationDialog">
    <template #dialogBody>
      <ConfirmationModal
        :confirmation-text="`${$t('deleteConfirmationText')}`"
        :confirmation-title="`${$t('deleteConfirmationTitle')}`"
        @update:confirmation="clearFiles"
      />
    </template>
  </Dialog>
  <Dialog v-model="showUploadDialog">
    <template #dialogBody>
      <UploadModal v-model="uploadedFiles" @update:upload-files="handleUpload" />
    </template>
  </Dialog>
  <v-row v-if="props.userId && props.userName" no-gutters class="padding-top-18">
    <v-col>
      <span class="passenger">{{ props.userName }}</span>
    </v-col>
    <v-col class="d-flex justify-end">
      <span class="passenger padding-bottom-10">
        {{ $t('coPassengerNumber') }} {{ handlePassengerNumber() }}
      </span>
    </v-col>
  </v-row>
  <v-row no-gutters class="padding-top-18">
    <v-col
      cols="12"
      xxl="6"
      xl="6"
      lg="6"
      md="6"
      sm="6"
      class="d-flex flex-column align-start justify-center"
    >
      <div class="d-flex w-100">
        <span class="fileName"
          >{{ sectionTitle }}
          <v-tooltip max-width="250" min-width="250" v-if="sectionHelpText" :text="sectionHelpText">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props">mdi-information-slab-circle-outline</v-icon>
            </template>
          </v-tooltip></span
        >
      </div>
    </v-col>
    <v-col
      v-if="files.length === 0 && !isNoSelected"
      class="d-flex justify-xxl-end justify-xl-end justify-lg-end justify-md-end justify-sm-end justify-center align-center"
    >
      <div class="d-flex padded">
        <div class="padding-right-18">
          <Button
            v-if="documentGroup !== DocumentGroup.legalDocuments"
            @click="handleNoSelected"
            type="primary-outlined-blue"
            :title="$t('IdontHave')"
            width="110px"
            height="32px"
            :disabled="isLegalDocumentsSectionAllowed"
          ></Button>
        </div>
        <div>
          <Button
            @click="showUploadDialog = true"
            type="primary-outlined-blue"
            :title="$t('upload')"
            width="100px"
            height="32px"
            :disabled="
              documentGroup !== DocumentGroup.legalDocuments
                ? isLegalDocumentsSectionAllowed
                : false
            "
          ></Button>
        </div>
      </div>
    </v-col>
    <v-col
      v-else-if="isNoSelected"
      class="d-flex justify-xxl-end justify-xl-end justify-lg-end justify-md-end justify-sm-end justify-center align-center"
    >
      <v-btn color="grey" height="32px" width="110px" disabled>{{ $t('IdontHave') }}</v-btn>
      <v-tooltip class="ml-0" location="top" :text="$t('cancel')">
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            color="transparent"
            icon="mdi-cancel"
            flat
            @click="clearFiles"
          ></v-btn>
        </template>
      </v-tooltip>
    </v-col>
    <v-col
      v-else
      class="d-flex justify-xxl-end justify-xl-end justify-lg-end justify-md-end justify-sm-end justify-center align-center"
    >
      <p class="fileName">
        {{ files.map((file) => file.claim_file?.original_name || $t('unknownFile')).join(',') }}
      </p>
      <v-tooltip class="ml-0" location="top" :text="$t('delete')">
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="!isAllApproved"
            v-bind="props"
            color="transparent"
            :icon="true"
            flat
            @click="showConfirmationDialog = true"
          >
            <v-img src="/images/trash-can.svg" alt="trash" width="20" height="20"></v-img>
          </v-btn>
        </template>
      </v-tooltip>
      <v-tooltip class="ml-0" location="top" :text="$t('download')">
        <template v-slot:activator="{ props }">
          <v-btn
            v-if="isDownloadAllowed"
            v-bind="props"
            color="transparent"
            :icon="true"
            flat
            @click="handleDownload"
          >
            <v-img src="/images/download.svg" alt="download" width="20" height="20"></v-img>
          </v-btn>
        </template>
      </v-tooltip>
    </v-col>
    <v-divider thickness="2px" class="mt-3" />
  </v-row>
</template>

<style scoped>
.v-btn {
  text-transform: none !important;
}
.padding-bottom-10 {
  padding-bottom: 10px;
}
.padding-top-18 {
  padding-top: 18px;
}
.padding-right-18 {
  padding-right: 18px;
}
span {
  font-size: 16px;
  font-weight: 600;

  &.passenger {
    color: #3e3caa;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 10px;
  }

  &.fileName {
    color: #000;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

:deep(.v-btn__overlay) {
  background-color: transparent !important;
}
.padded {
  @media (max-width: 600px) {
    padding-top: 10px;
  }
}

.spacing {
  @media (max-width: 1280px) {
    justify-content: space-between;
  }
}
</style>
