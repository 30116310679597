import axios, {
  AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse
} from 'axios'
import { handleApiError } from '@/utils/commsWrapper'
import { NETWORK_ERROR_TYPES, RETRY_AUTHORIZATION_PAGES } from '@/constants/constants'

let retryCount = 0

const getConfig = (
  prefix: string,
  extendedOptions: AxiosRequestConfig = {}
): AxiosRequestConfig => {
  return {
    withCredentials: true,
    baseURL: `${import.meta.env.VITE_API_BASE_URL}api/v1/${prefix}`,
    headers: {
      'Content-Type': 'application/json'
    },
    ...extendedOptions
  }
}

async function retryRequest(config: AxiosRequestConfig): Promise<void> {
  try {
    return axios.request(config)
  } catch (error) {
    return Promise.reject(error)
  }
}
export function createHttpClient(prefix: string): AxiosInstance {
  const config = getConfig(prefix)
  const refreshTokenConfig = getConfig('customers/refresh-token', {
    method: 'post'
  })
  const client: AxiosInstance = axios.create(config)

  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      if (
        error.response?.status === 401 &&
        !RETRY_AUTHORIZATION_PAGES.includes(error.config.url) &&
        retryCount < 1
      ) {
        retryCount++
        await retryRequest(refreshTokenConfig) // Refresh token
        return retryRequest(config)
      } else if (error.response?.status === 500 || NETWORK_ERROR_TYPES.includes(error.code)) {
        handleApiError()
      }
      return Promise.reject(error)
    }
  )
  return client
}
