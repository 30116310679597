import { defineStore } from 'pinia'
import type {
  DistanceVerification,
  ApplicationState,
  FlightIdentification,
  CompensationReason,
  Passenger,
  Document
} from '@/interfaces/interfaces'
import { useCatalogService } from '@/service/services/CatalogService'
import { DEFAULT_APPLICATION_VALUES } from '@/constants/constants'
export const useApplicationStore = defineStore('application', {
  state: (): ApplicationState => ({
    distanceVerification:
      JSON.parse(sessionStorage.getItem('distanceVerification')) ||
      DEFAULT_APPLICATION_VALUES.distanceVerification,
    flightIdentification:
      JSON.parse(sessionStorage.getItem('flightIdentification')) ||
      DEFAULT_APPLICATION_VALUES.flightIdentification,
    reasons: JSON.parse(sessionStorage.getItem('reasons')) || DEFAULT_APPLICATION_VALUES.reasons,
    passenger:
      JSON.parse(sessionStorage.getItem('passenger')) || DEFAULT_APPLICATION_VALUES.passenger,
    documents: [
      {
        name: 'documents_1',
        isUploaded: false,
        image: [],
        helpText: 'documents_1_help'
      },
      {
        name: 'documents_2',
        isUploaded: false,
        image: [],
        helpText: 'documents_2_help'
      },
      {
        name: 'documents_3',
        isUploaded: false,
        image: [],
        helpText: 'documents_3_help'
      },
      {
        name: 'documents_4',
        isUploaded: false,
        image: [],
        helpText: 'documents_4_help'
      },
      {
        name: 'documents_5',
        isUploaded: false,
        image: [],
        helpText: 'documents_5_help'
      },
      {
        name: 'documents_6',
        isUploaded: false,
        image: [],
        helpText: 'documents_6_help'
      }
    ]
  }),
  persist: {
    storage: sessionStorage,
    paths: ['distanceVerification', 'flightIdentification', 'reasons', 'passenger']
  },
  getters: {
    getDistanceVerification: function (state): DistanceVerification {
      return state.distanceVerification
    },
    getFlightIdentification: function (state): FlightIdentification {
      return state.flightIdentification
    },
    getReasons: function (state): CompensationReason {
      return state.reasons
    },
    getPassengerDetail: function (state): Passenger {
      return state.passenger
    },
    getDocuments: function (state): Array<Document> {
      return state.documents
    }
  },
  actions: {
    async getAirports(searchTerm: string) {
      const { getAirports } = useCatalogService()
      return await getAirports(searchTerm)
    },
    async getAirlines(searchTerm: string) {
      const { getAirlines } = useCatalogService()
      return await getAirlines(searchTerm)
    },
    async getAirportById(id: string = '') {
      const { getAirportById } = useCatalogService()
      return await getAirportById(id)
    },
    setDistanceVerification(data: DistanceVerification) {
      this.distanceVerification = data
    },
    setFlightIdentification(data: FlightIdentification) {
      this.flightIdentification = data
    },
    setCompensationReasons(data: CompensationReason) {
      this.reasons = data
    },
    setPassengerDetail(data: Passenger): void {
      this.passenger = data
    },
    setDocuments(data: Array<Document>): void {
      this.documents = data
    },
    clearApplication(): void {
      const distanceVerification = {
        fromAirport: null,
        toAirport: null,
        isDirect: true,
        restAirports: [],
        airline: null,
        flightNumber: '',
        flightDate: '',
        reservationNumber: ''
      }

      const reasons = {
        flightProblem: null,
        flightProblemLength: null,
        reasonDelay: null,
        reasonDelayDetail: null,
        description: ''
      }

      const passenger = {
        email: '',
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        address: {
          street: '',
          city: '',
          country: '',
          postCode: ''
        },
        phoneNumber: '',
        coPassengers: []
      }
      this.distanceVerification = distanceVerification
      this.reasons = reasons
      this.passenger = passenger
      //this.documents = documents
    }
  }
})
