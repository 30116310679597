<script setup lang="ts"></script>

<template>
  <svg width="20" height="20" viewBox="-3 3 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="user 2">
      <path
        id="Vector"
        d="M13.0827 9.76393C13.9026 9.12279 14.502 8.24177 14.7972 7.24373C15.0925 6.24568 15.069 5.18037 14.7299 4.19634C14.3909 3.21231 13.7532 2.35862 12.9058 1.75431C12.0584 1.15 11.0435 0.825195 10.0027 0.825195C8.96187 0.825195 7.94701 1.15 7.0996 1.75431C6.2522 2.35862 5.61451 3.21231 5.27544 4.19634C4.93637 5.18037 4.91284 6.24568 5.20813 7.24373C5.50341 8.24177 6.10279 9.12279 6.92268 9.76393C5.37366 10.3803 4.0449 11.4467 3.1079 12.8256C2.17091 14.2045 1.66867 15.8326 1.66602 17.4998V18.3331C1.66602 18.5541 1.75381 18.7661 1.91009 18.9223C2.06637 19.0786 2.27834 19.1664 2.49935 19.1664H17.4993C17.7204 19.1664 17.9323 19.0786 18.0886 18.9223C18.2449 18.7661 18.3327 18.5541 18.3327 18.3331V17.4998C18.3306 15.8334 17.8293 14.2059 16.8936 12.827C15.9578 11.4482 14.6305 10.3813 13.0827 9.76393ZM6.66602 5.83309C6.66602 5.17382 6.86151 4.52936 7.22778 3.98119C7.59406 3.43303 8.11465 3.00579 8.72374 2.75349C9.33282 2.5012 10.003 2.43519 10.6497 2.56381C11.2963 2.69243 11.8902 3.00989 12.3564 3.47607C12.8225 3.94225 13.14 4.53619 13.2686 5.18279C13.3973 5.82939 13.3312 6.49962 13.0789 7.1087C12.8267 7.71779 12.3994 8.23839 11.8512 8.60466C11.3031 8.97093 10.6586 9.16643 9.99935 9.16643C9.11529 9.16643 8.26745 8.81524 7.64233 8.19011C7.01721 7.56499 6.66602 6.71715 6.66602 5.83309ZM3.33268 17.4998C3.33268 15.7316 4.03506 14.036 5.2853 12.7857C6.53555 11.5355 8.23124 10.8331 9.99935 10.8331C11.7675 10.8331 13.4632 11.5355 14.7134 12.7857C15.9636 14.036 16.666 15.7316 16.666 17.4998H3.33268Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<style scoped></style>
