export enum CompensationTypes {
  delayed = 'flight_delay',
  cancelled = 'flight_cancel',
  connectingCanceled = 'flight_connecting_delay'
}

export enum FlightType {
  from = 1,
  to = 2,
  rest = 3
}

export enum DocumentCategory {
  flightTicket = 'flight_ticket',
  flightProblemConfirmation = 'flight_problem_confirmation',
  airlineCommunication = 'airline_communication',
  travelAgencyCommunication = 'travel_agency_communication',
  otherCompanyCommunication = 'other_company_communication',
  powerOfAttorney = 'power_of_attorney',
  contractDocument = 'contract_document',
  identityCard = 'identity_card',
  other = 'other'
}

export enum DocumentState {
  YES = 'yes',
  NO = 'no',
  UNKNOWN = 'unknown'
}

export enum DocumentGroup {
  legalDocuments = 'legal_documents',
  claimAttachments = 'claim_attachments',
  other = 'other'
}

export enum ApprovalType {
  approved = 0,
  missingDocuments = 1,
  inValidation = 2
}

export enum ClaimAlertStatusType {
  SUCCESS = 'success',
  ALERT = 'alert'
}

export enum ClaimStatusType {
  current = 'current',
  completed = 'completed',
  skipped = 'skipped',
  completed_error = 'completed_error',
  alert = 'alert'
}
