<script setup lang="ts"></script>

<template>
  <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0961 10.9739L12.1878 9.66724C12.0638 9.48961 11.9971 9.27832 11.9966 9.06171V5.31167C11.9966 3.90293 11.4369 2.55188 10.4408 1.55575C9.44467 0.559621 8.09363 0 6.68489 0C5.27614 0 3.9251 0.559621 2.92897 1.55575C1.93284 2.55188 1.37322 3.90293 1.37322 5.31167V9.06171C1.3727 9.27832 1.30598 9.48961 1.182 9.66724L0.273702 10.9739C0.136172 11.1263 0.0457342 11.3153 0.0133252 11.5179C-0.0190838 11.7206 0.00792391 11.9284 0.0910824 12.116C0.174241 12.3037 0.309989 12.4633 0.48191 12.5754C0.653831 12.6876 0.854561 12.7475 1.05983 12.748H4.08217C4.20408 13.3484 4.5298 13.8881 5.00414 14.2758C5.47847 14.6635 6.07226 14.8753 6.68489 14.8753C7.29751 14.8753 7.8913 14.6635 8.36563 14.2758C8.83997 13.8881 9.16569 13.3484 9.2876 12.748H12.3099C12.5152 12.7475 12.7159 12.6876 12.8879 12.5754C13.0598 12.4633 13.1955 12.3037 13.2787 12.116C13.3618 11.9284 13.3889 11.7206 13.3564 11.5179C13.324 11.3153 13.2336 11.1263 13.0961 10.9739ZM6.68489 13.8103C6.35612 13.8094 6.03569 13.7068 5.76753 13.5166C5.49936 13.3264 5.29658 13.058 5.187 12.748H8.18278C8.07319 13.058 7.87041 13.3264 7.60224 13.5166C7.33408 13.7068 7.01365 13.8094 6.68489 13.8103ZM1.05983 11.6857C1.08384 11.6634 1.10522 11.6384 1.12357 11.6113L2.05311 10.2728C2.30108 9.91751 2.43452 9.49494 2.43555 9.06171V5.31167C2.43555 4.18467 2.88325 3.10384 3.68015 2.30694C4.47706 1.51003 5.55789 1.06233 6.68489 1.06233C7.81188 1.06233 8.89271 1.51003 9.68962 2.30694C10.4865 3.10384 10.9342 4.18467 10.9342 5.31167V9.06171C10.9352 9.49494 11.0687 9.91751 11.3167 10.2728L12.2462 11.6113C12.2645 11.6384 12.2859 11.6634 12.3099 11.6857H1.05983Z"
      fill="#3E3CAA"
    />
  </svg>
</template>

<style scoped></style>
