// store/site.js
import { defineStore } from 'pinia'
export const useSiteStore = defineStore('site', {
  state: () => ({
    snackbar: {
      show: false,
      message: '',
      color: 'success'
    },
    currentRoute: '',
    hideNavbar: false,
    isLoading: false,
    selectedLanguage: sessionStorage.getItem('selectedLanguage') || 'sk'
  }),
  persist: {
    storage: sessionStorage,
    paths: ['selectedLanguage']
  },
  getters: {
    getSelectedLanguage: function (state): string {
      return state.selectedLanguage
    },
    getLoading: function (state): boolean {
      return state.isLoading
    },
    getCurrentRoute: function (state): string {
      return state.currentRoute
    },
    getHideNavbar: function (state): boolean {
      return state.hideNavbar
    }
  },
  actions: {
    setSelectedLanguage(language: string): void {
      this.selectedLanguage = language
    },
    setLoading(loading: boolean): void {
      this.isLoading = loading
    },
    showSnackbar(message, color = 'success') {
      this.snackbar = { show: true, message, color }
    },
    hideSnackbar() {
      this.snackbar.show = false
    },
    setCurrentRoute(route: string): void {
      this.currentRoute = route
    },
    setHideNavbar(hide: boolean): void {
      this.hideNavbar = hide
    }
  }
})
