import { createHttpClient } from '@/service/client'
import type { AirlineResponse, AirportResponse } from '@/interfaces/interfaces'
import { handleApiError } from '@/utils/commsWrapper'

const prefix = 'catalog/'
export function useCatalogService() {
  const client = createHttpClient(prefix)

  const getAirports = async (
    searchTerm: string = ''
  ): Promise<Array<AirportResponse> | undefined> => {
    try {
      const { data } = await client.get(`airports?search_term=${searchTerm}`)
      return data
    } catch (error) {
      handleApiError(error)
    }
  }

  const getAirlines = async (
    searchTerm: string = ''
  ): Promise<Array<AirlineResponse> | undefined> => {
    try {
      const { data } = await client.get(`airlines?search_term=${searchTerm}`)
      return data
    } catch (error) {
      handleApiError(error)
    }
  }

  const getAirportById = async (id: string = ''): Promise<AirportResponse | undefined> => {
    try {
      const { data } = await client.get(`airports/${id}`)
      return data
    } catch (error) {
      handleApiError(error)
    }
  }

  return { getAirports, getAirlines, getAirportById }
}
