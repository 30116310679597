<script setup lang="ts">
import type { FileResponse, IProcessedClaim } from '@/interfaces/interfaces'
import { computed, reactive, toRef, type UnwrapRef, watch } from 'vue'
import {
  ApprovalType,
  DocumentCategory,
  DocumentGroup,
  DocumentState
} from '@/enumerators/enumerators'
import AttachmentItem from '@/components/common/attachmentItem/AttachmentItem.vue'
import { useClaimStore } from '@/stores/claim'
import { useI18n } from 'vue-i18n'
import DocumentApproval from '@/components/claim/myClaim/DocumentApproval.vue'

interface ISection {
  sectionTitle: string
  sectionGroup: DocumentGroup
  sectionType: DocumentCategory
  sectionHelpText: string
  documentState?: DocumentState
  userId?: number | null
  userName?: string | null
  files: FileResponse[]
}

const props = defineProps<{
  files: FileResponse[]
  claim?: IProcessedClaim | null
}>()

const emits = defineEmits(['filesChanged'])

const claimStore = useClaimStore()

const filesReactive = toRef(props, 'files')
const { t } = useI18n()

const attachmentSections: UnwrapRef<ISection[]> = reactive([
  {
    sectionTitle: t('documents_1'),
    sectionGroup: DocumentGroup.claimAttachments,
    sectionType: DocumentCategory.flightTicket,
    documentState: DocumentState.UNKNOWN,
    sectionHelpText: t('documents_1_help'),
    files: []
  },
  {
    sectionTitle: t('documents_2'),
    sectionType: DocumentCategory.flightProblemConfirmation,
    sectionGroup: DocumentGroup.claimAttachments,
    documentState: DocumentState.UNKNOWN,
    sectionHelpText: t('documents_2_help'),
    files: []
  },
  {
    sectionTitle: t('documents_3'),
    sectionType: DocumentCategory.airlineCommunication,
    sectionGroup: DocumentGroup.claimAttachments,
    documentState: DocumentState.UNKNOWN,
    sectionHelpText: t('documents_3_help'),
    files: []
  },
  {
    sectionTitle: t('documents_4'),
    sectionType: DocumentCategory.travelAgencyCommunication,
    sectionGroup: DocumentGroup.claimAttachments,
    documentState: DocumentState.UNKNOWN,
    sectionHelpText: t('documents_4_help'),
    files: []
  },
  {
    sectionTitle: t('documents_5'),
    sectionType: DocumentCategory.otherCompanyCommunication,
    sectionGroup: DocumentGroup.claimAttachments,
    documentState: DocumentState.UNKNOWN,
    sectionHelpText: t('documents_5_help'),
    files: []
  },
  {
    sectionTitle: t('documents_other'),
    sectionType: DocumentCategory.other,
    sectionGroup: DocumentGroup.other,
    documentState: DocumentState.UNKNOWN,
    sectionHelpText: t('documents_other_help'),
    files: []
  }
])
let legalSections: UnwrapRef<ISection[]> = reactive([
  {
    sectionTitle: `${t('reprimand_contract')} (SCAN)`,
    sectionType: DocumentCategory.contractDocument,
    sectionGroup: DocumentGroup.legalDocuments,
    documentState: DocumentState.UNKNOWN,
    sectionHelpText: t('reprimand_contract_description'),
    userName: '',
    files: []
  }
])

const hydrateSections = (selectedSection: ISection[]) => {
  filesReactive.value.forEach((file) => {
    const section = selectedSection.find((section) => {
      if (section.sectionType === file.category && section.sectionGroup === file.group_claim_file) {
        if (!section.userId) {
          return section
        } else if (section.userId === file.customer_id) {
          return section
        }
      }
    })
    if (section) {
      section.documentState = file.claim_document_state
      section.files.push(file)
    }
  })
}

const generateLegalSections = () => {
  legalSections.push(
    {
      // requestor
      sectionTitle: `${t('delegation_document')} (SCAN)`,
      userId: props.claim?.customerId,
      userName: props.claim?.fullName,
      sectionGroup: DocumentGroup.legalDocuments,
      sectionType: DocumentCategory.powerOfAttorney,
      documentState: DocumentState.UNKNOWN,
      sectionHelpText: t('delegation_document_description'),
      files: []
    },
    {
      sectionTitle: `${t('IdOrPass')} (SCAN)`,
      userId: props.claim?.customerId,
      sectionType: DocumentCategory.identityCard,
      sectionGroup: DocumentGroup.legalDocuments,
      documentState: DocumentState.UNKNOWN,
      sectionHelpText: t('idOrPass_description'),
      files: []
    }
  )
  props.claim?.coPassengers.forEach((coPassenger) => {
    legalSections.push(
      {
        sectionTitle: `${t('delegation_document')} (SCAN)`,
        userId: coPassenger.customerId,
        sectionGroup: DocumentGroup.legalDocuments,
        userName: coPassenger.fullName,
        sectionType: DocumentCategory.powerOfAttorney,
        documentState: DocumentState.UNKNOWN,
        sectionHelpText: t('delegation_document_description'),
        files: []
      },
      {
        sectionTitle: `${t('IdOrPass')} (SCAN)`,
        userId: coPassenger.customerId,
        sectionType: DocumentCategory.identityCard,
        sectionGroup: DocumentGroup.legalDocuments,
        documentState: DocumentState.UNKNOWN,
        sectionHelpText: t('idOrPass_description'),
        files: []
      }
    )
  })
}

const noSelectedAttachmentSection = ({ sectionId, selected }) => {
  attachmentSections[sectionId].documentState = selected
}

const noSelectedLegalSection = ({ sectionId, selected }) => {
  legalSections[sectionId].documentState = selected
}

const isApproved = (selectedGroupSection: ISection[]): ApprovalType => {
  const numberOfFilledSubSections = selectedGroupSection.filter((section: ISection) => {
    if (section.files.length > 0) {
      return true
    }
  }).length
  const subSectionsContainAllFiles = numberOfFilledSubSections === selectedGroupSection.length
  const allSubSectionsApproved = selectedGroupSection.every((section) =>
    section.files.every((file) => file?.approved)
  )

  if (subSectionsContainAllFiles && allSubSectionsApproved) {
    return ApprovalType.approved
  } else if (!subSectionsContainAllFiles) {
    return ApprovalType.missingDocuments
  } else if (subSectionsContainAllFiles && !allSubSectionsApproved) {
    return ApprovalType.inValidation
  } else {
    return ApprovalType.missingDocuments
  }
}

const handleRemoveFiles = async (
  selectedSection: ISection[],
  event: {
    files: FileResponse[]
    sectionId: number
  }
) => {
  for (const file of event.files) {
    await claimStore.removeDocument(file.id)
  }
  selectedSection[event.sectionId].files = []
  selectedSection[event.sectionId].documentState = DocumentState.UNKNOWN
  handleFilesChangedEmit()
}

const handleFilesChangedEmit = () => {
  // check if any of the sections has files if yes emit
  emits(
    'filesChanged',
    attachmentSections.map((section) => section.files)
  )
}

const handleUploadedFiles = async (
  selectedSection: ISection[],
  event: {
    documentState: DocumentState
    files: File[] | null
    sectionId: number
    sectionType: DocumentCategory
    userId: number
  }
) => {
  const changedFiles = []
  let userId = props.claim?.customerId
  if (event.userId && event.userId !== -1) {
    userId = event.userId
  }
  if (event.files) {
    for (const file of event.files) {
      changedFiles.push({
        claimId: props.claim?.claimId,
        requestData: file,
        customerId: userId,
        category: event.sectionType,
        group_file: selectedSection[event.sectionId].sectionGroup,
        documentState: DocumentState.YES
      })
    }
  } else {
    changedFiles.push({
      claimId: props.claim?.claimId,
      requestData: event.files,
      customerId: userId,
      category: event.sectionType,
      group_file: selectedSection[event.sectionId].sectionGroup,
      documentState: DocumentState.NO
    })
  }

  for (const file of changedFiles) {
    const updatedFile = await claimStore.uploadDocument(
      file.claimId,
      file.requestData,
      file.customerId,
      file.category,
      file.group_file,
      file.documentState
    )
    if (!updatedFile) {
      selectedSection[event.sectionId].files = []
      selectedSection[event.sectionId].documentState = DocumentState.UNKNOWN
    } else {
      selectedSection[event.sectionId].files.push(updatedFile)
      selectedSection[event.sectionId].documentState = updatedFile.claim_document_state
    }
  }
  handleFilesChangedEmit()
}
const isLegalDocumentsSectionAllowed = computed((): boolean => {
  return props.claim?.statusStep >= 3
})

watch(filesReactive, () => {
  hydrateSections(attachmentSections)
  hydrateSections(legalSections)
})

hydrateSections(attachmentSections)
generateLegalSections()
hydrateSections(legalSections)
</script>

<template>
  <DocumentApproval
    :title="`1. ${t('claimDocuments')}`"
    :is-approved="isApproved(attachmentSections)"
  />
  <AttachmentItem
    v-for="(section, idx) in attachmentSections"
    :key="idx"
    :section-id="idx"
    :section-help-text="section.sectionHelpText"
    :section-title="section.sectionTitle"
    :files="section.files"
    :section-type="section.sectionType"
    :document-state="section.documentState"
    :document-group="section.sectionGroup"
    :is-legal-documents-section-allowed="isLegalDocumentsSectionAllowed"
    @update:remove-files="(event) => handleRemoveFiles(attachmentSections, event)"
    @update:no-selected="noSelectedAttachmentSection"
    @update:upload-files="(event) => handleUploadedFiles(attachmentSections, event)"
  />
  <DocumentApproval
    v-if="isLegalDocumentsSectionAllowed"
    :title="`2. ${$t('representationDocuments')}`"
    :is-approved="isApproved(legalSections)"
  />
  <AttachmentItem
    v-if="isLegalDocumentsSectionAllowed"
    v-for="(section, idx) in legalSections"
    :key="idx"
    :section-id="idx"
    :user-id="section.userId"
    :user-name="section.userName"
    :section-help-text="section.sectionHelpText"
    :section-title="section.sectionTitle"
    :files="section.files"
    :section-type="section.sectionType"
    :document-state="section.documentState"
    :document-group="section.sectionGroup"
    :co-passengers="claim?.coPassengers"
    @update:remove-files="(event) => handleRemoveFiles(legalSections, event)"
    @update:no-selected="noSelectedLegalSection"
    @update:upload-files="(event) => handleUploadedFiles(legalSections, event)"
  />
</template>

<style scoped>
.v-btn {
  text-transform: unset !important;
}
p {
  font-size: 16px;
  font-weight: 600;

  &.document {
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
