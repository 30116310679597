import './assets/main.css'
// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify, type IconProps } from 'vuetify'
import { sk, en } from 'vuetify/locale'
import { createApp, h } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from '@sentry/vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import i18n from '../locales/i18n.config'
import DateFnsAdapter from '@date-io/date-fns'
import skSk from 'date-fns/locale/sk'
import App from './App.vue'
import router from './router'
import { createGtm } from '@gtm-support/vue-gtm'
import Menu from '@/components/icons/Menu.vue'
import type { IconSet } from 'vuetify'
import Notifications from '@/components/icons/Notifications.vue'
import Account from '@/components/icons/Account.vue'
import Linkedin from '@/components/icons/Linkedin.vue'
import X from '@/components/icons/X.vue'
import Instagram from '@/components/icons/Instagram.vue'
import Facebook from '@/components/icons/Facebook.vue'

const customIcons = {
  menu: Menu,
  notifications: Notifications,
  account: Account,
  instagram: Instagram,
  facebook: Facebook,
  linkedin: Linkedin,
  x_twitter: X
}

const customIconSet: IconSet = {
  component: (props: IconProps) =>
    h(customIcons[props.icon as string], { class: 'v-icon--size-default' })
}

const app = createApp(App)
const vuetify = createVuetify({
  defaults: {},
  icons: {
    sets: {
      // name of our custom icon
      customIconSet
    }
  },

  date: {
    adapter: DateFnsAdapter,
    locale: {
      en: skSk, //to do - problem with different format switching
      sk: skSk
    }
  },
  locale: {
    messages: { en, sk }
  }
})

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(router)
app.use(vuetify)
app.use(i18n)

if (import.meta.env.VITE_CUSTOM_ENV === 'production') {
  app.use(
    createGtm({
      id: import.meta.env.VITE_GTM_ID,
      defer: true,
      debug: import.meta.env.DEV,
      vueRouter: router
    })
  )
}

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_CUSTOM_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  })
}

app.mount('#app')
