<script setup lang="ts">
import { useClaimStore } from '@/stores/claim'
import DocumentAttachments from '@/components/claim/myClaim/DocumentAttachments.vue'
import type { FileResponse, IProcessedClaim } from '@/interfaces/interfaces'
import { onMounted, type Ref, ref } from 'vue'

const claimStore = useClaimStore()

const props = defineProps<{
  claim: IProcessedClaim
}>()
const emit = defineEmits(['dataLoaded'])

const files: Ref<FileResponse[]> = ref([])
onMounted(async () => {
  files.value = await claimStore.getMyClaimFiles(props.claim.claimId)
  emit('dataLoaded')
})
</script>

<template>
  <v-row>
    <v-col>
      <h3 class="card-title">{{ $t('upload_documents_title') }}</h3>
    </v-col>
  </v-row>
  <DocumentAttachments :files="files" :claim="claim" />
</template>

<style scoped>
.v-btn {
  text-transform: unset !important;
}
p {
  font-size: 16px;
  font-weight: 600;

  &.document {
    font-size: 14px;
    font-weight: 400;
  }
}

.padded {
  padding-right: 13px;
}

.save-btn {
  border-radius: 8px;
  border: 1px solid #3e3caa;
  background: #3e3caa;
  margin-top: -5px;
  margin-left: 15px;
  span {
    color: #3e3caa;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    font-size: 16px;

    &.white {
      color: white;
    }
  }
}
</style>
