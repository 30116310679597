<script lang="ts" setup>
import { ref } from 'vue'
import { APP_NAV, ROUTE_NAMES, APP_NAV_LOGGED } from '@/constants/constants'
import AppBarMobile from '@/components/navigation/appBar/AppBarMobile.vue'
import Button from '@/components/Button.vue'
import AppBarDesktop from '@/components/navigation/appBar/AppBarDesktop.vue'
import { useDisplay } from 'vuetify'
import { useRouter } from 'vue-router'
import { useCustomerStore } from '@/stores/customer'
import { useApplicationStore } from '@/stores/application'
import { computed } from 'vue'

let drawer = ref(false)
const { mobile } = useDisplay()
const router = useRouter()
const userStore = useCustomerStore()
const applicationStore = useApplicationStore()

const isLogged = computed((): boolean => {
  return !!userStore.getUser?.id
})
const navItems = computed(() => {
  return isLogged.value ? APP_NAV_LOGGED : []
})

const drawerClick = (): void => {
  drawer.value = !drawer.value
}
const handleApplication = (): void => {
  router.push({ name: ROUTE_NAMES.DISTANCE_VERIFICATION })
}

const handleAction = async (): Promise<void> => {
  if (isLogged.value) {
    await userStore.logout()
    drawer.value = false
    applicationStore.clearApplication()
  } else {
    handleApplication()
  }
}

const getCustomerName = (): string => {
  return `${userStore.user?.first_name || ''} ${userStore.user?.last_name || ''}`
}
const getCustomerEmail = (): string => {
  return userStore.user?.email || ''
}

const handleDrawer = (isMobile: boolean): void => {
  if (isMobile) drawerClick()
  if (!isLogged.value) {
    router.push({ name: ROUTE_NAMES.LOGIN })
  }
}
</script>

<template>
  <AppBarMobile v-if="mobile" @drawer-click="handleDrawer(true)" />
  <AppBarDesktop v-else @drawer-click="handleDrawer" />
  <v-navigation-drawer location="top" v-model="drawer" class="client-nav" disable-resize-watcher>
    <v-container>
      <v-row class="user-info px-2" v-if="isLogged">
        <div class="pr-6">
          <div class="outer-circle">
            <img src="/img/account.svg" class="account-icon" />
          </div>
        </div>

        <div>
          <p class="username">{{ getCustomerName() }}</p>
          <p class="email">{{ getCustomerEmail() }}</p>
        </div>
      </v-row>
      <template v-for="({ code, sef }, index) in navItems" :key="index">
        <div class="nav-item">
          <a :href="sef">{{ $t(`navbar.${code}`) }}</a>
        </div>
      </template>
    </v-container>
    <template v-slot:append>
      <v-container>
        <Button
          style="margin-bottom: 11px"
          type="primary-red"
          :title="$t('buttons.submit_claim')"
          @click="handleApplication"
        />
        <Button
          type="primary-blue"
          style="margin-bottom: 45px"
          :title="isLogged ? $t('buttons.logout') : $t('buttons.login')"
          @click="handleAction"
        />
      </v-container>
    </template>
  </v-navigation-drawer>
</template>

<style scoped>
.client-nav {
  display: flex;
  justify-content: space-between;
  height: 565px !important;

  @media (min-width: 1280px) {
    display: none;
  }
}
a {
  text-decoration: none;
  color: black;
}

.nav-item {
  font-size: 20px;
  font-weight: 600;
}

.user-info {
  margin-top: 32px;
  margin-bottom: 32px;
}

.username {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.email {
  color: #898989;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.outer-circle {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  background-color: #3e3caa;
  border-radius: 50%;
  text-align: center;
}

.account-icon {
  text-align: center;
  flex-shrink: 0;
  line-height: 28px !important;
  height: 18px;
  margin-top: 5px;
}
</style>
