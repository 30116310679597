<script setup lang="ts">
import MyClaimCardMobilePersonInformation from '@/components/claim/myClaim/mobile/MyClaimCardMobilePersonInformation.vue'
import ExpansionButton from '@/components/common/expansionButton/ExpansionButton.vue'
import MyClaimCardDocumentInformation from '@/components/claim/myClaim/MyClaimCardDocumentInformation.vue'
import type { IProcessedClaim } from '@/interfaces/interfaces'
import { nextTick, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import ClaimStatus from '@/components/claimStatus/ClaimStatus.vue'
import MyClaimStepperMobile from '@/components/claimStepper/MyClaimStepperMobile.vue'

const props = defineProps<{
  claim: IProcessedClaim
}>()

const isExpansionBtnExpanded = ref(false)
const expendableSection = ref(null)
const claimStepSection = ref(null)
const route = useRoute()

const handleExpansionBtnClick = () => {
  isExpansionBtnExpanded.value = true
}

const openDocumentSection = () => {
  if (route.query.cidentity) {
    const targetIndex = route.query.cidentity as string
    if (parseInt(targetIndex) === props.claim.claimId) {
      isExpansionBtnExpanded.value = true
    }
  } else if (route.query.cdetail) {
    const targetIndex = route.query.cdetail as string
    if (parseInt(targetIndex) === props.claim.claimId) {
      scrollToClaimView()
    }
  }
}

const scrollToDocumentSection = async () => {
  await nextTick()
  if (route.query.cidentity) {
    const targetIndex = route.query.cidentity as string
    if (parseInt(targetIndex) === props.claim.claimId) {
      scrollToDocumentView()
    }
  } else if (isExpansionBtnExpanded.value) {
    scrollToDocumentView()
  }
}

const scrollToDocumentView = () => {
  if (!expendableSection.value) return
  expendableSection.value.scrollIntoView({ behavior: 'smooth' })
}

const scrollToClaimView = () => {
  if (!claimStepSection.value) return
  claimStepSection.value.scrollIntoView({ behavior: 'smooth', block: 'center' })
}

onMounted(async () => {
  openDocumentSection()
})
</script>
<template>
  <div class="request-details">
    <v-row no-gutters>
      <v-col>
        <h3 class="card-title">{{ $t('my_claims_view.claim_number') }}: {{ claim.claimNumber }}</h3>
      </v-col>
    </v-row>
    <div class="user-credentials">
      <p>
        {{ $t('my_claims_view.submission_date') }}:
        <span class="font-weight-bold">{{ claim.submissionDate }}</span>
      </p>
      <p>
        {{ $t('my_claims_view.claim_type') }}:
        <span class="font-weight-bold"
          >{{ $t(`${claim.flightProblem}`) }} {{ claim.flightProblemInterval }}</span
        >
      </p>
      <p>
        {{ $t('card_flight_airline') }}:
        <span class="font-weight-bold">{{ claim.airlineName }}</span>
      </p>
    </div>
    <v-row class="top-padding">
      <v-col
        cols="5"
        class="d-flex flex-column align-center"
        style="padding-left: 0; padding-top: 5px"
      >
        <div>
          <p>{{ $t('my_claims_view.departure_place') }}</p>
          <h3 class="card-title">{{ claim.departurePlace }}</h3>
        </div>
      </v-col>
      <v-col cols="2" class="d-flex justify-center">
        <div>
          <v-img src="/images/plane.svg" alt="arrow" width="22" height="22"></v-img>
        </div>
      </v-col>
      <v-col cols="5" class="d-flex justify-center" style="padding-top: 5px; padding-right: 0">
        <div>
          <p>{{ $t('my_claims_view.arrival_place') }}</p>
          <h3 class="card-title">{{ claim.arrivalPlace }}</h3>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <p>{{ $t('card_flight_number') }}</p>
        <h3 class="card-title">{{ claim.flightNumber }}</h3>
      </v-col>
      <v-col cols="5">
        <p class="text-center">{{ $t('flight_identification_reservation_number') }}</p>
        <h3 class="card-title text-center">{{ claim.reservationNumber }}</h3>
      </v-col>
      <v-col cols="4">
        <p class="text-right">{{ $t('my_claims_view.day_of_flight') }}</p>
        <h3 class="card-title text-right">{{ claim.flightDate }}</h3>
      </v-col>
    </v-row>

    <div class="request-status" ref="claimStepSection">
      <v-row>
        <v-col>
          <p>
            {{ $t('step_number') }} {{ claim.statusStep }} -
            <span class="highlighted"> {{ $t(`claim_status.${claim.statusName}`) }}</span>
          </p>
        </v-col>
      </v-row>
    </div>

    <div class="pb-3">
      <MyClaimStepperMobile :all-choices="claim.mappedSteps" :current-step="claim.statusStep" />
    </div>

    <p class="highlighted pb-2">{{ $t('application_state') }}</p>

    <ClaimStatus
      v-if="claim.isWarningEnabled"
      :alert-message="claim.stepAlert.message"
      :alert-type="claim.stepAlert.alert_type"
      @expansion-btn-click="handleExpansionBtnClick"
    />

    <div ref="expendableSection">
      <ExpansionButton
        @update:expand-on-change="isExpansionBtnExpanded = $event"
        :expand-on-change="isExpansionBtnExpanded"
        :visible-text="$t('my_claims_view.show_less')"
        :hidden-text="$t('my_claims_view.show_more')"
      >
        <template #expand>
          <div>
            <v-divider thickness="2px" class="mt-3" />
            <MyClaimCardMobilePersonInformation :claim="claim" />
            <v-divider thickness="2px" class="mt-3 pb-3" />
            <v-expansion-panels v-if="claim.coPassengers.length > 0" class="expansion-panels">
              <v-expansion-panel elevation="0" bg-color="transparent">
                <v-expansion-panel-title>{{
                  `${$t('my_claims_view.other_passengers')} (${claim.coPassengers.length})`
                }}</v-expansion-panel-title>
                <v-expansion-panel-text>
                  <v-divider thickness="2px" class="py-1" />
                  <div v-for="(coPassenger, idx) in claim.coPassengers" :key="idx">
                    <v-row>
                      <v-col>
                        <p>
                          {{ $t('card_personal_name') }}:
                          <strong>{{ coPassenger.firstName }}</strong>
                        </p>
                      </v-col>
                      <v-col>
                        <p>
                          {{ $t('card_personal_last_name') }}:
                          <strong>{{ coPassenger.lastName }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <p>
                          {{ $t('card_personal_date') }}:
                          <strong>{{ coPassenger.birthDate }}</strong>
                        </p>
                      </v-col>
                      <v-col>
                        <p>
                          {{ $t('my_claims_view.email_address') }}:
                          <strong>{{ coPassenger.email }}</strong>
                        </p>
                      </v-col>
                    </v-row>
                    <v-divider
                      v-if="idx !== claim.coPassengers.length - 1"
                      thickness="2px"
                      style="margin-top: 9px; padding-bottom: 10px"
                    />
                  </div>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-divider thickness="2px" class="mt-3 pb-3" />
            <MyClaimCardDocumentInformation :claim="claim" @data-loaded="scrollToDocumentSection" />
          </div>
        </template>
      </ExpansionButton>
    </div>
  </div>
</template>

<style scoped>
.request-details {
  width: 100%;
  border-radius: 10px;
  background-color: white;
  padding: 16px;
  margin-bottom: 16px;
}

.card-title {
  color: #3e3caa;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.top-padding {
  width: 100%;
  padding-left: 12px;
  padding-top: 15px;
  margin-right: -5px;
}

.request-status {
  padding-top: 30px;
  padding-bottom: 23px;
}

.highlighted {
  color: #3e3caa;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.user-credentials {
  padding-top: 10px;
  p {
    padding-bottom: 10px;
  }
}

.font-weight-bold {
  font-weight: bold;
}

.padding-top-7 {
  padding-top: 7px;
}

:deep(.v-expansion-panel-title) {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

:deep(.v-expansion-panel-title__overlay) {
  background: white;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0 !important;
}
</style>
