<script setup lang="ts">
import { ClaimAlertStatusType } from '@/enumerators/enumerators'
import ClaimStatusCompleted from '@/components/claimStatus/types/ClaimStatusCompleted.vue'
import ClaimStatusWarning from '@/components/claimStatus/types/ClaimStatusWarning.vue'
import { computed } from 'vue'

const emits = defineEmits(['expansionBtnClick'])
const props = defineProps<{
  alertMessage: string
  alertType: ClaimAlertStatusType
}>()
const handleExpansionBtnClick = () => {
  emits('expansionBtnClick')
}
const showMissingDocumentsWarning = computed(() => {
  return ['missing_claim_attachments', 'missing_legal_documents', 'halted_missing_docs'].includes(
    props.alertMessage
  )
})
</script>

<template>
  <ClaimStatusWarning
    v-if="showMissingDocumentsWarning"
    @expansion-btn-click="handleExpansionBtnClick"
  />
  <ClaimStatusCompleted v-else :alert-message="alertMessage" :alert-type="alertType" />
</template>

<style scoped></style>
